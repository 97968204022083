import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import lng from '../misc/lng';
import envConst from '../misc/envConst'

// const spiciness = ["走辣", "微辣", "小辣", "中辣", "大辣"];

const styles = () => {
    return {
        wrapper: {
            width: '100%',
            height: '100%',
            color: '#4F4F4F',
        },
        title: {
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '10px',
            color: '#4F4F4F',
        },
        spicinessTitleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 30px',
            color: '#4F4F4F',
        },
        spicinessTitle: {
            textAlign: 'left',
            fontSize: '24px',
            color: '#828282',
        },
        sideDishTitle: {
            textAlign: 'left',
            fontSize: '24px',
            color: '#828282',
            margin: '20px 30px 10px 30px',
            // marginTop: '10px',
        },
        sideDishItemsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            marginBottom: '10px',
            flexWrap: 'wrap',
            width: '90%',
            margin: '0 auto',
        },
        NoodleTypeItemsWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '90%',
            margin: '0 auto',
            flexWrap: 'wrap',
            color: '#4F4F4F',
        },
        noodleTypeItemsOutside: {
            marginTop: '10px',
            marginRight: '10px',
        },
        noodleTypeItems: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: 'auto',
        },
        noodleTypeItemsSelected: {
            // display: 'inline-block',
            // fles: 'wrap', 
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: 'auto',
            color: 'white',
            backgroundColor: '#e65100',
            // flex: '1 0 13%',
        },
        spicinessItemsOutside: {
            flex: '1 0 30%',
            marginTop: '10px',
        },
        spicinessItems: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 20px',
            width: '50px',
            margin: 'auto',
            // flex: '1 0 13%',
        },
        spicinessItemsSelected: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 20px',
            width: '50px',
            margin: 'auto',
            color: 'white',
            backgroundColor: '#e65100',
            // flex: '1 0 13%',
        },
        sideDishItems: {
            display: 'inline-block',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: '5px',
            width: '50px',
            // flex: '1 0 13%',
        },
        sideDishItemsSelected: {
            display: 'inline-block',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: '5px',
            width: '50px',
            color: 'white',
            backgroundColor: '#e65100',
        },
        qtyBtnWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4F4F4F',
        },
        itemQtyBtnWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4F4F4F',
        },
        spicinessItemsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            margin: '0 auto',
            flexWrap: 'wrap',
            color: '#4F4F4F',
        },
        btnWrapper: {
            padding: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: '0',
            left: 0,
            right: 0,
            backgroundColor: '#e65100',
            
        },
        btn: {
            color: 'white',
            fontSize: '1.5rem',
        },
        removeBtn: {
            color: '#EB5757',
        },
        qtyBtn: {
            color: '#EB5757',
        },
        invisableBtn: {
            color: 'white',
        },
        emptyListWording: {
            marginTop: '40%',
            color: '#4F4F4F',
            fontSize: '1.8rem',
        },
    }
}
class AddNoodlesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: { // store all selected noodles and its specs
                id: 0,
                qty: 1,
                sideDish: [],
                sideDish8: [],
                sideDish10: [],
                noodleType: {
                    productId: "",
                    productName: "",
                    productImg: "",
                    unitPrice: 0,
                    unitQty: 0,
                    unitStock: 0
                },
                spiciness: '',
            },
            spicinessDisplay: [], // for option display
            noodleTypeDisplay: [], // for option display
            itemList: [],
            unitPrice: props.initProductData.unitPrice,
            unitQty: props.initProductData.unitQty,
            unitStock: props.initProductData.unitStock,
        };
        this.noodleType = [];
        this.spiciness = [];
        this.sideDish = [];
        this.sideDish8 = [];
        this.sideDish10 = [];
        this.addQty = this.addQty.bind(this);
        this.reduceQty = this.reduceQty.bind(this);
        this.clickSideDishItem = this.clickSideDishItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addItemQty = this.addItemQty.bind(this);
        this.reduceItemQty = this.reduceItemQty.bind(this);
        this.getComfirmList = this.getComfirmList.bind(this);
        this.confirm = this.confirm.bind(this);
        this.isConfirmDisabled = this.isConfirmDisabled.bind(this);
        this.selectNoodleType = this.selectNoodleType.bind(this);
        this.selectSpicyness = this.selectSpicyness.bind(this);
    }

    componentDidMount() {
        // load the noodle side dish list (for dynamic menu update)
        const urlProductSpec = `${envConst.GET_MENU_URL}/productSpec.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
        const stateWhenError = {
            item: {
                id: 0,
                qty: 1,
                sideDish: []
            },
            itemList: [],
            unitPrice: 39,
            unitQty: 0,
            unitStock: 0
        };
        // retrieve noodle side dish data from json file
        axios.get(urlProductSpec).then((res) => {
            const specData = res.data;
            this.noodleType = specData['sh01']['psp01'].specOptions;
            this.spiciness = specData['sh01']['psp03'].specOptions;
            this.sideDish = specData['sh01']['psp02'].specOptions;
            this.sideDish8 = specData['sh01']['psp04'].specOptions;
            this.sideDish10 = specData['sh01']['psp05'].specOptions;
            this.setState({
                item: {
                    id: 0,
                    qty: 1,
                    sideDish: this.sideDish.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                    sideDish8: this.sideDish8.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                    sideDish10: this.sideDish10.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    )
                },
                itemList: this.props.initProductData.productSpec.map(
                    (noodle, idx) => {
                        let sideDishSpec = {};
                        let spicinessSpec = '';
                        noodle.spec.forEach(
                            (spec) => {
                            
                                if (spec.specName === '辣度') {
                                    spicinessSpec = spec.specOptions[0];
                                } else if (spec.specName === '配菜') {
                                    spec.specOptions.forEach(
                                        (i) => {
                                            sideDishSpec[i] = 1;
                                        }
                                    );
                                }
                            }
                        );
                    
                        const pObj = {
                            id: idx,
                            qty: noodle.qty,
                            sideDish: this.sideDish.map(
                                (dish) => (
                                    {
                                        ...dish,
                                        selected: sideDishSpec[dish.productName] ? true : false,
                                    }
                                )
                            ),
                            spiciness: spicinessSpec,
                        };
                        return pObj;
                    }
                ),
                noodleTypeDisplay: this.noodleType,
                spicinessDisplay: this.spiciness
            });
        }).catch(() => {
            // if anything wrong, set default state
            this.setState(stateWhenError);
        })
    }

    getTotalQty() {
        let totalQty = 0;
        this.state.itemList.forEach(
            (item) => {
                totalQty += item.qty;
            }
        );
        return totalQty;
    }

    addQty() {
        let id = 1;
        if (this.state.itemList.length > 0) {
            id = this.state.itemList[this.state.itemList.length - 1].id + 1;
        }
        this.setState({
            qty: this.state.qty + 1,
            itemList: [
                {
                    id: id,
                    qty: 1,
                    sideDish: this.sideDish.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                },
                ...this.state.itemList,
            ],
            unitQty: 1,
            unitStock: this.state.unitStock - 1,
        });
    }

    reduceQty() {
        if (this.state.qty > 1) {
            this.setState({
                qty: this.state.qty - 1,
            });
        }   
    }

    addItemQty(id) {
        this.setState(
            {
                itemList: this.state.itemList.map(
                    (item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                qty: item.qty + 1,
                            };
                        }
                        return item;
                    }
                ),
                unitStock: this.state.unitStock - 1,
            },
        );
    }

    reduceItemQty(id, item) {
        if (item.qty <= 1) {
            return;
        }
        this.setState(
            {
                itemList: this.state.itemList.map(
                    (item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                qty: item.qty - 1,
                            };
                        }
                        return item;
                    }
                ),
                unitStock: this.state.unitStock + 1,
            },
        );
    }

    deleteItem(id, item) {
        if (this.state.itemList.length <= 1) {
            return;
        }
        this.setState(
            {
                itemList: this.state.itemList.map(
                    (item) => {
                        if (id === item.id) {
                            return null;
                        }
                        return item;
                    }
                ).filter(item => item),
                unitQty: this.state.itemList.length <= 1 ? 0 : 1,
                unitStock: this.state.unitStock + item.qty,
            }
        );
    }

    clickSideDishItem(dishtype, sideDishId) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    [dishtype]: this.state.item[dishtype].map(
                        (dish) => {
                            if (dish.productId === sideDishId) {
                                return {
                                    ...dish,
                                    selected: !dish.selected,
                                };
                            }
                            return dish;
                        }
                    ),
                }
            }
        );
    }

    selectSpicyness(itemId, spicyName) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    spiciness: spicyName,
                },
            }
        );
    }

    selectNoodleType(type) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    noodleType: type,
                },
            }
        );
    }

    getComfirmList () {
        const {item} = this.state;
        return {
            qty: item.qty,
            id: item.id,
            spec: [
                {
                    specName: '米線系列',
                    specOptions: [item.noodleType],
                    specQtyLimit: 1,
                    unitPrice: 0
                },
                {
                    specName: '辣度',
                    specOptions: [item.spiciness],
                    specQtyLimit: 1,
                    unitPrice: 0
                },
                {
                    specName: '配菜',
                    specOptions: item.sideDish.map(
                        (dish) => {
                            if (dish.selected) {
                                return dish.productName;
                            }
                            return false;
                        }
                    ).filter(dish => dish),
                    specQtyLimit: -1,
                    unitPrice: 5
                },
                {
                    specName: '配菜',
                    specOptions: item.sideDish8.map(
                        (dish) => {
                            if (dish.selected) {
                                return dish.productName;
                            }
                            return false;
                        }
                    ).filter(dish => dish),
                    specQtyLimit: -1,
                    unitPrice: 8
                },
                {
                    specName: '配菜',
                    specOptions: item.sideDish10.map(
                        (dish) => {
                            if (dish.selected) {
                                return dish.productName;
                            }
                            return false;
                        }
                    ).filter(dish => dish),
                    specQtyLimit: -1,
                    unitPrice: 10
                }
            ].filter(i => i.specOptions.length > 0)
        };
    }
    // confirm order, get order info and store shopping cart list
    confirm() {
        // retrieve spec list for calculation of overall bill
        const noodlesData = this.getComfirmList();
        // perform calculation of price and update shop list
        this.props.updateProductShopList(this.props.initProductData.productId, 'NOODLES_ADD', noodlesData);
        // change view to product list
        this.props.confirm();
    }
    isConfirmDisabled() {
        let shouldDisable = false;
        const {item} = this.state;
        if (
            item.sideDish.filter(d => d.selected).length < 1 &&
            item.sideDish8.filter(d => d.selected).length < 1 &&
            item.sideDish10.filter(d => d.selected).length < 1
        ) {
            shouldDisable = true;
        } else if (item.spiciness === '' || !item.spiciness) {
            shouldDisable = true
        } else if (item.noodleType.productId === '') {
            shouldDisable = true
        }
        return shouldDisable;
    }
    render() {
        const classes = this.props.classes;
        const {chiLng} = lng;
        const {noodleTypeDisplay, item, spicinessDisplay} = this.state;
    
        return (
            <div className={classes.wrapper}>
                <Typography classes={{root: classes.title}}>
                    {chiLng.addNoodlesCardTitle}
                </Typography>
                <div className={classes.spicinessTitleWrapper}>
                    <Typography classes={{root: classes.spicinessTitle}}>
                        {chiLng.noodleType}
                    </Typography>
                </div>
                <div className={classes.NoodleTypeItemsWrapper}>
                    {
                        noodleTypeDisplay.map(
                            (type) => (
                                <div className={classes.noodleTypeItemsOutside} key={type.productId}>
                                    <Typography
                                        classes={{root: item.noodleType && item.noodleType.productName === type.productName ? classes.noodleTypeItemsSelected : classes.noodleTypeItems}}
                                        onClick={() => {this.selectNoodleType(type);}}
                                    >
                                        {`${type.productName} ($${type.unitPrice + this.state.unitPrice})`}
                                    </Typography>
                                </div>
                            )
                        )
                    }
                </div>
                <div className={classes.spicinessTitleWrapper}>
                    <Typography classes={{root: classes.spicinessTitle}}>
                        {chiLng.spiciness}
                    </Typography>
                </div>
                <div className={classes.spicinessItemsWrapper}>
                    {
                        spicinessDisplay.map(
                            (spicyName) => (
                                <div className={classes.spicinessItemsOutside} key={spicyName}>
                                    <Typography
                                        classes={{root: item.spiciness && item.spiciness === spicyName ? classes.spicinessItemsSelected : classes.spicinessItems}}
                                        onClick={() => {this.selectSpicyness(item.id, spicyName);}}
                                    >
                                        {spicyName}
                                    </Typography>
                                </div>
                            )
                        )
                    }
                </div>
                <Typography classes={{root: classes.sideDishTitle}}>
                    {chiLng.sideDish5}
                </Typography>
                <div className={classes.sideDishItemsWrapper}>
                    {
                        item.sideDish.map(
                            (dish) => (
                                <Typography
                                    key={dish.productId}
                                    classes={
                                        {
                                            root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                        }
                                    }
                                    onClick={() => {this.clickSideDishItem('sideDish', dish.productId);}}
                                >
                                    {dish.productName}
                                </Typography>
                            )
                        )
                    }
                </div>
                <Typography classes={{root: classes.sideDishTitle}}>
                    {chiLng.sideDish8}
                </Typography>
                <div className={classes.sideDishItemsWrapper}>
                    {
                        item.sideDish8.map(
                            (dish) => (
                                <Typography
                                    key={dish.productId}
                                    classes={
                                        {
                                            root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                        }
                                    }
                                    onClick={() => {this.clickSideDishItem('sideDish8', dish.productId);}}
                                >
                                    {dish.productName}
                                </Typography>
                            )
                        )
                    }
                </div>
                <Typography classes={{root: classes.sideDishTitle}}>
                    {chiLng.sideDish10}
                </Typography>
                <div className={classes.sideDishItemsWrapper}>
                    {
                        item.sideDish10.map(
                            (dish) => (
                                <Typography
                                    key={dish.productId}
                                    classes={
                                        {
                                            root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                        }
                                    }
                                    onClick={() => {this.clickSideDishItem('sideDish10', dish.productId);}}
                                >
                                    {dish.productName}
                                </Typography>
                            )
                        )
                    }
                    <Typography
                        classes={{root: classes.sideDishItems}}
                        style={{opacity: 0}}
                    >
                    豬大腸
                    </Typography>
                    <Typography
                        classes={{root: classes.sideDishItems}}
                        style={{opacity: 0}}
                    >
                    豬大腸
                    </Typography>
                </div>
                <Divider variant="middle" />
                <div style={{height: '100px'}} />
                <div className={classes.btnWrapper}>
                    <Button color="primary" classes={{root: classes.btn}} onClick={this.props.cancel}>
                        {chiLng.cancel}
                    </Button>
                    <Button disabled={this.isConfirmDisabled()} color="primary" classes={{root: classes.btn}} onClick={this.confirm}>
                        {chiLng.comfirm}
                    </Button>
                </div>
            </div>
        );
    }
}

AddNoodlesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    updateProductShopList: PropTypes.func.isRequired,
    initProductData: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired, // for changing view to list product only
};

export default withStyles(styles)(AddNoodlesDialog);