import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import lng from '../misc/lng';

const { chiLng } = lng;
const USERNAME = 'admin';
const PASSWORD = 'gme2371';

const styles = () => {
    return {
        root: {
            flexGrow: 1,
            paddingTop: '30px',
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        username: {
            width: '100%',
        },
        password: {
            width: '100%',
        },
        loginBtn: {
            marginTop : '50px',
            width: '100%',
        },
    }
}

class AdminLoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isError: false,
            errorMsg: '',
        }
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.onLogin = this.onLogin.bind(this);
    }

    componentDidMount() {
    }
    onLogin () {
        if (
            this.state.password === PASSWORD &&
            this.state.username === USERNAME
        ) {
            Cookies.set('isQuickOrderAdminLogin', true);
            this.props.changeSubView('orderList');
        } else {
            this.setState({
                isError: true,
                errorMsg: 'Incorrect username or password'
            });
        }
    }
    handleChangeUsername(event) {
        this.setState({
            username: event.target.value,
            isError: false,
            errorMsg: '',
        });
    }
    handleChangePassword(event) {
        this.setState({
            password: event.target.value,
            isError: false,
            errorMsg: '',
        });
    }
    render () {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography
                    variant='h4'
                    color="primary"
                >
                    {chiLng.adminMainTitle}<br/>{chiLng.adminSubTitle}
                </Typography>
                <TextField
                    id="filled-name"
                    label={chiLng.adminUserName}
                    className={classes.username}
                    value={this.state.username}
                    onChange={this.handleChangeUsername}
                    margin="normal"
                    error={this.state.isError}
                />
                <TextField
                    id="filled-name"
                    label={chiLng.adminPwd}
                    className={classes.password}
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    margin="normal"
                    type="password"
                    error={this.state.isError}
                />
                <Button variant="contained" color="primary" className={classes.loginBtn} onClick={this.onLogin} >
                    {chiLng.adminLogin}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.commonReducer.view
    }
}

export default compose(
    connect(mapStateToProps,
        {}
    ),withStyles(styles), withWidth()
)(AdminLoginPage);
