import React, { useEffect, useState } from 'react';
import axios from 'axios';
import envConst from '../misc/envConst';

const { API_GET_SYS_PARA } = envConst;
const withServerRequest = (WrappedComponent) => (props) => {
    const [ serverPara, setServerPara ] = useState([]);
    const fetchSystemSettings = () => {
        return axios.get(API_GET_SYS_PARA).then((response) => {
            setServerPara(response.data);
        }).catch((err) => {
            setServerPara({error: err});
        });
    }
    const [ shouldReload, setShouldReload ] = useState(false);

    if (shouldReload) {
        fetchSystemSettings();
        setShouldReload(false);
    }

    useEffect(() => {
        fetchSystemSettings();
    }, []);
    
    return <WrappedComponent setShouldReload={setShouldReload} serverPara={serverPara} {...props} />
}

export default withServerRequest;