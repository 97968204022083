import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withWidth from '@material-ui/core/withWidth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider }  from '@material-ui/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SimpleAlertDialog from './SimpleAlertDialog';
import SimpleFullPageDialog from './SimpleFullPageDialog';
import AdminAddOrderDialog from './AdminAddOrderDialog';
import ExportDialog from './ExportDialog';

// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from 'axios';
import {envConst} from '../misc/lng';
import lng from '../misc/lng';

// create theme for responsive text
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const { chiLng } = lng;

// const filterList = ['FILTER COMPLETED', 'FILTER TODAY', 'FILTER TEA TIME', 'FILTER LUNCH'];
const filterList = [chiLng.filCompleted, chiLng.filToday, chiLng.filTeaTime, chiLng.filLunch];

const getBackgroundColor = function(address) {
    if (address === '招商局物流中心') {
        return 'rgba(142, 255, 146, 0.5)';
    } else if (address === '豐樹青衣物流中心') {
        return 'rgba(252, 123, 129, 0.5)';
    }
    return 'inherit';
}

const getTextColor = function(address) {
    return 'inherit';
}
const styles = () => {
    return {
        root: {
            flexGrow: 1,
            paddingTop: '30px',
        },
        listTitle: {
            fontSize: '14px',
        },
        titleWrapper: {
            display: 'flex',
            justifyContent: 'space-around',
            flex: 1
        },
        titleItem: {
            paddingLeft: '5px',
        },
        icon: {
            padding: 0,
        },
        detailRow: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            marginBottom: '20px',
        },
        detailItem: {
        },
        detailPanel: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: '10px',
        },
        listItemTitle: {
            fontSize: '14px',
            color: '#828282',
        },
        panelRoot: {

        },
        addBtn: {
            color: '#E65100',
            padding: 0,
            marginTop: '-2px',
            marginLeft: '5px',
        },
        commonStatus: { /* css shared by all status */
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: '22px',
            writingMode: 'vertical-rl',
            color: 'white',
            textAlign: 'center',
            marginLeft: '-3px',
            paddingRight: '3px',
        },
        confirmedStatus: { /* css specifically by confirmed status */
            backgroundColor: '#2F80ED',
        },
        completedStatus: { /* css specifically by completed status */
            backgroundColor: '#219653',
        },
        voidStatus: { /* css specifically by void status */
            backgroundColor: '#f44336',
        },
        summary: {
            height: '80px',
            borderBottom: 'solid 0.6px #828282',
        },
        deleteIcon: {
            position: 'absolute',
            right: '0px',
            top: '85px',
            color: 'red;',
        },
        filterWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: '100px',
            marginBottom: '10px',

        },
        filterOpt: {
            marginLeft: 'auto',
        },
        cvsButton: {
            position: 'fixed',
            bottom: '0',
            left: '0',
            backgroundColor: '#E65100',
            color: 'white',
        },
        actionPanel: {
            position: 'absolute',
            top: '0px',
            right: '0px',
        },
        logoutBtn: {
            padding: '7px',
        },
        homeBtn: {
            padding: '0px',
        },
        settingsBtn: {
            padding: '0px',
        },
        completeBtn: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            // backgroundColor: '#2F80ED',
        },
        formControl: {
            width: '300px',
        },
        filterSelect: {
            fontSize: '0.8rem',
            textAlign: 'left',
            paddingLeft: '15px',
        },
        cntBoxName: {
            display: 'flex',
            flex: '0.7',
            justifyContent: 'left',
        },
        cntBoxQty: {
            display: 'flex',
            flex: '0.3',
            justifyContent: 'center',
        },
        loadingWrapper: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.5,
            zIndex: 9999,
            backgroundColor: 'white',
        },
        loadingIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-20px',
            marginLeft: '-20px',
        },
        manualPrintBtn: {
            marginLeft: '8px'
        }
    }
}
const StyledListItemText = styled(ListItemText)`
    .MuiListItemText-primary {
        display: flex;
        flex: 1;
        align-items: center;
    }
`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
class AdminListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filter: false,
            filterList: [filterList[1]],
            showTodayOrder: false,
            alertDialog: { // status object specifically to alert dialog
                shouldOpen: false,
                alertTitle: '',
                alertMsg: '',
                item: null
            },
            addOrderDialog: {
                open: false,
            },
            actionPanelColoring: {
                orderList: true,
                settings: false,
            },
            showCntMealDialog: false,
            mealCnt: {},
            exportDialogOpen: false,
            isLoading: false,
        }
        this.tempList = [];
        this.listForShow = [];
        this.currentPage = 0;
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleChangeShowTodayOrder = this.handleChangeShowTodayOrder.bind(this);
        this.getList = this.getList.bind(this);
        this.export = this.export.bind(this);
        this.getPayment = this.getPayment.bind(this);
        this.getDetailList = this.getDetailList.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.delete = this.delete.bind(this);
        this.handleChangeFilterList = this.handleChangeFilterList.bind(this);
        this.handleAlertOk = this.handleAlertOk.bind(this);
        this.handleAlertCancel = this.handleAlertCancel.bind(this);
        this.handleAddOrderDialogOpen = this.handleAddOrderDialogOpen.bind(this);
        this.handleAddOrderDialogClose = this.handleAddOrderDialogClose.bind(this);
        this.handleCountMealDialogOpen = this.handleCountMealDialogOpen.bind(this);
        this.updateIconColor = this.updateIconColor.bind(this);
        this.getDataList = this.getDataList.bind(this);
        this.calMealCnt = this.calMealCnt.bind(this);
        this.handleExportDialogOpen = this.handleExportDialogOpen.bind(this);
        this.handleExportDialogClose = this.handleExportDialogClose.bind(this);
        this.setLoadingIndicator = this.setLoadingIndicator.bind(this);
        this.loadFunc = this.loadFunc.bind(this);
        this.getLoader = this.getLoader.bind(this);
    }

    componentDidMount() {
        // first landing, need to get list once
        this.getDataList(this.calMealCnt);
        // listen to order creation
        this.props.ws.on('orderCreated', () => {
            // order created, reload the order list
            this.getDataList(this.calMealCnt);
        });
        // listen to order void
        this.props.ws.on('orderVoid', () => {
            // reload the order list
            this.getDataList(this.calMealCnt);
        });
    }
    loadFunc() {
        this.setLoadingIndicator(true);
        setTimeout(
            () => {
                this.setLoadingIndicator(false);
            }, 2000
        );
        console.log('loadFunc');
        this.currentPage += 1;
        this.setState({
            list: [...this.state.list, ...this.listForShow.slice((this.currentPage * 100), (this.currentPage * 100) + 100)],
        }, () => {});
    }
    // Set loading indicator
    setLoadingIndicator(isLoading) {
        this.setState({
            isLoading: isLoading,
        });
    }
    // calculate qty of meals
    calMealCnt() {
        console.log(this.state.list);
        const orderList = this.state.list;
        return orderList && 
        orderList.filter((order) => {
            // console.log(order)
            return order.orderStatus === 'confirmed'
        })
        .reduce((acc, curr) => {
            // console.log(acc)
            const {productData} = curr.orderDetails;
            // console.log(productData);
            productData && productData.map((prodObj) => {
                // only count main meal, extract by product id and product type, no noodles and soup rice
                const prodId = prodObj.productId;
                const prodName = prodObj.productName;
                // normal product type for lunch time meal type exluding soup rice and noodles
                if (prodId.startsWith('prod') && prodObj.unitQty > 0 && prodObj.productType === 'normal') {
                    // console.log(acc);
                    if (typeof acc[prodName] !== 'undefined') {
                        // sum up the qty by the qty ordered
                        acc[prodName] = acc[prodName] + parseInt(prodObj.unitQty);
                    } else {
                        // first counting, create object first, no addition calculation
                        acc[prodName] = parseInt(prodObj.unitQty);
                    }
                }
            });
            const sortedAccKey = Object.keys(acc).sort((aKey, bKey) => {
                if (aKey.charAt(0) < bKey.charAt(0)) {return -1}
                if (aKey.charAt(0) > bKey.charAt(0)) {return 1}
                return 0;
            });
            // sort the result object and update the counter state
            let sortedAcc = {};
            sortedAccKey.map((accKey) => {
                sortedAcc[accKey] = acc[accKey]
            });
            return {...sortedAcc};
        }, {}) // initial empty val for counter object
    }

    handleExportDialogOpen () {
        this.setState(
            {
                exportDialogOpen: true,
            }
        );
    }
    handleExportDialogClose () {
        this.setState(
            {
                exportDialogOpen: false,
            }
        );
    }
    // handle meal counting function
    handleCountMealDialogOpen() {
        // show full screen dialog
        this.setState({
            showCntMealDialog: true
        })
    }
    handleAddOrderDialogOpen() {
        this.setState(
            {
                addOrderDialog: {
                    ...this.state.setting,
                    open: true,
                }
            }
        );
    }
    handleAddOrderDialogClose() {
        this.setState(
            {
                addOrderDialog: {
                    ...this.state.setting,
                    open: false,
                }
            }, () => {
                this.getDataList(this.calMealCnt)
            }
        );
    }
    async getAllOrder (whereFilter) {
        this.tempList = [];
        const targetUrl = `${envConst.API_REST_URL}`;
        return new Promise(
            (resolve, reject) => {
                const requestApiCall = () => {
                    const body = {
                        params: {
                            filter: {
                                where: whereFilter,
                                limit: 100,
                                skip: this.tempList.length,
                                // order: 'orderTimeStamp ASC',
                            },
                        }
                    };
                    axios.get(targetUrl, body).then(
                        (res) => {
                            if (res.status === 200) {
                                console.log(res.data);
                                this.numberOfTimeLeft -= 1;
                                this.tempList = [...this.tempList, ...res.data];
                                if (res.data.length <= 0) {
                                    resolve(this.tempList);
                                } else {
                                    requestApiCall();
                                }
                            }
                        },
                        (err) => {
                            reject(err)
                        }
                    );
                };

                // start calling recursive call 
                requestApiCall();
            }
        );
    }

    getDataList(cb) {
        this.setLoadingIndicator(true);
        const targetUrl = `${envConst.API_REST_URL}`;
        if (this.state.filterList.indexOf(chiLng.filToday) > -1) {
            const pastDate = new Date();
            pastDate.setHours(0);
            pastDate.setMinutes(0);
            pastDate.setSeconds(1);
            // pastDate.setDate(pastDate.getDate() - 7);
            const whereFilter = {
                orderTimeStamp: {
                    gte: pastDate.toISOString(),
                },
            };
            this.getAllOrder(whereFilter).then(
                (data) => {
                    this.listForShow = this.getList(data);
                    this.currentPage = 0;
                    this.setState(
                        {
                            list: this.listForShow.slice(0, 100),
                        }, () => {
                            // count meal qty after order data retrieved
                            // counter only for today's meal
                            this.setState({mealCnt: cb()})
                            this.setLoadingIndicator(false);
                        }
                    );
                }
            );
            return;
        }
        this.getAllOrder({}).then(
            (data) => {
                this.listForShow = this.getList(data);
                this.currentPage = 0;
                this.setState(
                    {
                        list: this.listForShow.slice(0, 100),
                    }, () => {
                        // count meal qty after order data retrieved
                        // counter only for today's meal
                        this.setLoadingIndicator(false);

                        this.setState({mealCnt: cb()})
                    }
                );
            }
        );
        // temporarily comment due to performance issue

        // axios.get(targetUrl).then(
        //     (res) => {
        //         if (res.status === 200) {
        //             console.log(res);
        //             this.setState(
        //                 {
        //                     list: res.data,
        //                 }
        //             );
        //         }
        //     },
        //     (err) => {
        //         console.log('err');
        //         console.log(err);
        //     }
        // );
    }
    getPayment(item) {
        if (item.orderPayAmount !== 0) {
            return item.orderPayAmount;
        }
        const itemList = item.orderDetails.productData;
        let sum = 0;
        if (itemList) {
            itemList.forEach(
                (i) => {
                    if (i.unitQty > 0) {
                        sum += (i.unitPrice * i.unitQty);
                    }
                }
            );
        }
        return sum;
    }
    getDetailList(item) {
        const itemList = item.orderDetails.productData;
        const returnArr = itemList && itemList.filter(i => i.unitQty > 0).map(
            (i) => {
                if (i.productType === 'noodles') {
                    const noodles = i.productSpec.map(
                        (noodle) => {
                            let sideDashSpec = [];
                            let spicinessSpec = '';
                            let noodleType = '';
                            noodle.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '辣度') {
                                        spicinessSpec = spec.specOptions[0];
                                    } else if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    } else if (spec.specName === '米線系列') {
                                        noodleType = spec.specOptions[0].productName;
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${noodle.id}`}>
                                    {`${noodleType}、${spicinessSpec}、${sideDashSpec.join('、')} x ${noodle.qty}`}
                                </div>
                            )
                        }
                    );
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        noodles
                    ];
                } else if (i.productType === 'soupRices') {
                    const soupRices = i.productSpec.map(
                        (soupRice) => {
                            let sideDashSpec = [];
                            soupRice.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${soupRice.id}`}>
                                    {`${sideDashSpec.join('、')} x ${soupRice.qty}`}
                                </div>
                            )
                        }
                    );
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        soupRices
                    ];
                } else if (i.productType === 'teaTime') {
                    const soupRices = i.productSpec.map(
                        (soupRice) => {
                            let sideDashSpec = [];
                            soupRice.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${soupRice.id}`}>
                                    {`${sideDashSpec.join('、')} x ${soupRice.qty}`}
                                </div>
                            )
                        }
                    );
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        soupRices
                    ];
                }
                return (
                    <div key={i.productId}>
                        {i.productName} x {i.unitQty}
                    </div>
                )
            }
        ).flat();

        return returnArr;
    }
    export(inputList) {
        const result = [
            ['order No', 'Contact Person', 'Contact no', 'Paymant', 'Delivery Time', 'Details', 'Qty']
        ];
        let list = inputList;

        const getOrderClientContactNo = (phone) => {
            if (phone === '') return;

            if (phone.substring(0, 3) === '852') return (phone.substring(3));

            return phone;
        };

        list.forEach(
            (data) => {
                // first row handling
                const firstRow = [
                    data.generatedOrderId, data.orderClientContactName, getOrderClientContactNo(data.orderClientContactNo), this.getPayment(data),
                    data.orderDeliveryTime,
                ];

                if (!data.orderDetails.productData) {
                    result.push(firstRow);
                    console.log('data hihi');
                    console.log(data);
                    return;
                }
                const itemList = data.orderDetails.productData;
                const inUsedList = itemList.filter(i => i.unitQty > 0);
                const otherNoodleRows = [];
                if (inUsedList[0].productType === 'noodles') {
                    let qty = 0;
                    inUsedList[0].productSpec.forEach(
                        (spec) => {
                            qty = qty + spec.qty;
                        }
                    );
                    firstRow.push(
                        inUsedList[0].productName,
                        qty,
                    );
                    // loop through each noodle menu and list out
                    inUsedList[0].productSpec.forEach(
                        (spec) => {
                            const specSize = spec.spec.length;
                            const noodleOption = spec.spec[0].specOptions[0].productName;
                            const noodleTaste = spec.spec[1].specOptions[0];
                            const noodleQty = spec.qty;
                            let noodleSubPartsStr = '';
                            for (let i=2; i<specSize; i++) {
                                for(let j=0; j<spec.spec[i].specOptions.length; j++) {
                                    if (i === specSize -1 && j === spec.spec[i].specOptions.length - 1) {
                                        // list the last one
                                        noodleSubPartsStr += spec.spec[i].specOptions[j];
                                    } else {
                                        noodleSubPartsStr += spec.spec[i].specOptions[j] + ' | ';
                                    }
                                }
                            }
                            otherNoodleRows.push(
                                [
                                    ' ', ' ', ' ', ' ', ' ', 
                                    `${inUsedList[0].productName}(${noodleOption} | ${noodleTaste} | ${noodleSubPartsStr})`, noodleQty
                                ]
                            );
                        }
                    );
                    result.push(firstRow, ...otherNoodleRows);
                } else if (inUsedList[0].productType === 'soupRices' || inUsedList[0].productType === 'teaTime') {
                    let qty = 0;
                    inUsedList[0].productSpec.forEach(
                        (spec) => {
                            qty = qty + spec.qty;
                        }
                    );
                    firstRow.push(
                        inUsedList[0].productName,
                        qty,
                    );
                    // loop through each noodle menu and list out
                    inUsedList[0].productSpec.forEach(
                        (spec) => {
                            const specSize = spec.spec.length;
                            const noodleQty = spec.qty;
                            let noodleSubPartsStr = '';
                            for (let i=0; i<specSize; i++) {
                                for(let j=0; j<spec.spec[i].specOptions.length; j++) {
                                    if (i === specSize -1 && j === spec.spec[i].specOptions.length - 1) {
                                        // list the last one
                                        noodleSubPartsStr += spec.spec[i].specOptions[j];
                                    } else {
                                        noodleSubPartsStr += spec.spec[i].specOptions[j] + ' | ';
                                    }
                                }
                            }
                            otherNoodleRows.push(
                                [
                                    ' ', ' ', ' ', ' ', ' ', 
                                    `${inUsedList[0].productName}(${noodleSubPartsStr})`, noodleQty
                                ]
                            );
                        }
                    );
                    result.push(firstRow, ...otherNoodleRows);
                } else {
                    firstRow.push(
                        inUsedList[0].productName,
                        inUsedList[0].unitQty,
                    );
                    result.push(firstRow);
                }
                // other rows
                if (inUsedList.length > 1) {
                    for (let i = 1; i < inUsedList.length; i++) {
                        if (inUsedList[i].productType === 'noodles') {
                            let qty = 0;
                            inUsedList[i].productSpec.forEach(
                                (spec) => {
                                    qty = qty + spec.qty;
                                }
                            );
                            result.push([
                                ' ', ' ', ' ', ' ', ' ', `${inUsedList[i].productName}`, qty
                            ]);
                        } else if (inUsedList[i].productType === 'soupRices' || inUsedList[i].productType === 'teaTime') {
                            let qty = 0;
                            inUsedList[i].productSpec.forEach(
                                (spec) => {
                                    qty = parseInt(qty) + parseInt(spec.qty);
                                }
                            );
                            const soupRicesFirstRow = [];
                            const otherSoupRicesRows = [];
                            soupRicesFirstRow.push(
                                ' ', ' ', ' ', ' ', ' ',
                                inUsedList[i].productName,
                                qty,
                            );
                            // loop through each noodle menu and list out
                            inUsedList[i].productSpec.forEach(
                                (spec) => {
                                    const specSize = spec.spec.length;
                                    const noodleQty = spec.qty;
                                    let soupRiceSubPartsStr = '';
                                    for (let idx=0; idx<specSize; idx++) {
                                        for(let j=0; j<spec.spec[idx].specOptions.length; j++) {
                                            if (idx === specSize -1 && j === spec.spec[idx].specOptions.length - 1) {
                                                // list the last one
                                                soupRiceSubPartsStr += spec.spec[idx].specOptions[j];
                                            } else {
                                                soupRiceSubPartsStr += spec.spec[idx].specOptions[j] + ' | ';
                                            }
                                        }
                                    }
                                    otherSoupRicesRows.push(
                                        [
                                            ' ', ' ', ' ', ' ', ' ',
                                            `${inUsedList[i].productName}(${soupRiceSubPartsStr})`, noodleQty
                                        ]
                                    );
                                }
                            );
                            result.push(soupRicesFirstRow, ...otherSoupRicesRows);
                        }else {
                            result.push([
                                ' ', ' ', ' ', ' ', ' ', inUsedList[i].productName, inUsedList[i].unitQty
                            ]);
                        }
                    }
                }
            }
        );
        let csvContent = "";
        result.forEach(function(rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        const encodedUri = encodeURIComponent(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", 'data:application/csv;charset=utf-8,%EF%BB%BF' + encodedUri);
        const date = new Date();
        link.setAttribute("download", `orders-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`);
        document.body.appendChild(link);
        link.click();
    }
    handleChangeFilterList (event) {
        this.setState(
            {
                filterList: event.target.value,
            }, () => {
                this.getDataList(this.calMealCnt);
            }
        );
    }
    handleChangeFilter(event) {
        this.setState(
            {
                filter: event.target.checked,
            }
        );
    }
    handleChangeShowTodayOrder(event) {
        this.setState(
            {
                showTodayOrder: event.target.checked,
            }, () => {
                this.getDataList(this.calMealCnt);
            }
        );
    }
    // getList() {
    //     // only include completed orders
    //     if (this.state.filterList.indexOf('FILTER COMPLETED') > -1) {
    //         return this.state.list.filter(i => i.orderStatus !== 'completed' && i.orderStatus !== 'void');
    //     }
    //     // always filter void orders
    //     return this.state.list.filter(i=> i.orderStatus !== 'void');
    // }
    getList(_list) {
        let list = _list.filter(i=> i.orderStatus !== 'void');
        // only include completed orders
        if (this.state.filterList.indexOf(chiLng.filCompleted) > -1) {
            list = list.filter(i => i.orderStatus === 'completed');
        }
        if (this.state.filterList.indexOf(chiLng.filTeaTime) > -1) {
            console.log(chiLng.filTeaTime);
            list = list.filter(
                (order) => {
                    console.log('order');
                    console.log(order);
                    const mealList = order.orderDetails.productData;
                    const inUsedList = mealList.filter(i => i.unitQty > 0);
                    console.log(inUsedList);
                    let isTeaTime = false;
                    inUsedList.forEach(
                        (i) => {
                            if (i.productId[0] === 't') {
                                isTeaTime = true;
                            }
                        }
                    );
                    return isTeaTime;
                }
            );
        }
        if (this.state.filterList.indexOf(chiLng.filLunch) > -1) {
            console.log('FILTER LUNCH');
            list = list.filter(
                (order) => {
                    console.log('order');
                    console.log(order);
                    const mealList = order.orderDetails.productData;
                    const inUsedList = mealList.filter(i => i.unitQty > 0);
                    console.log(inUsedList);
                    let isLunch = false;
                    inUsedList.forEach(
                        (i) => {
                            if (i.productId[0] === 'p') {
                                isLunch = true;
                            }
                        }
                    );
                    return isLunch;
                }
            );
        }
        console.log('Check point');
        console.log(list);
        // always filter void orders
        return list;
    }
    getStatus(status) {
        if (status === 'completed'){
            return '已完成';
        } else if (status === 'void') { // add removed order status
            return '已刪除';
        }
        return '準備中';
    }
    // alert cancel callback
    handleAlertCancel() {
        this.setState({
            ...this.state,
            alertDialog: {
                ...this.state.alertDialog,
                shouldOpen: false,
                item: null
            }
        });
    }
    // alert ok call back (confirm delete)
    handleAlertOk() {
        const item = this.state.alertDialog.item;
        console.log(item);
        // popup dialog
        const id = item.generatedOrderId;
        const targetUrl = `${envConst.API_REST_URL}/${id}`;
        const data = {
            ...item,
            orderStatus: 'void',
        }
        // we only update the order status instead of removeing the whole entry
        axios.put(targetUrl, data).then((response) => {
            console.log(response);
            this.getDataList(this.calMealCnt);
        })
        .catch((error) => {
            console.log(error);
        });
        // close dialog and remove the target item
        this.setState({
            ...this.state,
            alertDialog: {
                ...this.state.alertDialog,
                shouldOpen: false,
                item: null
            }
        });
    }
    delete(item) {
        this.setState({
            ...this.state,
            alertDialog: {
                ...this.state.alertDialog,
                shouldOpen: true,
                item,
                alertTitle: chiLng.okAlertTitle,
                alertMsg: `${chiLng.okAlertRemoveOrderPrefix}(${item.generatedOrderId})?${chiLng.okAlertRemoveOrderSuffix}`,
            }
        })
    }
    changeStatus(item) {
        const id = item.generatedOrderId;
        const targetUrl = `${envConst.API_REST_URL}/${id}/replace`;
        console.log('item.orderStatus');
        console.log(item.orderStatus);
        axios.post(targetUrl,
            {
                ...item,
                orderStatus: item.orderStatus === 'confirmed' ? 'completed' : 'confirmed' ,
            }
        ).then((response) => {
            console.log(response);
            this.getDataList(this.calMealCnt);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    generateOrderStatusClass(classes, orderStatus) {
        if (!orderStatus) {
            return classes.commonStatus;
        } else {
            switch (orderStatus) {
                case 'void':
                    return `${classes.commonStatus} ${classes.voidStatus}`
                case 'confirmed': 
                    return `${classes.commonStatus} ${classes.confirmedStatus}`;
                case 'completed': 
                    return `${classes.commonStatus} ${classes.completedStatus}`;
            }
        }
    }
    updateIconColor(target) {
        switch(target) {
            case 'orderList':
                this.setState({
                    actionPanelColoring: { 
                        ...{orderList: true, settings: false}
                    } 
                })
                break;
            case 'settings':
                this.setState({
                    actionPanelColoring: { 
                        ...{orderList: false, settings: true}
                    } 
                })
                break;
        }
    }
    getLoader() {
        if (this.state.list.length === 0 || (this.state.list.length % 100) !== 0) {
            return (<div/>)
        }
        return (
            <div style={{marginTop: '10px'}}>
                <Button onClick={this.loadFunc} color="primary">{chiLng.loadMoreLabel}</Button>
            </div>
        );
    }
    render () {
        const {classes} = this.props;
        const { mealCntTitle, manualPrint } = chiLng;
        const manualPrintLabel = (item, e) => {
            console.log(item)
            // prevent expansion panel expand when click
            e.stopPropagation();
            // fire print action
            const printURL = envConst.API_MANUAL_PRINT_LBL;
            const printData = {
                generatedOrderId: item.generatedOrderId
            }
            axios.post(printURL, printData).then((postRes) => {
                console.log(postRes);
            });
        }
        return (
            <div className={classes.root}>
                {
                    this.state.isLoading ? (
                        <div className={classes.loadingWrapper}>
                            <CircularProgress className={classes.loadingIcon}/>
                        </div>
                    ) : <div />
                }
                <ExportDialog
                    open={this.state.exportDialogOpen}
                    cancel={this.handleExportDialogClose}
                    export={this.export}
                    setLoadingIndicator={this.setLoadingIndicator}
                />
                <SimpleFullPageDialog
                    showCntMealDialog={this.state.showCntMealDialog}
                    setShowCntMealDialog={(shouldShowDia) => this.setState({showCntMealDialog: shouldShowDia})}
                    mealCntTitle={mealCntTitle}
                    renderDiaCtx={()=> {
                        // place dialog content here
                        console.log(this.state.mealCnt)
                        const mealNameKey = Object.keys(this.state.mealCnt);
                        console.log(mealNameKey);
                        return (
                            <List>
                                {
                                    mealNameKey.length > 0 && mealNameKey.map((mealName, idx) => {
                                        return (
                                            <React.Fragment key={`mealListFrag-${idx}`}>
                                                <ListItem key={`mealList-${idx}`}>
                                                    <StyledListItemText
                                                        key={`mealListItem-${idx}`}
                                                        classes={classes.itemListOverride}
                                                        style={{display: 'flex', flex: 1, justifyContent: 'center'}}
                                                    >
                                                        <ThemeProvider theme={theme}>
                                                            <Typography variant="h3" noWrap className={classes.cntBoxName}>
                                                                {mealName}
                                                            </Typography>
                                                            <Typography variant="h3" noWrap className={classes.cntBoxQty}>
                                                                <b>{this.state.mealCnt[mealName]}</b>
                                                            </Typography>
                                                        </ThemeProvider>
                                                    </StyledListItemText>
                                                </ListItem>
                                                { idx!== mealNameKey.length - 1 ? <Divider /> : null}
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </List>
                        )
                    }}
                />
                
                <SimpleAlertDialog 
                    shouldOpen={this.state.alertDialog.shouldOpen}
                    alertTitle={this.state.alertDialog.alertTitle}
                    alertMsg={this.state.alertDialog.alertMsg}
                    handleAlertOk={this.handleAlertOk}
                    handleAlertCancel={this.handleAlertCancel}
                />
                <AdminAddOrderDialog open={this.state.addOrderDialog.open} cancel={this.handleAddOrderDialogClose}/>
                <div style={{display: 'flex', justifyContent: 'center', itemsAlign: 'center'}}>
                    <Typography
                        variant='subtitle2'
                        color="primary"
                        style={
                            {
                                fontSize: '22px',
                            }
                        }
                    >
                        {chiLng.orderTitle}
                    </Typography>
                    <IconButton aria-label="add" className={classes.addBtn} onClick={this.handleAddOrderDialogOpen} >
                        <AddIcon fontSize="default" />
                    </IconButton>
                    { /*  */ }
                    <IconButton aria-label="add" className={classes.addBtn} onClick={this.handleCountMealDialogOpen} >
                        <AvTimerIcon fontSize="default" />
                    </IconButton>
                </div>
                <div className={classes.filterWrapper}>
                    {/* <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.filter}
                            value="checked"
                            onChange={this.handleChangeFilter}
                            color="primary"
                        />
                        }
                        classes={
                            {
                                root: classes.filterOpt,
                            }
                        }
                        label="FILTER COMPLETED ORDER"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.showTodayOrder}
                            value="checked"
                            onChange={this.handleChangeShowTodayOrder}
                            color="primary"
                        />
                        }
                        classes={
                            {
                                root: classes.filterOpt,
                            }
                        }
                        label="SHOW TODAY ORDER"
                    /> */}
                    <FormControl className={classes.formControl}>
                        <Select
                        multiple
                        autoWidth
                        value={this.state.filterList}
                        onChange={this.handleChangeFilterList}
                        input={<Input id="select-multiple-checkbox" />}
                        displayEmpty
                        classes={{select: classes.filterSelect}}
                        renderValue={
                            selected => {
                                if (selected.length <= 0) {
                                    return chiLng.selFilter;
                                }
                                return selected.join(', ');
                            }
                        }
                        MenuProps={MenuProps}
                        >
                        {filterList.map(name => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={this.state.filterList.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </div>
                
                {
                    this.state.list.map(
                        (item, idx) => (
                            <ExpansionPanel key={idx}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    classes={
                                        {
                                            expandIcon: classes.icon,
                                            root: classes.summary,
                                        }
                                    }
                                    style={{
                                        backgroundColor: getBackgroundColor(item.orderAddress),
                                        color: getTextColor(item.orderAddress),
                                    }}
                                >
                                <div className={this.generateOrderStatusClass(classes, item.orderStatus)}>
                                    {this.getStatus(item.orderStatus)}
                                </div>
                                <div className={classes.titleWrapper}>
                                    <div className="" >
                                        <Typography className={classes.listTitle}>
                                                {chiLng.orderNoLbl}
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.generatedOrderId}
                                        </Typography>
                                    </div>
                                    <div className={classes.titleItem}>
                                        <Typography className={classes.listTitle}>
                                                {chiLng.contactPLbl}
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.orderClientContactName}
                                        </Typography>
                                    </div>
                                    <div className={classes.titleItem}>
                                        <Typography className={classes.listTitle}>
                                                {chiLng.telLbl}
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.orderClientContactNo}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button color="primary" variant="contained" className={classes.manualPrintBtn} size="small" onClick={manualPrintLabel.bind(this, item)}>{chiLng.manualPrint}</Button>
                                    </div>
                                </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{backgroundColor: getBackgroundColor(item.orderAddress)}}>
                                    <div className={classes.detailPanel} >
                                        <Button color="primary" variant="contained" fullWidth onClick={() => {this.changeStatus(item);}} classes={{root: classes.completeBtn}}>
                                            {
                                                item.orderStatus === 'confirmed' ? chiLng.markAsCompleted : chiLng.markedAsConfirmed
                                            }
                                        </Button>
                                        <Button color="primary" onClick={() => {this.delete(item);}} className={classes.deleteIcon}>
                                            <CancelIcon />
                                        </Button>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.statusLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {this.getStatus(item.orderStatus)}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.deliveryTimeLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderDeliveryTime}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className="" >
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.orderNoLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.generatedOrderId}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.contactPLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderClientContactName}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.telLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderClientContactNo}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.addressLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderAddress}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.paymentLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {this.getPayment(item)}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.orderTimeLbl}
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {new Date(item.orderTimeStamp).toLocaleString('en-GB', { timeZone: 'Asia/Hong_Kong' })}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    {chiLng.detailsLbl}
                                                </Typography>
                                                <div className={classes.listItemTitle} style={{ textAlign: 'left' }}>
                                                    {
                                                        this.getDetailList(item)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    )
                }
                {this.getLoader()}
                <div style={{width: '100%', height: '50px'}}/>
                <Button variant="contained" fullWidth classes={{root: classes.cvsButton}} onClick={this.handleExportDialogOpen}>
                    {chiLng.exportCsv}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.commonReducer.view,
        ws: state.commonReducer.ws
    }
}

export default compose(
    connect(mapStateToProps,
        {}
    ),withStyles(styles), withWidth()
)(AdminListPage);
