import * as ActionTypes from '../actions';
/**
 * {
 *      orderId: {
 *             orderUrlHash: 'someHashIdForUrlHere', 
*              status: 'pending / confirmed / processing / completed'
*              orderTimestamp: current dateTimeObj,
*              orderDetails: {
*                  productById: {},
*                    productData: [],
*              }
 *          }
 *      
 * }
 */

// Hard-coded default order id (should be a fixed value)
const defaultOrderId = 'A0001';
// Default value for url suffix (user checking url)
const defaultOrderUrlHash = 'GMZHASH10000000';
const initialStateObj = {};

// All the hash / generate order id actions should NOT be done in reducer, 
// reducer should NOT have any side effects
const updateOrderStatusReducerFn = (state, {
    generatedOrderId,
    orderTimeStamp,
    orderStatus,
    orderUrlHash,
    productReducerObj,
    orderAddress,
    orderClientContactNo,
    orderClientContactName,
    orderDeliveryTime,
    payAmount
}) => {
    if (!generatedOrderId) {
        generatedOrderId = defaultOrderId;
    };
    if (!orderUrlHash) {
        orderUrlHash = defaultOrderUrlHash;
    }
    return {
        ...state,
        [generatedOrderId]: {
            ...state[generatedOrderId],
            orderUrlHash,
            orderStatus,
            orderTimeStamp,
            orderDetails: productReducerObj,
            orderAddress,
            orderClientContactNo,
            orderClientContactName,
            orderDeliveryTime,
            payAmount
        }
    }
};
const ordersReducer = (state = initialStateObj, action) => {
    switch(action.type) {
        case ActionTypes.UPDATE_ORDER_STATUS:
            const {orderTimeStamp, orderStatus} = action;
            return updateOrderStatusReducerFn(state, action);
        case ActionTypes.ClEARORDER:
            return {};
        default:
            return state;
    }
}

export default ordersReducer;