import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import lng from '../misc/lng';
import {Typography} from '@material-ui/core';

const { chiLng } = lng;
const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            position: 'relative'
        },
        title: {
            flex: 1,
            marginLeft: '5px'
        }
    }
});
const SimpleFullPageDialog = ({ showCntMealDialog, setShowCntMealDialog, mealCntTitle, renderDiaCtx }) => {
    const classes = useStyles();
    const handleClose = () => {
        setShowCntMealDialog(false);
    }
    
    return (
        <Dialog fullScreen open={showCntMealDialog} color="primary">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>{ mealCntTitle }</Typography>
                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                flex="1"
            >
                {renderDiaCtx()}
            </Box>
        </Dialog>
    );
}

export default SimpleFullPageDialog;