const payAmountCalulator = (origAmount, formFactorObj) => {
    const calAct = Object.keys(formFactorObj)[0];
    const formFactor = formFactorObj[calAct];
    
    switch(calAct) {
        case 'minus':
            return parseInt(origAmount) - parseInt(formFactor);
        case 'multiply':
            const discountedPrice = Math.ceil(parseInt(origAmount) * parseFloat(formFactor));
            return discountedPrice;
        default:
            return parseInt(origAmount);
    }
}

/**
 * 
 * @param {object} discountCondition Condition object for determine the validation of the discount
 * @param {object} data Data to be judged by whether the discount can apply or not
 */
const discountConditionHelper = (discountCondition, data) => {
    console.log(data);
    console.log(discountCondition)
    console.log(data.totalAmount)
    // default no discount is given
    let shouldDiscountApply = false;
    if (!discountCondition || !data) {
        // no limitation
        shouldDiscountApply = true;
        return shouldDiscountApply;
    } 
    // check discount is in valid date
    if (discountCondition && discountCondition.applyDate) {
        discountCondition.applyDate.forEach((validIsoDate) => {
            console.log(validIsoDate);
            const discountMonth = (new Date(validIsoDate)).getMonth();
            const discountDate = (new Date(validIsoDate)).getDate();
            const currentMonth = (new Date()).getMonth();
            const currentDate = (new Date()).getDate();
            
            // validate date and month
            if (discountMonth === currentMonth && discountDate === currentDate) {
                shouldDiscountApply = true;
            }
        });
    }
    // check total amount validity (given it is in valid date)
    if (data && data.totalAmount && discountCondition && discountCondition.totalAmount) {
        const localAmount = parseInt(data.totalAmount);
        const localCondition = discountCondition.totalAmount;
        // Function constructor, eval the string as logic which can determine
        shouldDiscountApply = Function(`return ${localAmount}${localCondition}`)();
    }
    return shouldDiscountApply;
}

const commonFn = {
    payAmountCalulator,
    discountConditionHelper
};

export default commonFn;