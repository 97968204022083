const API_REST_PORT = 3002;
// use UTC+0 for query to sync with database
// but when generating ID, use UTC+8
const dayToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString();

const envConst = {
    API_REST_ORDER_COUNT_URL: `https://api.${window.location.hostname}/api/orders/count?where[orderTimeStamp][gt]=${dayToday}`,
    API_REST_URL: `https://api.${window.location.hostname}/api/orders`,
    API_REST_URL_COUNT: `http://${window.location.hostname}:3002/api/orders/count`,
    API_REST_URL_ADMIN: `https://api.${window.location.hostname}/api/orders/admin`,
    API_REST_URL_ORDER_TEST: `https://api.${window.location.hostname}/api/orders/test`,
    API_CHK_ORDER_URL: `https://${window.location.hostname}/orders`,
    API_VERIFY_PROMO_CODE: `https://api.${window.location.hostname}/api/promos/verify`,
    API_GET_SYS_PARA: `https://api.${window.location.hostname}/api/SystemControlParameters`,
    API_UPDATE_SYS_PARA: `https://api.${window.location.hostname}/api/SystemControlParameters/batchUpdate`,
    API_MANUAL_PRINT_LBL: `https://api.${window.location.hostname}/api/orders/printlabel`,
    WS_URL: `https://api.${window.location.hostname}`,
    ADMIN_DELIVERY_OPTIONS: [
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '14:15',
        '14:30',
        '14:45'
    ],
    DELIVERY_OPTIONS: [
        '12:15',
        '12:30',
        '12:45',
        '13:00',
    ],
    DELIVERY_OPTIONS_B: [
        '12:30'
    ],
    DELIVERY_OPTIONS_C: [
        '12:00',
        '12:15',
        '13:00'
    ],
    DELIVERY_OPTIONS_TEA_TIME: [
        '14:30',
    ],
    DELIVERY_ADDR_OPTIONS: [
        '青衣嘉民領達中心地下',
        '招商局物流中心',
        '豐樹青衣物流中心',
    ],
    exportOptList: [
        {
            name: '自訂',
            value: 'cust',
        },
        {
            name: '最近1個月',
            value: 'lastMonth',
        },        {
            name: '全部',
            value: 'all',
        },
    ],
    exportFormatList: [
        {
            name: 'csv',
            value: 'csv',
        },
        {
            name: 'html',
            value: 'html',
        },
    ],
    SHOP_IMG_PATH: '../static/shop/img', // not used, just for record
    PROD_IMG_PATH: '../static/prod/img', // not used, just for record
    backendGeneralTimeout: 0,
    MIN_TEATIME_PAY: 300, // adjust min pay for ordering
    MAX_MEAL_ITEM: 999, // adjust no. of items user can add in menu 
    MAX_MEAL_QTY: 999, // adjust in reducer max qty to calculate order payment amount
    GET_MEAL_IMG_URL: `${process.env.PUBLIC_URL}/assets/meal`,
    GET_MENU_URL: `${process.env.PUBLIC_URL}/assets/menu`,
}

export default envConst;