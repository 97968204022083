import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import lng from '../misc/lng';

const useStyles = makeStyles({
    card: {
        maxWidth: 380, // don't flex here, flex outside
        margin: '0 auto',
    },
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
    },
    cardItems: {
        flexGrow: 1,
        flexShrink: 1,
    },
    firstLineCard: {
        width: '100%',
    },
    qtyAdjust: {
        flexGrow: 2,
        flexShrink: 1,
    },
    qtyCtx: {
        verticalAlign: 'middle',
    },
    cardActNormal: {
        fontSize: '1.8rem'
    },
    mealCardBtn: {
        cursor: 'pointer',
        fontSize: '2.5rem'
    },
    cardContent: {
        "minHeight": '80px' /* Limit the height to show at least 2 meal cards */
    },
    buyBtn: {
        marginLeft: '20px',
        fontSize: '1rem',
    },
});
const NoodlesMealCard = (props) => {
    const classes = useStyles();
    const getTotalQty = (product) => {
        let qty = 0;
      product.productSpec.forEach(
        (spec) => {
          qty += spec.qty;
        }
      );
      return qty;
    }
    return (
        <Card className={classes.card}>
            <CardMedia
                component="img"
                alt={lng.chiLng.altProdImg}
                height="210"
                image={props.productImg}
            />
            <CardContent classes={{ root: classes.cardContent }}>
                <Typography gutterBottom variant="h4" component="h2">
                    {props.productName}
                </Typography>
                <div className={classes.cardContainer}>
                    <div className={classes.cardItems}>
                        <Typography
                            variant="h6"
                            className={classes.cardActNormal}
                        >
                            {/*adjust the display value to +5 for display*/}
                            HKD: <b>${props.unitPrice+5}起</b>
                        </Typography>
                    </div>
                    <div className={[classes.cardItems, classes.qtyAdjust].join(' ')}>
                        {/* <IconButton
                            className={classes.button}
                            aria-label="Remove 1"
                            color="primary"
                            onClick={updateProductShopListDel}
                        >
                            <RemoveCircle 
                                className={classes.mealCardBtn}
                            />
                        </IconButton> */}
                        <Typography
                            variant="h6"
                            component="span"
                            className={[classes.qtyCtx, classes.cardActNormal].join(' ')}
                        >
                            {lng.chiLng.prodQty}: <b>{getTotalQty(props.productData)}</b>
                        </Typography>
                        <Button classes={{root: classes.buyBtn}} variant="contained" color="primary" onClick={() => {props.handleOpenAddNoodlesDialog(props.productData);}}>
                            {lng.chiLng.buy}
                        </Button>
                        {/* <IconButton
                            className={classes.button}
                            aria-label="Add 1"
                            color="primary"
                            onClick={updateProductShopListAdd}
                        >
                            <AddCircle
                                className={classes.mealCardBtn}
                            />
                        </IconButton> */}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

NoodlesMealCard.propTypes = {
    shopID: PropTypes.string.isRequired,
    productID: PropTypes.string.isRequired,
    productName: PropTypes.string,
    productDesc: PropTypes.string,
    productImg: PropTypes.string,
    unitPrice: PropTypes.number.isRequired,
    unitQty: PropTypes.number.isRequired,
    handleOpenAddNoodlesDialog: PropTypes.func.isRequired,
    productData: PropTypes.object.isRequired,
}

NoodlesMealCard.defaultProps = {
    productName: lng.chiLng.prodNameDef,
    productDesc: lng.chiLng.prodDescDef,
    productImg: lng.sysLng.prodImgDef,
}


export default NoodlesMealCard;
