import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withWidth from '@material-ui/core/withWidth';
import AdminSettingsPage from '../components/AdminSettingsPage'
import AdminLoginPage from '../components/AdminLoginPage';
import AdminListPage from '../components/AdminListPage';
import AdminActionPanel from '../components/AdminActionPanel';

import Cookies from 'js-cookie';

const styles = () => {
    return {
        root: {
            flexGrow: 1,
        },
    }
}

class AdminPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subView: 'login',
        }
        this.getView = this.getView.bind(this);
        this.changeSubView = this.changeSubView.bind(this);
        const isLogin = Cookies.get('isQuickOrderAdminLogin');
        if (isLogin) {
            this.state.subView = 'orderList';
        }
    }

    componentDidMount() {}
    changeSubView(view) {
        this.setState({
            subView: view,
        });
    }
    getView() {
        switch (this.state.subView) {
            case 'login':
                return <AdminLoginPage/>;
            case 'orderList':
                return <AdminListPage/>;
            case 'settings':
                return <AdminSettingsPage/>;
            default:
                return <AdminLoginPage/>; 
        }
    }
    render () {
        const {root} = this.props.classes;
        return (
            <div className={root}>
                <AdminActionPanel 
                    render={this.getView}
                    changeSubView={this.changeSubView}
                    currentView={this.state.subView}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.commonReducer.view
    }
}

export default compose(
    connect(mapStateToProps,
        {}
    ),withStyles(styles), withWidth()
)(AdminPageContainer);
