import React from 'react';
import PropTypes from 'prop-types';
// import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import SimpleMessageDialog from './SimpleMessageDialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
// eslint-disable-next-line no-unused-vars
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import lng, { envConst } from '../misc/lng';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import AdminAddNoodlesDialog from './AdminAddNoodlesDialog';
import AdminAddSoupRiceDialog from './AdminAddSoupRiceDialog';
import AdminAddTeaTimeDialog from './AdminAddTeaTimeDialog';
import { getMenuJson } from '../actions';
import {
    confirmOrderStatus,
    clearOrder,
} from '../actions';

const timeList = envConst.ADMIN_DELIVERY_OPTIONS;
const addressList = envConst.DELIVERY_ADDR_OPTIONS;
const regexForPhone = /^[235679]\d{7}$/;
const {chiLng} = lng;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const styles = (theme) => {
    return {
        binAvatar: {
            backgroundColor: theme.palette.secondary.dark,
            cursor: 'pointer',
            width: '24px',
            height: '24px',
        },
        wrapper: {
            padding: '00px',
            marginBottom: '50px',
        },
        titleWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        subTitleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E65100',
            padding: '20px',
        },
        productRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
        },
        input: {
            width: '35px',
            height: '20px',
            margin: '0px 8px',
            border: '1px solid rgba(5, 5, 5, 0.65)',
            borderRadius: '4px',
            textAlign: 'center',
            fontSize: '1rem',
        },
        noodleInput: {
            width: '30px',
            height: '17px',
            margin: '0px 8px',
            border: '1px solid rgba(5, 5, 5, 0.65)',
            borderRadius: '4px',
            textAlign: 'center',
            fontSize: '0.8rem',
        },
        orderInput: {
            width: '180px',
            height: '17px',
            border: '1px solid rgba(5, 5, 5, 0.65)',
            borderRadius: '4px',
            fontSize: '0.8rem',
        },
        totalPriceInput: {
            width: '106px',
            height: '24px',
            border: '1px solid rgba(5, 5, 5, 0.65)',
            borderRadius: '4px',
            fontSize: '1rem',
            textAlign: 'center',
        },
        orderInputError: {
            width: '180px',
            height: '17px',
            border: '2px solid red',
            borderRadius: '4px',
            fontSize: '0.8rem',
        },
        addBtn: {
            color: '#E65100',
        },
        noodleSubtitle: {
            width: '180px',
        },
        btnWrapper: {
            padding: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: '0',
            left: 0,
            right: 0,
            backgroundColor: '#e65100',  
        },
        btn: {
            color: 'white',
            fontSize: '1.5rem',
        },
        calBtn: {
            color: 'white',
            fontSize: '1rem',
            padding: 0,
            marginLeft: '10px',
        },
        icon: {
            padding: 0,
        },
        summary: {
            height: '80px',
            borderBottom: 'solid 0.6px #828282',
        },
        OrderDetail: {
            padding: '20px',
        },
    }
}
class AdminAddOrderDialog extends React.Component {
    constructor(props) {
        super(props);
        const defaultAddress = envConst.DELIVERY_ADDR_OPTIONS[0];
        // order is important! this.beverageList should place before this.initialState
        this.beverageList = []; // store beverage list retrieved from menu json
        this.ttbeverageList = []; // store beverage list retrieved from menu json
        this.productObj = {}; // store product object for add noodles dialog
        this.teaTimeObj = {}; // store product object for add taeTime dialog
        this.productList = []; // store product list retrieved from menu json
        this.teaTimeList = []; // store product list retrieved from tea time menu json
        this.initialState = {
            totalPrice: 0,
            productList: [],
            teaTimeList: [],
            addNoodlesDialog: {
                open: false,
                productData: {
                    unitPrice: 0,
                },
            },
            addSoupRiceDialog: {
                open: false,
                productData: {
                    unitPrice: 0,
                },
            },
            addTeaTimeDialog: {
                open: false,
                productData: {
                    unitPrice: 0,
                },
            },
            beverageList: this.beverageList,
            ttbeverageList: this.ttbeverageList,
            address: '',
            phonePrefix: '852',
            phoneNo: '',
            contactPerson: '',
            deliveryTime: '12:00 - 12:30',
            isError: {
                address: false,
                phoneNo: false,
                contactPerson: false,
                deliveryTime: false, 
            },
            shouldModalOpen: false,
            shouldLoadingIndicatorShow: true,
            msg: '', // state to store message of warning dialog
            shouldDisable: true, // whether or not the promo button should disabled
            typeOfPromoMsg: 'none', // determine which type of message should show, none, failed or success
        };
        this.state = this.initialState;
        this.onEnter = this.onEnter.bind(this);
        this.changeProductQty = this.changeProductQty.bind(this);
        this.changeTeaTimeProductQty = this.changeTeaTimeProductQty.bind(this);
        this.changeProductPrice = this.changeProductPrice.bind(this);
        this.openAddNoodlesDialog = this.openAddNoodlesDialog.bind(this);
        this.openAddTeaTimeDialog = this.openAddTeaTimeDialog.bind(this);
        this.openAddSoupRiceDialog = this.openAddSoupRiceDialog.bind(this);
        this.closeAddNoodlesDialog = this.closeAddNoodlesDialog.bind(this);
        this.closeAddSoupRiceDialog = this.closeAddSoupRiceDialog.bind(this);
        this.closeAddTeaTimeDialog = this.closeAddTeaTimeDialog.bind(this);
        this.confirmNoodlesDialog = this.confirmNoodlesDialog.bind(this);
        this.confirmSoupRiceDialog = this.confirmSoupRiceDialog.bind(this);
        this.confirmTeaTimeDialog = this.confirmTeaTimeDialog.bind(this);
        this.changeNoodleQty = this.changeNoodleQty.bind(this);
        this.changeTeaTimeQty = this.changeTeaTimeQty.bind(this);
        this.removeNoodle = this.removeNoodle.bind(this);
        this.removeTeaTime = this.removeTeaTime.bind(this);
        this.changeBeverageQty = this.changeBeverageQty.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangePhoneNo = this.handleChangePhoneNo.bind(this);
        this.handleChangeDeliveryTime = this.handleChangeDeliveryTime.bind(this);
        this.changeTTBeverageQty = this.changeTTBeverageQty.bind(this);
        this.handleChangeContactPerson = this.handleChangeContactPerson.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.isConfirmDisabled = this.isConfirmDisabled.bind(this);
        this.changeTotalPrice = this.changeTotalPrice.bind(this);
        this.calculateTotalPrice = this.calculateTotalPrice.bind(this);
        this.handleOrderProcess = this.handleOrderProcess.bind(this);
        this.saveUserContactData = this.saveUserContactData.bind(this);
        this.setOrderEntryToBackend = this.setOrderEntryToBackend.bind(this);
        this.getNoodleQty = this.getNoodleQty.bind(this);
        this.cancelAdd = this.cancelAdd.bind(this);
        this.getSoupRiceSubTitle = this.getSoupRiceSubTitle.bind(this);
        this.getNoodleSubTitle = this.getNoodleSubTitle.bind(this);
        
    }

    componentDidMount() {
        // retrieve meal and beverage data
        const beverageUrl = `${envConst.GET_MENU_URL}/beverage.json?bust=${Date.now()}`
        const ttbeverageUrl = `${envConst.GET_MENU_URL}/ttbeverage.json?bust=${Date.now()}`
        const httpPromiseArr = [
            getMenuJson(),
            axios.get(beverageUrl),
            getMenuJson('sh02'),
            axios.get(ttbeverageUrl),
        ];
        
        Promise.all(httpPromiseArr).then((httpResObjArr) => {
            // we can get all menu and beverage object success
            if (httpResObjArr[0].status < 404 &&
                httpResObjArr[1].status < 404 &&
                httpResObjArr[2].status < 404 &&
                httpResObjArr[3].status < 404
            ) {
                    // save loaded menu data to menu object variable
                    this.productList = httpResObjArr[0].data ? httpResObjArr[0].data.sh01 : [];
                    this.productList.forEach(
                        (product) => {
                            this.productObj[product.productId] = product;
                        }
                    );
                    this.teaTimeList = httpResObjArr[2].data ? httpResObjArr[2].data.sh02 : [];
                    this.teaTimeList.forEach(
                        (product) => {
                            this.teaTimeObj[product.productId] = product;
                        }
                    );
                    // save loaded beverage data to menu object variable
                    console.log(httpResObjArr[1])
                    this.beverageList = httpResObjArr[1].data ? httpResObjArr[1].data.sh01 : [];
                    this.ttbeverageList = httpResObjArr[3].data ? httpResObjArr[3].data.sh01 : [];
                    // need to setstate again here as this.beverageList is updated
                    // if beverageList is not under react state mechanism, setstate is NOT needed
                    this.setState(
                        {
                            beverageList: this.beverageList,
                            ttbeverageList: this.ttbeverageList,
                        }
                    );
            }
        }).catch((err) => {
            // something wrong happen, popup error dialog
            this.setState({
                msg: chiLng.loadJsonDataFailed,
                modalOpen: true,
                shouldLoadingIndicatorShow: false
            });

            setTimeout(() => {
                this.setState({
                    msg: null,
                    modalOpen: false,
                    shouldLoadingIndicatorShow: false
                });
            }, 2000);
        });
        
    }

    getNoodleQty(product) {
        let qty = 0;
        product.productSpec.forEach(
            (noodle) => {
                if (!noodle.qty) return;
                qty += parseInt(noodle.qty);
            }
        );
        return qty;
    }
    /**
     * Set provided order entry to the back for storage
     * @param {object} orderDataToBeSaved 
     * @return {Promise} A promise for futher resolved
     */
    setOrderEntryToBackend(orderDataToBeSaved) {
        // Signature should be fixed for the API path
        const targetUrl = envConst.API_REST_URL_ADMIN;
        const backendGeneralTimeout = envConst.backendGeneralTimeout;
        return axios.put(targetUrl, orderDataToBeSaved, {timeout: backendGeneralTimeout});
    }

    async saveUserContactData(
        orderAddress,
        orderClientContactName,
        orderClientContactNo,
        orderDeliveryTime,
        orderMoment,
        payAmount,
    ) {
        await this.props.confirmOrderStatus(
            'confirmed',
            orderAddress,
            orderClientContactName,
            orderClientContactNo,
            orderDeliveryTime,
            orderMoment,
            payAmount,
        );
    }
    async handleOrderProcess() {
        const {chiLng: {orderProcessing, smsWentWrong, successOrderCreate, failedOrderCreate}} = lng;
        // Show processing indicator...
        this.setState({
            shouldModalOpen: true,
            msg: orderProcessing
        });
        const orderMoment = window.moment.tz('Asia/Hong_Kong');
        await this.saveUserContactData(
            this.state.address,
            this.state.contactPerson,
            this.state.phoneNo,
            this.state.deliveryTime,
            orderMoment,
            this.state.totalPrice ? parseInt(this.state.totalPrice) : 0,
        );
        const generateFormattedData = () => {
            const orderDetail = {
                productById: {},
                productData: [...this.state.productList, ...this.state.beverageList, ...this.state.teaTimeList, ...this.state.ttbeverageList],
            };
            orderDetail.productData.forEach(
                (product) => {
                    orderDetail.productById[product.productId] = product;
                }
            );
            const targetOrder = this.props.orderData;
            const formattedOrderforSave = {};
            const generatedOrderId = Object.keys(targetOrder)[0];

            formattedOrderforSave.generatedOrderId = generatedOrderId;
            formattedOrderforSave.orderUrlHash = targetOrder[generatedOrderId].orderUrlHash;
            formattedOrderforSave.orderStatus = targetOrder[generatedOrderId].orderStatus;
            formattedOrderforSave.orderTimeStamp = targetOrder[generatedOrderId].orderTimeStamp;
            formattedOrderforSave.orderAddress = this.state.address;
            formattedOrderforSave.orderClientContactNo = `${this.state.phonePrefix}${this.state.phoneNo}`;
            formattedOrderforSave.orderClientContactName = this.state.contactPerson;
            formattedOrderforSave.orderDeliveryTime = this.state.deliveryTime;
            formattedOrderforSave.orderDetails = orderDetail;
            formattedOrderforSave.orderPayAmount = this.state.totalPrice ? parseInt(this.state.totalPrice) : 0;

            return formattedOrderforSave;
        };
        const aBackEndPromise = this.setOrderEntryToBackend(generateFormattedData());
        aBackEndPromise.then((res) => {
            if (res.data.result && res.data.result.success) {
                this.setState({
                    msg: successOrderCreate
                });
            } else {
                this.setState({
                    msg: failedOrderCreate
                });
            }
            // success response here
            // We delay and intentionally show the loading to user
            setTimeout(() => {
                this.setState({
                    shouldModalOpen: false,
                });
                this.props.cancel();
            }, 1500);
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                shouldLoadingIndicatorShow: false,
                msg: smsWentWrong,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        shouldModalOpen: false
                    });
                    this.props.cancel();
                }, 3000);
            });
        }).finally(() => {
            //reset order
            this.props.clearOrder();
            // reset state to initial
            this.setState(this.initialState);
        })

    }
    changeTotalPrice(e) {
        this.setState({
            totalPrice: e.target.value,
        });
    }
    calculateTotalPrice() {
        this.setState({
            totalPrice: this.getTotalPrice(),
        });
    }
    isConfirmDisabled() {
        if (
            (this.state.totalPrice !== 0 && !this.state.totalPrice) ||
            this.state.address === '' ||
            this.state.phoneNo === '' ||
            this.state.contactPerson === '' ||
            this.state.deliveryTime === '' ||
            this.state.isError.address ||
            this.state.isError.phoneNo ||
            this.state.isError.contactPerson ||
            this.state.isError.deliveryTime
        ) {
            return true;
        }
        return false;
    }
    handleChangeAddress(event) {
        this.setState({
            address: event.target.value,
            isError: {
                ...this.state.isError,
                address: event.target.value === '',
            },
        });
    }
    handleChangeDeliveryTime(event) {
        this.setState({
            deliveryTime: event.target.value,
        });
    }
    handleChangePhoneNo(event) {
        const val = event.target.value;
        if (regexForPhone.test(val)) {
            this.setState({
                isError: {
                    ...this.state.isError,
                    phoneNo: false,
                },
                phoneNo: val,
            });
        } else {
            this.setState({
                phoneNo: event.target.value,
                isError: {
                    ...this.state.isError,
                    phoneNo: true,
                },
            });
        }
    }
    handleChangeContactPerson(event) {
        const val = event.target.value;
        if (val !== '') {
            this.setState({
                isError: {
                    ...this.state.isError,
                    contactPerson: false,
                },
                contactPerson: event.target.value,
            });  
        } else {
            this.setState({
                isError: {
                    ...this.state.isError,
                    contactPerson: true,
                },
                contactPerson: event.target.value,
            });
        }
    }
    openAddNoodlesDialog(id) {
        this.setState({
            addNoodlesDialog: {
                open: true,
                productData: this.productObj[id],
            }
        });
    }
    openAddSoupRiceDialog(id) {
        this.setState({
            addSoupRiceDialog: {
                open: true,
                productData: this.productObj[id],
            }
        });
    }
    openAddTeaTimeDialog(id) {
        this.setState({
            addTeaTimeDialog: {
                open: true,
                productData: this.teaTimeObj[id],
            }
        });
    }
    closeAddNoodlesDialog() {
        this.setState({
            addNoodlesDialog: {
                ...this.state.addNoodlesDialog,
                open: false,
            }
        });
    }
    closeAddSoupRiceDialog() {
        this.setState({
            addSoupRiceDialog: {
                ...this.state.addSoupRiceDialog,
                open: false,
            }
        });
    }
    closeAddTeaTimeDialog() {
        this.setState({
            addTeaTimeDialog: {
                ...this.state.addTeaTimeDialog,
                open: false,
            }
        });
    }
    confirmNoodlesDialog(noodleData) {
        const id = noodleData.id;
        const newSpec = noodleData.spec;
        const price = noodleData.price;
        this.setState({
            addNoodlesDialog: {
                ...this.state.addNoodlesDialog,
                open: false,
            },
            productList: this.state.productList.map(
                (product) => {
                    if (product.productId === id) {

                        if (product.productSpec.length < 1) {
                            return {
                                ...product,
                                productSpec: [
                                    {
                                        id: 0,
                                        qty: 1,
                                        unitPrice: price,
                                        spec: newSpec,
                                    }
                                ],
                                unitQty: 1,
                                totalPrice: price,
                            }
                        } else {
                            let isExist = false;
                            const newProductSpec = product.productSpec.map(
                                (curNoodle) => {
                                    if (
                                        JSON.stringify(curNoodle.spec) === JSON.stringify(newSpec)
                                    ) {
                                        isExist = true;
                                        return {
                                            ...curNoodle,
                                            qty: parseInt(curNoodle.qty) + 1,
                                        };
                                    }
                                    return curNoodle;
                                }
                            );
                            if (isExist) {
                                return {
                                    ...product,
                                    productSpec: newProductSpec,
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            } else {
                                console.log('notExist');
                                return {
                                    ...product,
                                    productSpec: [
                                        ...product.productSpec,
                                        {
                                            id: 0,
                                            qty: 1,
                                            unitPrice: price,
                                            spec: newSpec,
                                        }
                                    ].map((p, idx) => ({...p, id: idx})),
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            }
                        }
                    }
                    return product;
                }
            )
        }, () => console.log(this.state));
    }
    confirmSoupRiceDialog(soupRiceData) {
        const id = soupRiceData.id;
        const newSpec = soupRiceData.spec;
        const price = soupRiceData.price;
        this.setState({
            addSoupRiceDialog: {
                ...this.state.addSoupRiceDialog,
                open: false,
            },
            productList: this.state.productList.map(
                (product) => {
                    if (product.productId === id) {

                        if (product.productSpec.length < 1) {
                            return {
                                ...product,
                                productSpec: [
                                    {
                                        id: 0,
                                        qty: 1,
                                        unitPrice: price,
                                        spec: newSpec,
                                    }
                                ],
                                unitQty: 1,
                                totalPrice: price,
                            }
                        } else {
                            let isExist = false;
                            const newProductSpec = product.productSpec.map(
                                (curNoodle) => {
                                    if (
                                        JSON.stringify(curNoodle.spec) === JSON.stringify(newSpec)
                                    ) {
                                        isExist = true;
                                        return {
                                            ...curNoodle,
                                            qty: parseInt(curNoodle.qty) + 1,
                                        };
                                    }
                                    return curNoodle;
                                }
                            );
                            if (isExist) {
                                return {
                                    ...product,
                                    productSpec: newProductSpec,
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            } else {
                                console.log('notExist');
                                return {
                                    ...product,
                                    productSpec: [
                                        ...product.productSpec,
                                        {
                                            id: 0,
                                            qty: 1,
                                            unitPrice: price,
                                            spec: newSpec,
                                        }
                                    ].map((p, idx) => ({...p, id: idx})),
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            }
                        }
                    }
                    return product;
                }
            )
        }, () => console.log(this.state));
    }
    confirmTeaTimeDialog(soupRiceData) {
        const id = soupRiceData.id;
        const newSpec = soupRiceData.spec;
        const price = soupRiceData.price;
        this.setState({
            addTeaTimeDialog: {
                ...this.state.addTeaTimeDialog,
                open: false,
            },
            teaTimeList: this.state.teaTimeList.map(
                (product) => {
                    if (product.productId === id) {

                        if (product.productSpec.length < 1) {
                            return {
                                ...product,
                                productSpec: [
                                    {
                                        id: 0,
                                        qty: 1,
                                        unitPrice: price,
                                        spec: newSpec,
                                    }
                                ],
                                unitQty: 1,
                                totalPrice: price,
                            }
                        } else {
                            let isExist = false;
                            const newProductSpec = product.productSpec.map(
                                (curNoodle) => {
                                    if (
                                        JSON.stringify(curNoodle.spec) === JSON.stringify(newSpec)
                                    ) {
                                        isExist = true;
                                        return {
                                            ...curNoodle,
                                            qty: parseInt(curNoodle.qty) + 1,
                                        };
                                    }
                                    return curNoodle;
                                }
                            );
                            if (isExist) {
                                return {
                                    ...product,
                                    productSpec: newProductSpec,
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            } else {
                                console.log('notExist');
                                return {
                                    ...product,
                                    productSpec: [
                                        ...product.productSpec,
                                        {
                                            id: 0,
                                            qty: 1,
                                            unitPrice: price,
                                            spec: newSpec,
                                        }
                                    ].map((p, idx) => ({...p, id: idx})),
                                    unitQty: 1,
                                    totalPrice: product.totalPrice + price,
                                };
                            }
                        }
                    }
                    return product;
                }
            )
        }, () => console.log(this.state));
    }
    changeNoodleQty(inputProduct, inputNoodle, e) {
        let totalPrice = 0;
        this.setState({
            productList: this.state.productList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                productSpec: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            totalPrice += (noodle.unitPrice * parseInt(e.target.value));
                                            return ({
                                                ...noodle,
                                                qty: e.target.value,
                                            });
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ),
                                totalPrice,
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    changeTeaTimeQty(inputProduct, inputNoodle, e) {
        let totalPrice = 0;
        this.setState({
            teaTimeList: this.state.teaTimeList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                productSpec: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            totalPrice += (noodle.unitPrice * parseInt(e.target.value));
                                            return ({
                                                ...noodle,
                                                qty: e.target.value,
                                            });
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ),
                                totalPrice,
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    changeBeverageQty(inputBeverage, e) {
        this.setState({
            beverageList: this.state.beverageList.map(
                (beverage) => {
                    if (inputBeverage.productId === beverage.productId) {
                        return (
                            {
                                ...beverage,
                                unitQty: parseInt(e.target.value),
                            }
                        );
                    }
                    return beverage;
                }
            ),
        });
    }
    changeTTBeverageQty(inputBeverage, e) {
        this.setState({
            ttbeverageList: this.state.ttbeverageList.map(
                (beverage) => {
                    if (inputBeverage.productId === beverage.productId) {
                        return (
                            {
                                ...beverage,
                                unitQty: parseInt(e.target.value),
                            }
                        );
                    }
                    return beverage;
                }
            ),
        });
    }
    removeNoodle(inputProduct, inputNoodle) {
        let totalPrice = 0;
        this.setState({
            productList: this.state.productList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                productSpec: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            return null;
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ).filter(n => n).map((n, idx) => ({...n, id: idx})),
                                totalPrice,
                                unitQty: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            return null;
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ).filter(n => n).map((n, idx) => ({...n, id: idx})).length <= 0 ? 0 : 1,
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    removeTeaTime(inputProduct, inputNoodle) {
        let totalPrice = 0;
        this.setState({
            teaTimeList: this.state.teaTimeList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                productSpec: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            return null;
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ).filter(n => n).map((n, idx) => ({...n, id: idx})),
                                totalPrice,
                                unitQty: product.productSpec.map(
                                    (noodle) => {
                                        if (noodle.id === inputNoodle.id) {
                                            return null;
                                        }
                                        totalPrice += (noodle.unitPrice * noodle.qty);
                                        return noodle;
                                    }
                                ).filter(n => n).map((n, idx) => ({...n, id: idx})).length <= 0 ? 0 : 1,
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    changeProductQty(inputProduct, e) {
        this.setState({
            productList: this.state.productList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                unitQty: parseInt(e.target.value),
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    changeTeaTimeProductQty(inputProduct, e) {
        this.setState({
            teaTimeList: this.state.teaTimeList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                unitQty: parseInt(e.target.value),
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    changeProductPrice(inputProduct, e) {
        this.setState({
            productList: this.state.productList.map(
                (product) => {
                    if (inputProduct.productId === product.productId) {
                        return (
                            {
                                ...product,
                                unitPrice: e.target.value,
                            }
                        );
                    }
                    return product;
                }
            ),
        });
    }
    onEnter() {
        // load previous gathered beverage and product info to state
        this.setState({
            productList: this.productList.map(
                (product) => (
                    {
                        ...product,
                    }
                )
            ),
            teaTimeList: this.teaTimeList.map(
                (product) => (
                    {
                        ...product,
                    }
                )
            ),
            beverageList: this.beverageList,
            ttbeverageList: this.ttbeverageList,
        });
    }
    getSoupRiceSubTitle(soupRice) {
        // return '';
        let sideDashSpec = [];
        soupRice.spec.forEach(
            (spec) => {
                if (spec.specName === '配菜') {
                    spec.specOptions.forEach(
                        (i) => {
                          sideDashSpec.push(i);
                        }
                    );
                }
            }
        );
        return `${sideDashSpec.join('|')}`;
    }
    getNoodleSubTitle(noodle) {
        console.log(noodle);
        // return '';
        let sideDashSpec = [];
        let spicinessSpec = '';
        let noodleType = '';
        noodle.spec.forEach(
            (spec) => {
                if (spec.specName === '辣度') {
                    spicinessSpec = spec.specOptions[0];
                } else if (spec.specName === '配菜') {
                    spec.specOptions.forEach(
                        (i) => {
                          sideDashSpec.push(i);
                        }
                    );
                } else if (spec.specName === '米線系列') {
                  noodleType = spec.specOptions[0].productName;
                }
            }
        );
        return `${noodleType}、${spicinessSpec}、${sideDashSpec.join('|')}`;
    }
    getTotalPrice() {
        console.log('getTotalPrice');
        console.log(this.state.productList);
        console.log(this.state.teaTimeList);
        let totalPrice = 0;
        this.state.productList.forEach(
            (product) => {
                if (product.productType === 'noodles' || product.productType === 'soupRices') {
                    totalPrice += product.totalPrice;
                } else {
                    if (product.unitQty !== '') {
                        totalPrice += (product.unitPrice * product.unitQty);
                    }
                }
            }
        );
        this.state.teaTimeList.forEach(
            (product) => {
                if (product.productType === 'noodles' || product.productType === 'soupRices' || product.productType === 'teaTime') {
                    totalPrice += product.totalPrice;
                } else {
                    if (product.unitQty !== '') {
                        totalPrice += (product.unitPrice * product.unitQty);
                    }
                }
            }
        );
        this.state.beverageList.forEach(
            (beverage) => {
                totalPrice += (beverage.unitPrice * beverage.unitQty);
            }
        );
        this.state.ttbeverageList.forEach(
            (beverage) => {
                totalPrice += (beverage.unitPrice * beverage.unitQty);
            }
        );
        if (!totalPrice) {
            return '';
        }
        return totalPrice;
    }
    cancelAdd() {
        // reset state to initial
        this.setState(this.initialState);
        this.props.cancel();
    }
    render() {
        const classes = this.props.classes;
        const {chiLng} = lng;
        return (
            <Dialog fullScreen open={this.props.open} onEntered={this.onEnter} onClose={() => {}} TransitionComponent={Transition}>
                <div className={classes.wrapper}>
                    <AdminAddNoodlesDialog
                        open={this.state.addNoodlesDialog.open}
                        initProductData={this.state.addNoodlesDialog.productData}
                        cancel={this.closeAddNoodlesDialog}
                        confirm={this.confirmNoodlesDialog}
                    />
                    <AdminAddSoupRiceDialog
                        open={this.state.addSoupRiceDialog.open}
                        initProductData={this.state.addSoupRiceDialog.productData}
                        cancel={this.closeAddSoupRiceDialog}
                        confirm={this.confirmSoupRiceDialog}
                    />
                    <AdminAddTeaTimeDialog
                        open={this.state.addTeaTimeDialog.open}
                        initProductData={this.state.addTeaTimeDialog.productData}
                        cancel={this.closeAddTeaTimeDialog}
                        confirm={this.confirmTeaTimeDialog}
                    />
                    {/* <div className={classes.titleWrapper}>
                        <SettingsIcon/>
                        <Typography variant="h6" style={{marginLeft: '10px'}}>
                            SETTINGS
                        </Typography>
                    </div> */}
                    <div className={classes.subTitleWrapper}>
                        <Typography variant="h6" style={{}}>
                            {chiLng.manualOrderTitle}
                        </Typography>
                    </div>
                    {/* Lunch */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="lunch"
                            classes={
                                {
                                    expandIcon: classes.icon,
                                    root: classes.summary,
                                }
                            }
                        >
                            <Typography className={classes.heading}>{chiLng.manualLunchTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {this.state.productList.map(
                                    (product) => {
                                        if (product.productType === 'noodles') {
                                            return(
                                                <div key={product.productId}>
                                                    <div className={classes.productRow}>
                                                        <Typography variant="body1">
                                                            {product.productName}
                                                        </Typography>
                                                        <div style={{display: 'flex', alignItems: 'center', width: '85px'}}>
                                                            <Typography variant="body1">
                                                                {this.getNoodleQty(product)}
                                                            </Typography>
                                                            <IconButton aria-label="add" className={classes.addBtn} onClick={() => {this.openAddNoodlesDialog(product.productId);}} >
                                                                <AddIcon fontSize="default" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    {
                                                        product.productSpec.map(
                                                            (noodle) => {
                                                                return (
                                                                    <div className={classes.productRow} key={noodle.id}>
                                                                        <Avatar classes={{root: classes.binAvatar}} onClick={() => this.removeNoodle(product, noodle)}>
                                                                            <DeleteIcon style={{fontSize: '18px'}}/>
                                                                        </Avatar>
                                                                        <Typography variant="body2" classes={{root: classes.noodleSubtitle}}>
                                                                            {this.getNoodleSubTitle(noodle)}
                                                                        </Typography>
                                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.qty}
                                                                                className={classes.qtyInput}
                                                                                onChange={e => {this.changeProductQty(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.qty}
                                                                                className={classes.noodleInput}
                                                                                onChange={(e) => {this.changeNoodleQty(product, noodle, e);}}
                                                                            />
                                                                            <div>
                                                                            {chiLng.dollarSign}
                                                                            </div>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.unitPrice}
                                                                                onChange={e => {this.changeProductPrice(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.unitPrice}
                                                                                className={classes.noodleInput}
                                                                                disabled
                                                                            />
                                                                            <div>
                                                                                {chiLng.perMeal}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    }
                                                </div>
                                            );
                                        } else if (product.productType === 'soupRices') {
                                            return(
                                                <div key={product.productId}>
                                                    <div className={classes.productRow}>
                                                        <Typography variant="body1">
                                                            {product.productName}
                                                        </Typography>
                                                        <div style={{display: 'flex', alignItems: 'center', width: '85px'}}>
                                                            <Typography variant="body1">
                                                                {this.getNoodleQty(product)}
                                                            </Typography>
                                                            <IconButton aria-label="add" className={classes.addBtn} onClick={() => {this.openAddSoupRiceDialog(product.productId);}} >
                                                                <AddIcon fontSize="default" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    {
                                                        product.productSpec.map(
                                                            (noodle) => {
                                                                return (
                                                                    <div className={classes.productRow} key={noodle.id}>
                                                                        <Avatar classes={{root: classes.binAvatar}} onClick={() => this.removeNoodle(product, noodle)}>
                                                                            <DeleteIcon style={{fontSize: '18px'}}/>
                                                                        </Avatar>
                                                                        <Typography variant="body2" classes={{root: classes.noodleSubtitle}}>
                                                                            {this.getSoupRiceSubTitle(noodle)}
                                                                        </Typography>
                                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.qty}
                                                                                className={classes.qtyInput}
                                                                                onChange={e => {this.changeProductQty(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.qty}
                                                                                className={classes.noodleInput}
                                                                                onChange={(e) => {this.changeNoodleQty(product, noodle, e);}}
                                                                            />
                                                                            <div>
                                                                            {chiLng.dollarSign}
                                                                            </div>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.unitPrice}
                                                                                onChange={e => {this.changeProductPrice(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.unitPrice}
                                                                                className={classes.noodleInput}
                                                                                disabled
                                                                            />
                                                                            <div>
                                                                            {chiLng.perMeal}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    }
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={product.productId} className={classes.productRow}>
                                                <Typography variant="body1">
                                                    {product.productName}
                                                </Typography>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {/* <TextField
                                                        id="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{classes: {root: classes.inputOutlined}}}
                                                        inputProps={{type: 'number', classes: {root: classes.input}}}
                                                        value={product.unitQty}
                                                        className={classes.qtyInput}
                                                        onChange={e => {this.changeProductQty(product, e);}}
                                                    /> */}
                                                    <input
                                                        type="number"
                                                        value={product.unitQty}
                                                        className={classes.input}
                                                        onChange={e => {this.changeProductQty(product, e);}}
                                                    />
                                                    <div>
                                                    {chiLng.dollarSign}
                                                    </div>
                                                    {/* <TextField
                                                        id="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{classes: {root: classes.inputOutlined}}}
                                                        inputProps={{type: 'number', classes: {root: classes.input}}}
                                                        value={product.unitPrice}
                                                        onChange={e => {this.changeProductPrice(product, e);}}
                                                    /> */}
                                                    <input
                                                        type="number"
                                                        value={product.unitPrice}
                                                        className={classes.input}
                                                        disabled
                                                    />
                                                    <div>
                                                    {chiLng.perMeal}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {/* Tea Time */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="lunch"
                            classes={
                                {
                                    expandIcon: classes.icon,
                                    root: classes.summary,
                                }
                            }
                        >
                            <Typography className={classes.heading}>{chiLng.manualTeatimeTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {this.state.teaTimeList.map(
                                    (product) => {
                                        if (product.productType === 'teaTime') {
                                            return(
                                                <div key={product.productId}>
                                                    <div className={classes.productRow}>
                                                        <Typography variant="body1">
                                                            {product.productName}
                                                        </Typography>
                                                        <div style={{display: 'flex', alignItems: 'center', width: '85px'}}>
                                                            <Typography variant="body1">
                                                                {this.getNoodleQty(product)}
                                                            </Typography>
                                                            <IconButton aria-label="add" className={classes.addBtn} onClick={() => {this.openAddTeaTimeDialog(product.productId);}} >
                                                                <AddIcon fontSize="default" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    {
                                                        product.productSpec.map(
                                                            (noodle) => {
                                                                return (
                                                                    <div className={classes.productRow} key={noodle.id}>
                                                                        <Avatar classes={{root: classes.binAvatar}} onClick={() => this.removeTeaTime(product, noodle)}>
                                                                            <DeleteIcon style={{fontSize: '18px'}}/>
                                                                        </Avatar>
                                                                        <Typography variant="body2" classes={{root: classes.noodleSubtitle}}>
                                                                            {this.getSoupRiceSubTitle(noodle)}
                                                                        </Typography>
                                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.qty}
                                                                                className={classes.qtyInput}
                                                                                onChange={e => {this.changeProductQty(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.qty}
                                                                                className={classes.noodleInput}
                                                                                onChange={(e) => {this.changeTeaTimeQty(product, noodle, e);}}
                                                                            />
                                                                            <div>
                                                                            {chiLng.dollarSign}
                                                                            </div>
                                                                            {/* <TextField
                                                                                id="outlined"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputProps={{classes: {root: classes.noodleInputOutlined}}}
                                                                                inputProps={{type: 'number', classes: {root: classes.noodleInput}}}
                                                                                value={noodle.unitPrice}
                                                                                onChange={e => {this.changeProductPrice(product, e);}}
                                                                            /> */}
                                                                            <input
                                                                                type="number"
                                                                                value={noodle.unitPrice}
                                                                                className={classes.noodleInput}
                                                                                disabled
                                                                            />
                                                                            <div>
                                                                            {chiLng.perMeal}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    }
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={product.productId} className={classes.productRow}>
                                                <Typography variant="body1">
                                                    {product.productName}
                                                </Typography>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {/* <TextField
                                                        id="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{classes: {root: classes.inputOutlined}}}
                                                        inputProps={{type: 'number', classes: {root: classes.input}}}
                                                        value={product.unitQty}
                                                        className={classes.qtyInput}
                                                        onChange={e => {this.changeProductQty(product, e);}}
                                                    /> */}
                                                    <input
                                                        type="number"
                                                        value={product.unitQty}
                                                        className={classes.input}
                                                        onChange={e => {this.changeTeaTimeProductQty(product, e);}}
                                                    />
                                                    <div>
                                                    {chiLng.dollarSign}
                                                    </div>
                                                    {/* <TextField
                                                        id="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{classes: {root: classes.inputOutlined}}}
                                                        inputProps={{type: 'number', classes: {root: classes.input}}}
                                                        value={product.unitPrice}
                                                        onChange={e => {this.changeProductPrice(product, e);}}
                                                    /> */}
                                                    <input
                                                        type="number"
                                                        value={product.unitPrice}
                                                        className={classes.input}
                                                        disabled
                                                    />
                                                    <div>
                                                    {chiLng.perMeal}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {/* Drinks */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="drinks"
                            classes={
                                {
                                    expandIcon: classes.icon,
                                    root: classes.summary,
                                }
                            }
                        >
                            <Typography className={classes.heading}>{chiLng.manualDrinksTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {
                                    this.state.beverageList.map(
                                        (beverage) => {
                                            return (
                                                <div key={beverage.productId} className={classes.productRow}>
                                                    <Typography variant="body1">
                                                        {beverage.productName}
                                                    </Typography>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <input
                                                            type="number"
                                                            value={beverage.unitQty}
                                                            className={classes.input}
                                                            onChange={e => {this.changeBeverageQty(beverage, e);}}
                                                        />
                                                        <div>
                                                        {chiLng.dollarSign}
                                                        </div>
                                                        <input
                                                            type="number"
                                                            value={beverage.unitPrice}
                                                            className={classes.input}
                                                            disabled
                                                        />
                                                        <div>
                                                        {chiLng.perMeal}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )
                                }
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    {/* Tea Time Drinks */}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="drinks"
                            classes={
                                {
                                    expandIcon: classes.icon,
                                    root: classes.summary,
                                }
                            }
                        >
                            <Typography className={classes.heading}>{chiLng.manualTeatimeDrinksTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {
                                    this.state.ttbeverageList.map(
                                        (beverage) => {
                                            return (
                                                <div key={beverage.productId} className={classes.productRow}>
                                                    <Typography variant="body1">
                                                        {beverage.productName}
                                                    </Typography>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <input
                                                            type="number"
                                                            value={beverage.unitQty}
                                                            className={classes.input}
                                                            onChange={e => {this.changeTTBeverageQty(beverage, e);}}
                                                        />
                                                        <div>
                                                        {chiLng.dollarSign}
                                                        </div>
                                                        <input
                                                            type="number"
                                                            value={beverage.unitPrice}
                                                            className={classes.input}
                                                            disabled
                                                        />
                                                        <div>
                                                        {chiLng.perMeal}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )
                                }
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <div className={classes.OrderDetail}>
                        <div className={classes.productRow}>
                            <Typography variant="body1">
                                {chiLng.contactPerson}
                            </Typography>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="text"
                                    value={this.state.contactPerson}
                                    className={this.state.isError.contactPerson ? classes.orderInputError : classes.orderInput}
                                    onChange={this.handleChangeContactPerson}
                                />
                            </div>
                        </div>
                        <div className={classes.productRow}>
                            <Typography variant="body1">
                                {chiLng.contactNo}
                            </Typography>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="number"
                                    value={this.state.phoneNo}
                                    className={this.state.isError.phoneNo ? classes.orderInputError : classes.orderInput}
                                    onChange={this.handleChangePhoneNo}
                                />
                            </div>
                        </div>
                        {/* <div className={classes.productRow}>
                            <Typography variant="body1">
                                {chiLng.deliveryAddr}
                            </Typography>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="text"
                                    value={this.state.address}
                                    className={this.state.isError.address ? classes.orderInputError : classes.orderInput}
                                    onChange={this.handleChangeAddress}
                                />
                            </div>
                        </div> */}
                        <div className={classes.productRow}>
                            <Typography variant="body1">
                                {chiLng.deliveryAddr}
                            </Typography>
                            <FormControl error={this.state.address === ''}>
                                <Select
                                    style={{textAlign: 'left', width: '180px'}}
                                    value={this.state.address}
                                    onChange={this.handleChangeAddress}
                                    // className={this.state.isError.address ? classes.orderInputError : classes.orderInput}
                                    inputProps={{
                                        name: 'address',
                                    }}
                                >
                                {
                                    addressList.map(
                                        item => (
                                            <MenuItem style={{textAlign: 'left'}} value={item} key={item}>{item}</MenuItem>
                                        )
                                    )
                                }
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.productRow}>
                            <Typography variant="body1">
                                {chiLng.deliveryTime}
                            </Typography>
                            <Select
                                style={{textAlign: 'left', width: '180px'}}
                                value={this.state.deliveryTime}
                                onChange={this.handleChangeDeliveryTime}
                                inputProps={{
                                    name: 'deliveryTime',
                                }}
                            >
                            {
                                timeList.map(
                                    item => (
                                        <MenuItem style={{textAlign: 'left'}} value={item} key={item}>{item}</MenuItem>
                                    )
                                )
                            }
                            </Select>
                        </div>
                        <div className={classes.productRow}>
                            <Typography variant="h6">
                                {chiLng.totalPrice}
                            </Typography>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="number"
                                    value={this.state.totalPrice}
                                    onChange={this.changeTotalPrice}
                                    className={classes.totalPriceInput}
                                />
                                <Button variant="contained" color="primary" classes={{root: classes.calBtn}} onClick={this.calculateTotalPrice}>
                                    {chiLng.calculate}
                                </Button>
                            </div>
                        </div>
                    </div>
                    
                    <SimpleMessageDialog 
                        lng={this.state.msg}
                        modalOpen={this.state.shouldModalOpen}
                        shouldLoadingIndicatorShow={this.state.shouldLoadingIndicatorShow}
                    />
                </div>
                <div className={classes.btnWrapper}>
                        <Button color="primary" classes={{root: classes.btn}} onClick={this.cancelAdd}>
                            {chiLng.cancel}
                        </Button>
                        <Button disabled={this.isConfirmDisabled()} color="primary" classes={{root: classes.btn}} onClick={this.handleOrderProcess}>
                            {chiLng.comfirm}
                        </Button>
                    </div>
            </Dialog>
        );
    }
}

AdminAddOrderDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        orderData: state.ordersReducer,
    }
}

export default compose(
    connect(mapStateToProps,
        {
            confirmOrderStatus,
            clearOrder,
        }
    ),withStyles(styles),
)(AdminAddOrderDialog);
