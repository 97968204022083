import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { envConst } from '../misc/lng'; 

export default () => {
    const [ testStatus, setTestStatus ] = useState('Loading');
    useEffect(() => {
        const targetUrl = envConst.API_REST_URL_ORDER_TEST;
        const generatedOrderId = 'test';
        const orderUrlHash = 'aHash';
        const orderStatus = 'confirmed';
        const orderTimeStamp = new Date();
        const orderAddress = 'test address';
        const orderClientContactNo = '';
        const orderClientContactName = 'test';
        const orderDeliveryTime = '12:15';
        const orderDetails = {};
        const orderPayAmount = 500;

        axios.put(targetUrl, {
            generatedOrderId,
            orderUrlHash,
            orderStatus,
            orderTimeStamp,
            orderAddress,
            orderClientContactNo,
            orderClientContactName,
            orderDeliveryTime,
            orderDetails,
            orderPayAmount
        }).then((res) => {
            console.log(res);
            if (res.data.result.success) {
                setTestStatus('Order Test OK !');
            } else {
                setTestStatus('Order Test FAILED !');
            }
        });
    }, []);
    return (
        <span>{testStatus}</span>
    );
};