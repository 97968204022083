import React, { useEffect } from 'react';
import OrderSummaryCard from './components/OrderSummaryCard';
import ConfirmOrderCard from './components/ConfirmOrderCard';
import SuccessOrderCard from './components/SuccessOrderCard';
import webSocket from 'socket.io-client'
import ParentOrder from './containers/ParentOrder';
import { BrowserRouter as Router, Route } from "react-router-dom";
import AdminPageContainer from './containers/AdminPageContainer';
import { ThemeProvider }  from '@material-ui/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-roboto';
import './App.css';
import { envConst } from './misc/lng';
import {
    saveWsObj,
} from './actions';
import OrderTest from './containers/OrderTest';

// define global site main and secondary color
const themeStyle = {
    palette: {
        primary: {
            main: '#e65100',
            custlight: '#ffb74d',
        },
        secondary: {
            main: '#191919'
        }
    }
};
const theme = createMuiTheme(themeStyle);

// main entrance
// // remove socket
        // this.props.ws && this.props.ws.close();
const App = (props) => {
    useEffect(() => {
        // connect and assign ws obj to store
        const wso = webSocket.connect(envConst.WS_URL);
        props.saveWsObj(wso);
        // clean up and close socket
        return () => {
            props.ws && props.ws.close();
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Router>
                    {/* <Route exact path="/" 
                        render={(props)=> <ConfirmOrderCard {...props} changeView={()=> (<div></div>)} orderData={{some: 'data'}} confirmOrderStatus={()=>{console.log('tmp')}} render={(stateObj) => (<div>yes</div>)} setTotalPay={()=>{}}/>} /> */}
                    <Route exact path="/" component={ParentOrder} />
                    <Route path="/orders/:oid" component={OrderSummaryCard} />
                    <Route path="/admin" component={AdminPageContainer} />
                    <Route path="/test" component={OrderTest} />
                </Router>
            </div>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        view: state.commonReducer.view,
        ws: state.commonReducer.ws,
    }
}

export default compose(
    connect(mapStateToProps,{
        saveWsObj
    }),
)(App);
