import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import lng, { envConst } from '../misc/lng';
import SimpleMessageDialog from './SimpleMessageDialog';


const { chiLng } = lng;
// const { API_REST_URL } = envConst;
const styles = () => {
    return {
        root: {
            padding: '20px 35px',
            flexWrap: 'wrap',
        },
        table: {
            width: '100%',
        },
        button: {
            cursor: 'pointer',
            marginTop: '3rem',
        }
    }
}
class OrderSummaryCard extends React.Component {
    constructor(props) {
        super(props);
        // Retrieve oid from the url
        this.oid = this.props.match.params.oid;
        this.state = {
            data: [
                {
                    name: chiLng.generatedOrderId,
                    value: '-',
                },
                {
                    name: chiLng.orderTimeStamp,
                    value: '-',
                },
                {
                    name: chiLng.orderClientContactName,
                    value: '-',
                },
                {
                    name: chiLng.orderClientContactNo,
                    value: '-',
                },
                {
                    name: chiLng.orderDeliveryTime,
                    value: '-',
                },
                {
                    name: chiLng.orderStatus,
                    value: '-',
                },
            ],
            shouldModalOpen: false,
            modalType: 'error',
            modalMessage: '',
        };
    }

    completeAndReturn(evt) {
        evt.preventDefault();
        document.location.href="/";
    }

    componentDidMount() {
        const targetUrl = `${envConst.API_REST_URL}/${this.oid}`;
        // retrieve order data from oid extracted from url and query server for order data
        axios.get(targetUrl).then((res) => {
            console.log(res);
            let loadedData = [];
            const requiredColumn = ['generatedOrderId', 'orderTimeStamp', 'orderClientContactName', 'orderClientContactNo', 'orderDeliveryTime', 'orderStatus'];
            let count = -1;
            loadedData = Object.keys(res.data).map((dataKey) => {
                if (requiredColumn.includes(dataKey)) {
                    count = count + 1;
                    let formattedVal = res.data[dataKey];
                    switch(dataKey) {
                        case 'orderTimeStamp':
                            formattedVal = window.moment(formattedVal).format('DD-MM-YYYY HH:mm:ss');
                            return undefined;
                        case 'orderStatus':
                            formattedVal = chiLng[formattedVal];
                            return undefined;
                        default:
                    }
                    return {
                        name: chiLng[dataKey],
                        value: formattedVal
                    }
                }
                return undefined;
            }).filter(stateData => typeof stateData !== 'undefined');
            this.setState({data: loadedData});
        }).catch((err) => {
            // Something went wrong, show error message and redirect
            this.setState({shouldModalOpen: true, modalMessage: chiLng.somethingWentWrong});
            setTimeout(() => {
                this.setState({shouldModalOpen: false});
            }, 1500);
        });
    }
    
    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                    {
                        this.state.data.map(
                            (data, idx) => (
                                <TableRow key={`order-row-key-${idx}`}>
                                    <TableCell>
                                        <Typography variant="subtitle1">
                                            {data.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2">
                                            {data.value}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        )
                    }
                    </TableHead>
                </Table>
                <SimpleMessageDialog
                    lng={this.state.modalMessage}
                    modalOpen={this.state.shouldModalOpen}
                    shouldLoadingIndicatorShow={false}
                />
                <Button
                        fullWidth
                        color="primary"
                        className={classes.button}
                        onClick={this.completeAndReturn}
                        variant="contained"
                    >
                    <Typography variant="h6">{ chiLng.returnBtnCtx }</Typography>
                </Button>
            </div>
        );
    }
}

OrderSummaryCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderSummaryCard);