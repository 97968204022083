import * as ActionTypes from '../actions';
import {envConst} from '../misc/lng';
// State object design
// {
//      [productId]: {
//          productName: '',
//          productImg: '',
//          unitPrice: xx,
//          unitQty: xx,
//          unitStock: xx
//      }
// }
//

const initialStateObj = {
    productById: {},
    productData: [],
};
export const products = (state = initialStateObj.productData, action) => {
    switch(action.type) {
        case ActionTypes.GET_PROD_LIST:
            return [...[], ...action.productData];
        default:
            return state;
    }
};

export const productById = (state = initialStateObj.productById, action) => {
    switch(action.type) {
        case ActionTypes.GET_PROD_LIST:
            return {...[], ...action.productData.reduce((obj, currProduct) => {
                const productId = currProduct.productId;
                obj[productId] = currProduct;
                return obj;
            }, {})};
        default:
            return state;
    }
}

export const getByProductId = (state, productId) => {
    return state.productById[productId];
}

const updateProductShopListReducerFn = (state, action) => {
    const {productId, userActionType, productData} = action;
    const targetProduct = getByProductId(state, productId);
    let total = 0;
    state.productData.forEach(
        (productDatum) => {
            console.log(productDatum);
            total += productDatum.unitQty;
        }
    );

    if (total >= envConst.MAX_MEAL_QTY && userActionType === 'ADD') {
        return state;
    }
    let newTargetData = {};
    if (typeof productId !== 'undefined' && targetProduct.unitStock > 0) {
        const targetProductDataArr = state.productData.map((productDatum) => {
            if (productDatum.productId === productId) {
                console.log('updateProductShopListReducerFn');
                console.log(userActionType);
                console.log(productData);
                if (productData && userActionType === 'NOODLES') {
                    return productData;
                } else if (productData && userActionType === 'NOODLES_ADD') {
                    console.log('targetProduct');
                    console.log(targetProduct);
                    // check if previously added to cart
                    let isExist = false;
                    const newProductSpec = targetProduct.productSpec.map(
                        (curNoodle) => {
                            // check consistency of spicy and noodle type
                            const curNoodleSpiciness = curNoodle.spec[0].specOptions;
                            const curNoodleSideDish = curNoodle.spec[1].specOptions;

                            const newNoodleSpiciness = productData.spec[0].specOptions;
                            const newNoodleSideDish = productData.spec[1].specOptions;
                            
                            // check other side dishes, (max 3 types of dishes)
                            let curNoodlesToppings = [], newNoodlesToppings = [];
                            for (let i=2; i<5; i++) {
                                if (typeof curNoodle.spec[i] !== 'undefined') {
                                    curNoodlesToppings.push(curNoodle.spec[i].specOptions)
                                }
                                if (typeof productData.spec[i] !== 'undefined') {
                                    newNoodlesToppings.push(productData.spec[i].specOptions)
                                }
                            }
                            // stringify can compare original noodle data and newly selected noodle data
                            if (
                                JSON.stringify(curNoodleSpiciness) === JSON.stringify(newNoodleSpiciness) &&
                                JSON.stringify(curNoodleSideDish) === JSON.stringify(newNoodleSideDish) && 
                                JSON.stringify(curNoodlesToppings) === JSON.stringify(newNoodlesToppings)
                            ) {
                                isExist = true;
                                return {
                                    ...curNoodle,
                                    qty: curNoodle.qty + 1,
                                };
                            }
                            return curNoodle;
                        }
                    );
                    if (isExist) {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: newProductSpec,
                            unitQty: 1,
                        };
                    } else {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: [...targetProduct.productSpec, productData],
                            unitQty: 1,
                        };
                        newTargetData.productSpec = newTargetData.productSpec.map(
                            (item, idx) => (
                                {
                                    ...item,
                                    id: idx,
                                }
                            )
                        );
                    }
                    let totalPrice = 0;
                    newTargetData.productSpec.forEach(
                      (noodle) => {
                        let price = newTargetData.unitPrice;
                        noodle.spec.forEach(
                            (spec) => {
                              if (spec.specName === '配菜') {
                                spec.specOptions.forEach(
                                  (opt) => {
                                    price += spec.unitPrice;
                                  }
                                );
                              } else if (spec.specName === '米線系列') {
                                spec.specOptions.forEach(
                                    (opt) => {
                                      price += opt.unitPrice;
                                    }
                                  );
                              }
                            }
                        );
                        totalPrice += (price * noodle.qty);
                      }
                    );
                    newTargetData.totalPrice = totalPrice;
                    return newTargetData;
                } else if (productData && userActionType === 'SOUPRICE_ADD') {
                    console.log('SOUPRICE_ADD');
                    console.log('targetProduct');
                    console.log(targetProduct);
                    // check if previously added to cart
                    let isExist = false;
                    const newProductSpec = targetProduct.productSpec.map(
                        (curNoodle) => {
                            // check consistency of spicy and noodle type
                            const curNoodleSpiciness = curNoodle.spec[0].specOptions;
                            const curNoodleSideDish = curNoodle.spec[1].specOptions;

                            const newNoodleSpiciness = productData.spec[0].specOptions;
                            const newNoodleSideDish = productData.spec[1].specOptions;
                            
                            // check other side dishes, (max 3 types of dishes)
                            let curNoodlesToppings = [], newNoodlesToppings = [];
                            for (let i=2; i<5; i++) {
                                if (typeof curNoodle.spec[i] !== 'undefined') {
                                    curNoodlesToppings.push(curNoodle.spec[i].specOptions)
                                }
                                if (typeof productData.spec[i] !== 'undefined') {
                                    newNoodlesToppings.push(productData.spec[i].specOptions)
                                }
                            }
                            if (
                                JSON.stringify(curNoodleSpiciness) === JSON.stringify(newNoodleSpiciness) &&
                                JSON.stringify(curNoodleSideDish) === JSON.stringify(newNoodleSideDish) && 
                                JSON.stringify(curNoodlesToppings) === JSON.stringify(newNoodlesToppings)
                            ) {
                                isExist = true;
                                return {
                                    ...curNoodle,
                                    qty: curNoodle.qty + 1,
                                };
                            }
                            return curNoodle;
                        }
                    );
                    if (isExist) {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: newProductSpec,
                            unitQty: 1,
                        };
                    } else {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: [...targetProduct.productSpec, productData],
                            unitQty: 1,
                        };
                        newTargetData.productSpec = newTargetData.productSpec.map(
                            (item, idx) => (
                                {
                                    ...item,
                                    id: idx,
                                }
                            )
                        );
                    }
                    let totalPrice = 0;
                    newTargetData.productSpec.forEach(
                      (noodle) => {
                        let price = newTargetData.unitPrice;
                        noodle.spec.forEach(
                            (spec) => {
                              if (spec.specName === '配菜') {
                                spec.specOptions.forEach(
                                  (opt) => {
                                    price += spec.unitPrice;
                                  }
                                );
                              }
                            }
                        );
                        totalPrice = (totalPrice + ((price - 21) * noodle.qty));
                      }
                    );
                    newTargetData.totalPrice = totalPrice;
                    return newTargetData;
                } else if (productData && userActionType === 'TEATIME_ADD') {
                    console.log('TEATIME_ADD');
                    console.log('targetProduct');
                    console.log(targetProduct);
                    // check if previously added to cart
                    let isExist = false;
                    const newProductSpec = targetProduct.productSpec.map(
                        (curNoodle) => {
                            // check consistency of spicy and noodle type
                            const curNoodleSpiciness = curNoodle.spec[0].specOptions;

                            const newNoodleSpiciness = productData.spec[0].specOptions;
                            if (
                                JSON.stringify(curNoodleSpiciness) === JSON.stringify(newNoodleSpiciness)
                            ) {
                                isExist = true;
                                return {
                                    ...curNoodle,
                                    qty: curNoodle.qty + 1,
                                };
                            }
                            return curNoodle;
                        }
                    );
                    console.log('isExist');
                    console.log(isExist);
                    console.log(newProductSpec);
                    if (isExist) {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: newProductSpec,
                            unitQty: 1,
                        };
                    } else {
                        newTargetData = {
                            ...targetProduct,
                            productSpec: [...targetProduct.productSpec, productData],
                            unitQty: 1,
                        };
                        newTargetData.productSpec = newTargetData.productSpec.map(
                            (item, idx) => (
                                {
                                    ...item,
                                    id: idx,
                                }
                            )
                        );
                    }
                    let totalPrice = 0;
                    newTargetData.productSpec.forEach(
                      (noodle) => {
                        let price = newTargetData.unitPrice;
                        noodle.spec.forEach(
                            (spec) => {
                              if (spec.specName === '配菜') {
                                spec.specOptions.forEach(
                                  (opt) => {
                                    price += spec.unitPrice;
                                  }
                                );
                              }
                            }
                        );
                        totalPrice = (totalPrice + (price * noodle.qty));
                      }
                    );
                    newTargetData.totalPrice = totalPrice;
                    return newTargetData;
                } else if (userActionType === 'DEL' && productDatum.unitQty > 0 ) {
                    return {
                        ...productDatum,
                        unitQty: productDatum.unitQty - 1,
                        unitStock: productDatum.unitStock + 1,
                    }
                } else if (userActionType === 'ADD' && productDatum.unitQty < envConst.MAX_MEAL_ITEM) {
                    return {
                        ...productDatum,
                        unitQty: productDatum.unitQty + 1,
                        unitStock: productDatum.unitStock - 1
                    }
                }
            }
            return productDatum;
        });
        let updatedProductById = Object.assign({}, state.productById[productId]);
        if (userActionType === 'DEL') {
            updatedProductById.unitQty --;
            updatedProductById.unitStock ++;
        } else if (userActionType === 'ADD') {
            updatedProductById.unitQty ++;
            updatedProductById.unitStock --;
        } else if (userActionType === 'NOODLES') {
            updatedProductById = productData;
        } else if (userActionType === 'NOODLES_ADD') {
            updatedProductById = newTargetData;
        } else if (userActionType === 'SOUPRICE_ADD') {
            updatedProductById = newTargetData;
        } else if (userActionType === 'TEATIME_ADD') {
            updatedProductById = newTargetData;
        }
        return {
            ...state,
            productData: targetProductDataArr,
            productById: {
                ...state.productById,
                [productId]: updatedProductById,
            },
        }
    }
    
    return state;
}

const productsReducer = (state = initialStateObj, action) => {
    switch(action.type) {
        case ActionTypes.UPDATE_PROD_SHOP_LIST:
            return updateProductShopListReducerFn(state, action);
        case ActionTypes.UPDATE_PROD_QTY_TO_ZERO: {
            const {productId, productType} = action;
            console.log('NOODLES aero');
            console.log(productId, productType);
            if (productType === 'NOODLES') {
                return {
                    ...state,
                    productData: state.productData.map((productDatum) => {
                        if (productDatum.productId === productId) {
                            productDatum.unitStock = productDatum.unitStock + productDatum.unitQty;
                            productDatum.unitQty = 0;
                            productDatum.productSpec = [];
                        }
                        return productDatum;
                    }),
                    productById: {
                        ...state.productById, 
                        [productId]: {
                            ...state.productById[productId],
                            unitStock: state.productById[productId].unitStock + state.productById[productId].unitQty,
                            unitQty: 0,
                            productSpec: [],
                        }
                    }
                }
            }
            return {
                ...state,
                productData: state.productData.map((productDatum) => {
                    if (productDatum.productId === productId) {
                        productDatum.unitStock = productDatum.unitStock + productDatum.unitQty;
                        productDatum.unitQty = 0;
                    }
                    return productDatum;
                }),
                productById: {
                    ...state.productById, 
                    [productId]: {
                        ...state.productById[productId],
                        unitStock: state.productById[productId].unitStock + state.productById[productId].unitQty,
                        unitQty: 0,
                    }
                }
            }
        }
        case ActionTypes.ADD_BEVERAGE: {
            const list = action.data;
            const newList = state.productData.concat(list);
            return {
                ...state,
                productData: newList,
                productById: newList.reduce(
                    (obj, currProduct) => {
                        const productId = currProduct.productId;
                        obj[productId] = currProduct;
                        return obj;
                    }, {}
                )
            }
        }
        case ActionTypes.REMOVE_BEVERAGE: {
            console.log(state.productData);
            const newProductData = state.productData.filter(
                (product) => {
                    if (product.productId.indexOf('dr') !== -1) return false;
                    return true;
                }
            );
            console.log(newProductData);
            const newProductById = {};
            newProductData.forEach(
                (product) => {
                    newProductById[product.productId] = product;
                }
            );
            return {
                ...state,
                productData: newProductData,
                productById: newProductById,
            }
        }
        default:
            return {
                ...state,
                productData: products(state.productData, action),
                productById: productById(state.productById, action),
            }
    }
}

export default productsReducer;
