import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import lng from '../misc/lng';

const { chiLng } = lng;
const SimpleAlertDialog = ({shouldOpen, alertTitle, alertMsg, handleAlertOk, handleAlertCancel}) => {
    return (
        <div>
            <Dialog
                open={shouldOpen}
            >
                <DialogTitle>{ alertTitle }</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{ alertMsg }</DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ handleAlertCancel }> { chiLng.okAlertCancel } </Button>
                    <Button color="primary" onClick={ handleAlertOk }> { chiLng.okAlert } </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

SimpleAlertDialog.defaultProps = {
    shouldOpen: false
}

export default SimpleAlertDialog;