import envConstDev from './envConst.dev.js';
import envConstProd from './envConst.prod.js';

const envConst = process.env.NODE_ENV === 'development' ? envConstDev : envConstProd;

// Common functions for both dev and prod 
// named export
export const menuNameExtractHelper = () => {
    const dayMapper = (dayNo) => {
        const dayName = [
            'monday', //sunday count as monday
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'monday' // saturaday count as monday
        ]
        return dayName[dayNo];
    }
    const today = new Date();
    return dayMapper(today.getDay());
};

export default envConst;