import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types'
import lng, { envConst } from '../misc/lng';

class PromoNotificationBanner extends React.Component {
    render() {
        // Props value received from render props
        const {typeOfPromoMsg} = this.props.stateObj;
        const colorDeterminer = typeOfPromoMsg === 'success' ? "primary.main" : "error.main";
        return typeOfPromoMsg !== 'none' ? (
            <Box
                borderRadius={2}
                textAlign="center"
                border={1}
                color={colorDeterminer}
                mt={3}
                pt={0.5}
                pb={0.5}
                pl={1.5}
                pr={1.5}
                width='auto'
            >
                { typeOfPromoMsg === 'success' ? lng.chiLng.applyPromoOk : lng.chiLng.applyPromoFailed }
            </Box>
        ) : null;

    }
}

PromoNotificationBanner.propTypes = {
    stateObj: PropTypes.object.isRequired,
}

export default PromoNotificationBanner;