import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import lng from '../misc/lng';

const { chiLng } = lng;
const useStyles = makeStyles((theme) => ({
    actionPanel: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
    generalBtnStyle: {
        padding: '8px',
        paddingRight: '0px',
        paddingLeft: '3px',
    },
}));

const AdminActionPanel = (props) => {
    const classes = useStyles();
    // state object should be kept as 1 layer
    const initialState = {
        orderIconColored: true,
        settingsIconColored: false,
    };
    // handle coloring state of the action panel icons
    const [color, setColorCondition] = useState(initialState);
    // set the target color and de-colourize others
    const updateIconColor = (target) => {
        switch(target) {
            case 'orderList':
                setColorCondition({
                    ...{
                        settingsIconColored: false,
                        orderIconColored: true,
                    }
                });
                break;
            case 'settings':
                setColorCondition({
                    ...{
                        settingsIconColored: true,
                        orderIconColored: false,
                    }
                });
                break;
        }
    }
    const renderFromRenderProsFn = () => {
        // pickedElement is just an Element of react component returned by getView, it describes the component
        // instances only, therefore cannot use as <pickedElement/>
        let pickedElement = props.render();
        const enhancedPickedElement = React.cloneElement(
            pickedElement,
            { changeSubView: props.changeSubView }
        );
        return enhancedPickedElement;
    }

    return (
        <React.Fragment>
            <div className={classes.actionPanel}
                style={{
                    display: props.currentView === 'login' ? 'none' : 'block',
                    marginRight: '10px'
                }}>
                <IconButton
                        classes={{root: classes.generalBtnStyle}}
                        onClick={() => {
                            // highlight icon and de-highlight other icon
                            updateIconColor('orderList');
                            // change view
                            props.changeSubView('orderList');
                        }}
                >
                    <HomeIcon {...(color.orderIconColored ? {color: 'primary'} : null)} />
                </IconButton>
                <IconButton
                        classes={{root: classes.generalBtnStyle}}
                        onClick={() => {
                            // highlight icon and de-highlight other icon
                            updateIconColor('settings');
                            // change view
                            props.changeSubView('settings');
                        }}
                >
                    <SettingsIcon {...(color.settingsIconColored ? {color: 'primary'} : null)}/>
                </IconButton>
                <IconButton
                    classes={{root: classes.generalBtnStyle}}
                    onClick={() => {
                            Cookies.set('isQuickOrderAdminLogin', true);
                            props.changeSubView('login');
                        }
                    }
                >
                    <ExitToAppIcon />
                </IconButton>
            </div>
            {renderFromRenderProsFn()}
        </React.Fragment>
    );
}

export default AdminActionPanel;