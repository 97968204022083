import React from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import {withStyles} from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import lng, { envConst } from '../misc/lng';

const { chiLng } = lng;
const { API_CHK_ORDER_URL } = envConst;
const styles = () => {
    return {
        root: {
            padding: '20px 35px',
            flexWrap: 'wrap',
        },
        title: {
            textAlign: 'center',
        },
        icon: {
            color: '#27AE60',
            fontSize: '13rem',
            marginTop: '30px',
            marginBottom: '30px',
        },
        lastLine: {
            marginTop: '0.5rem'
        }
    }
}
class SuccessOrderCard extends React.Component {
    constructor(props) {
        super(props);
        this.viewOrder = this.viewOrder.bind(this);
    }
    
    viewOrder() {
        const {generatedOrderId} = this.props;
        const targetUrl = `${API_CHK_ORDER_URL}/${generatedOrderId}`;
        console.log(targetUrl);
        window.location.href = targetUrl;
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h4" classes={{root: classes.title}}>
                    { chiLng.successOrderOut }
                </Typography>
                <div>
                    <CheckCircleIcon classes={{root: classes.icon}} />
                </div>
                <div style={{display:'block', marginBottom: '0.3rem'}}>
                    <Typography variant="h6">
                        { chiLng.orderOutTime }
                    </Typography>
                </div>
                <div style={{marginBottom: '1.6rem'}}>
                    <Typography variant="h6">
                        {window.moment(this.props.orderTimeStamp).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                </div>
                <Button
                    fullWidth
                    className={classes.button}
                    color="primary"
                    onClick={this.viewOrder}
                    variant="contained"
                    style={{cursor: 'pointer'}}
                >
                    <Typography variant="h6">{ chiLng.viewOrderStatus }</Typography>
                </Button>
                <Typography variant="subtitle2" className={classes.lastLine}>{ chiLng.bringYourChanges }</Typography>
            </div>
        );
    }
}

SuccessOrderCard.propTypes = {
    classes: PropTypes.object.isRequired,
    generatedOrderId: PropTypes.string.isRequired,
    orderTimeStamp: PropTypes.string.isRequired,
};

export default withStyles(styles)(SuccessOrderCard);