import React from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SimpleMessageDialog from './SimpleMessageDialog';
import lng, { envConst } from '../misc/lng';
import prefix from '../misc/simulate/phone-prefix.json';
import commonFn from '../misc/common.js';

const timeList = envConst.DELIVERY_OPTIONS;
const regexForPhone = /^[235679]\d{7}$/;
const phonePrefixList = Object.keys(prefix);
const styles = (theme) => {
    return {
        root: {
            padding: '20px 35px',
            flexWrap: 'wrap',
        },
        title: {
            textAlign: 'left',
        },
        addressInput: {
            width: '100%',
        },
        formControl: {
            minWidth: '100%',
            marginTop: '30px',
        },
        promoRoot: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            //basline to ensure textfield and button are aligned
            alignItems: 'baseline', // not default stretch for our case as button will have its height too high
            height: 'inherit',
            verticalAlign: 'middle',
        },
        promoCodeInput: {
            width: '78%',
            marginRight: '0.2rem'
        },
        promoApplyBtn: {
            width: '20%'
        },
        whatsApp: {
            backgroundColor: '#25d366',
            color: 'white',
        },
        whatsAppIcon: {
            color: 'white',
        },
        errDiv: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1'
        }
    }
}

class ConfirmOrderCard extends React.Component {
    constructor(props) {
        super(props);
        // const defaultAddress = envConst.DELIVERY_ADDR_OPTIONS[0];
        const defaultAddress = '';
        this.addressList = envConst.DELIVERY_ADDR_OPTIONS;
        // get mobile bl (should retrieve from DB)
        this.BList = lng.sysLng.BList;
        this.state = {
            address: defaultAddress,
            promoCodeInput: '',
            phonePrefix: '852',
            phoneNo: '',
            contactPerson: '',
            deliveryTime: '',
            isError: {
                address: false,
                phoneNo: false,
                contactPerson: false,
                deliveryTime: false, 
            },
            timeList,
            shouldModalOpen: false,
            shouldLoadingIndicatorShow: true,
            msg: '', // state to store message of warning dialog
            shouldDisable: true, // whether or not the promo button should disabled
            typeOfPromoMsg: 'none', // determine which type of message should show, none, failed or success
            shouldPromoInputDisabled: false, // whether or not the promo input is allowed, default should allow
            shouldOverrideOrder: false // whether or not override time selection should allow to choose
        };
        this.timeCheckIvl = [];
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangePhoneNo = this.handleChangePhoneNo.bind(this);
        this.handleChangeContactPerson = this.handleChangeContactPerson.bind(this);
        this.handleChangeDeliveryTime = this.handleChangeDeliveryTime.bind(this);
        this.handleOrderProcess = this.handleOrderProcess.bind(this);
        this.shouldDisable = this.shouldDisable.bind(this);
        this.setOrderEntryToBackend = this.setOrderEntryToBackend.bind(this);
        this.reset = this.reset.bind(this);
        this.saveUserContactData = this.saveUserContactData.bind(this);
        this.handleChangePromoInput = this.handleChangePromoInput.bind(this);
        this.handlePromoClick = this.handlePromoClick.bind(this);
        this.handleChangePhonePrefix = this.handleChangePhonePrefix.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        // set mounting state to prevent noops error
        this.mount = true;
        // update total amount (without discount)
        this.props.setTotalPay();
        console.log(this.props);
        const time1100 = new Date();
        // order cut time
        time1100.setHours('11');
        time1100.setMinutes('00');
        const time1320 = new Date();
        time1320.setHours('13');
        time1320.setMinutes('20');
        this.timeCheckIvlSpecial = setInterval(() => {
            const curTime = new Date();
            this.timeList = [];
            if (this.props.shop === 'sh02') {
                // time list for tea time
                if (this.props.wsShouldOrderOverride || curTime <= time1320) {
                    this.timeList = envConst.DELIVERY_OPTIONS_TEA_TIME;
                }
            } else {
                // Time list for lunch
                if (this.props.wsShouldOrderOverride || curTime <= time1100) {
                    // provide 3 time slots for user to choose
                    if (this.state.address === '招商局物流中心') {
                        this.timeList = envConst.DELIVERY_OPTIONS_B;
                    } else if (this.state.address === '豐樹青衣物流中心') {
                        this.timeList = envConst.DELIVERY_OPTIONS_C;
                    } else {
                        this.timeList = envConst.DELIVERY_OPTIONS;
                    }
                }
            }
            const getRefreshTime = () => {
                if (this.timeList.includes(this.state.deliveryTime)) {
                    return this.state.deliveryTime
                }
                return '';
            }
            this.setState(
                {
                    // timeList: envConst.DELIVERY_OPTIONS,
                    timeList: this.timeList,
                    // deliveryTime: this.state.deliveryTime,
                    deliveryTime: getRefreshTime(),
                }
            );
        }, 1000);
    }
    componentWillUnmount() {
        this.mount = false;
        for(let i=0; i<this.timeCheckIvl; i++) {
            clearTimeout(this.timeCheckIvl[i]);
        }
        clearInterval(this.timeCheckIvlSpecial);
    }

    back() {
        console.log('back');
        this.props.removeBeverage();
        this.props.changeView('productList');
    }
    // Perform handle promo click in component itself
    // Order data is enough for further deducing the promo code, no need to handle in ParentOrder container
    handlePromoClick() {
        const promoCode = this.state.promoCodeInput.trim().toLowerCase();
        const targetUrl = envConst.API_VERIFY_PROMO_CODE;
        const {backendGeneralTimeout} = envConst;
        // GET parameters to pass to...
        const param = {
            orderData: this.props.orderData,
            promoCode
        };
        return axios.get(targetUrl, {params: param}, {timeout: backendGeneralTimeout}).then((res) => {
            try {
                if (res.data.res.success) {
                    const discountData = res.data.res.inst;
                    console.log(discountData)
                    // check if discount condition is met
                    if (discountData && discountData['apply-condition']) {
                        const shouldPromoApply = commonFn.discountConditionHelper(
                            discountData['apply-condition'],
                            {totalAmount: this.props.totalPrice}
                        );
                        // failed promo condition
                        if (!shouldPromoApply) {
                            // promo not exists
                            this.setState({
                                ...this.state,
                                shouldDisable: false,
                                typeOfPromoMsg: 'failed',
                            });
                            return
                        }
                    }
                    // retrieive form factor to calculate discount
                    const discountFormFactor = res.data.res.inst['discount-form-factor'];
                    this.props.updateDiscountFormFactortoParent(discountFormFactor);
                    // promo did exists
                    this.setState({
                        ...this.state,
                        shouldDisable: true,
                        typeOfPromoMsg: 'success',
                        shouldPromoInputDisabled: true, // promo only allow input once
                    });
                } else {
                    // promo not exists
                    this.setState({
                        ...this.state,
                        shouldDisable: false,
                        typeOfPromoMsg: 'failed',
                    });
                }
            } catch (err) {
                // anything else wrong
                this.setState({
                    ...this.state,
                    shouldDisable: false,
                    typeOfPromoMsg: 'failed',
                });
            }
        });
    }

    handleChangePromoInput(event) {
        // uncontrolled input, we handle all the input changes and state assignment
        const promoCode = event.target.value.trim();
        if (promoCode !== '' && typeof promoCode !== 'undefined') {
            this.setState({
                promoCodeInput: promoCode,
                shouldDisable: false
            });
        } else {
            this.setState({
                promoCodeInput: promoCode,
                shouldDisable: true
            });
        }
    }
    
    handleChangeAddress(event) {
        console.log(event);
        this.setState({
            address: event.target.value,
        });
    }
    
    handleChangePhoneNo(event) {
        const val = event.target.value;
        if (regexForPhone.test(val)) {
            this.setState({
                isError: {
                    ...this.state.isError,
                    phoneNo: false,
                },
                phoneNo: val,
            });
        } else {
            this.setState({
                phoneNo: event.target.value,
                isError: {
                    ...this.state.isError,
                    phoneNo: true,
                },
            });
        }
    }
    
    handleChangeContactPerson(event) {
        const val = event.target.value;
        if (val !== '') {
            this.setState({
                isError: {
                    ...this.state.isError,
                    contactPerson: false,
                },
                contactPerson: event.target.value,
            });  
        } else {
            this.setState({
                isError: {
                    ...this.state.isError,
                    contactPerson: true,
                },
                contactPerson: event.target.value,
            });
        }
    }
    
    handleChangeDeliveryTime(event) {
        this.setState({
            deliveryTime: event.target.value,
        });
    }
    
    reset() {
        this.setState(
            {
                address: '',
                phoneNo: '',
                contactPerson: '',
                deliveryTime: '',
            }
        );
    }
    
    shouldDisable() {
        if (
            this.state.address === '' ||
            this.state.phoneNo === '' ||
            this.state.contactPerson === '' ||
            this.state.deliveryTime === '' ||
            this.state.isError.address ||
            this.state.isError.phoneNo ||
            this.state.isError.contactPerson ||
            this.state.isError.deliveryTime
        ) {
            return true;
        }
        return false;
    }
    
    /**
     * Set provided order entry to the back for storage
     * @param {object} orderDataToBeSaved 
     * @return {Promise} A promise for futher resolved
     */
    setOrderEntryToBackend(orderDataToBeSaved) {
        // Signature should be fixed for the API path
        const targetUrl = envConst.API_REST_URL;
        const backendGeneralTimeout = envConst.backendGeneralTimeout;
        return axios.put(targetUrl, orderDataToBeSaved, {timeout: backendGeneralTimeout});
    }

    handleChangePhonePrefix(event) {
        this.setState(oldValues => ({
            ...oldValues,
            phonePrefix: event.target.value,
          }));
    }

    async saveUserContactData(
        orderAddress,
        orderClientContactName,
        orderClientContactNo,
        orderDeliveryTime,
        orderMoment,
        payAmount,
    ) {
        await this.props.confirmOrderStatus(
            'confirmed',
            orderAddress,
            orderClientContactName,
            orderClientContactNo,
            orderDeliveryTime,
            orderMoment,
            payAmount,
        );
    }

    getBl() {
        // should retrieve from DB (TBD)
        return this.BList;
    }
    
    async handleOrderProcess() {
        const BList = this.getBl();
        const { 
            chiLng: {
                orderProcessing,
                smsWentWrong,
                orderWentWrong,
                failedOrderCreate,
                whatsappOrder,
                orderWentWrong2,
                wrongCode,
                wrongCode2,
                whatsappCallNo
            }
        } = lng;
        const {classes} = this.props;
        // Show processing indicator... 
        this.setState({
            shouldModalOpen: true,
            msg: orderProcessing
        });
        // check valid user
        const formattedPhoneNo = this.state.phoneNo.trim().replace(/\s/, '');
        if (BList.includes(formattedPhoneNo)) {
            this.setState({
                shouldModalOpen: true,
                msg: failedOrderCreate,
                shouldLoadingIndicatorShow: false
            });
            this.timeCheckIvl.push(setTimeout(() => {
                if (this.mount) {
                    this.setState({
                        shouldModalOpen: false,
                        msg: '',
                        shouldLoadingIndicatorShow: true
                    });
                }
            }, 3000));
            return;
        }
        // save user order time
        const orderMoment = window.moment.tz('Asia/Hong_Kong');
        console.log(this.props.payAmount)
        // Update order status passed from previous page to store
        await this.saveUserContactData(
            this.state.address,
            this.state.contactPerson,
            this.state.phoneNo,
            this.state.deliveryTime,
            orderMoment,
            parseInt(this.props.totalPrice),
        );

        // Prepare a well-formatted data to insert to back-end
        const generateFormattedData = () => {
            const targetOrder = this.props.orderData;
            const formattedOrderforSave = {};
            const generatedOrderId = Object.keys(targetOrder)[0];
            
            formattedOrderforSave.generatedOrderId = generatedOrderId;
            formattedOrderforSave.orderUrlHash = targetOrder[generatedOrderId].orderUrlHash;
            formattedOrderforSave.orderStatus = targetOrder[generatedOrderId].orderStatus;
            formattedOrderforSave.orderTimeStamp = targetOrder[generatedOrderId].orderTimeStamp;
            formattedOrderforSave.orderAddress = this.state.address;
            formattedOrderforSave.orderClientContactNo = `${this.state.phonePrefix}${this.state.phoneNo}`;
            formattedOrderforSave.orderClientContactName = this.state.contactPerson;
            formattedOrderforSave.orderDeliveryTime = this.state.deliveryTime;
            formattedOrderforSave.orderDetails = targetOrder[generatedOrderId].orderDetails;
            formattedOrderforSave.orderPayAmount = parseInt(this.props.payAmount);

            return formattedOrderforSave;
        };

        const aBackEndPromise = this.setOrderEntryToBackend(generateFormattedData());
        aBackEndPromise.then((res) => {
            // success response here
            this.setState({
                shouldModalOpen: false,
            }, () => {
                // Change the view, indicating success of the order
                // Pass the order ID and order time to successOrder for further view & processing 
                this.props.changeView('successOrder', res.data.generatedOrderId, res.data.orderTimeStamp);
            });
        }).catch((err) => {
            console.log(err)
            this.setState({
                shouldLoadingIndicatorShow: false,
                msg: (
                    <div classes={classes.errDiv}>
                        <p style={{marginBottom: '0px'}}>
                            {orderWentWrong}
                        </p>
                        
                        <p style={{marginTop: '0px'}}>
                            {orderWentWrong2}
                        </p>

                        <Button
                            variant="contained"
                            className={this.props.classes.whatsApp}
                            startIcon={<WhatsAppIcon className={this.props.classes.whatsAppIcon} />}
                            onClick={() => {
                                window.open(
                                    `https://wa.me/852${whatsappCallNo}`,
                                    '_blank'
                                );
                            }}
                        >
                            {whatsappOrder}
                        </Button>

                        <p style={{textAlign: 'left'}}>
                            <Alert style={{ fontSize: '1.2rem' }} severity="error">{wrongCode}: &lt;{err.toString()}&gt; {wrongCode2}</Alert>
                        </p>
                    </div>
                ),
            }, () => {
                this.timeCheckIvl.push(setTimeout(() => {
                    if (this.mount) {
                        this.setState({
                            shouldModalOpen: false
                        });
                        // redirect to main page when error occurs
                        window.location.replace(window.location);
                    }
                }, 15000));
            });
        });
    }
    
    render() {
        const classes = this.props.classes;
        const {
            chiLng: {
                deliveryAddr,
                contactNo,
                contactPerson,
                deliveryTime,
                resetInput,
                sendOrderOut,
                prodConfirmOrder,
                promoCodeInput,
                promoCodeApply,
                timeReachLabel,
                back,
            }
        } = lng;
        
        return (
            <div className={classes.root}>
                <SimpleMessageDialog 
                    lng={this.state.msg}
                    modalOpen={this.state.shouldModalOpen}
                    shouldLoadingIndicatorShow={this.state.shouldLoadingIndicatorShow}
                />
                <Typography variant="h6" classes={{root: classes.title}}>
                    {prodConfirmOrder}
                </Typography>
                {/* <TextField
                    id="filled-name"
                    label={deliveryAddr}
                    className={classes.addressInput}
                    value={this.state.address}
                    onChange={this.handleChangeAddress}
                    margin="normal"
                /> */}
                <FormControl error={this.state.address === ''} className={classes.formControl} margin='normal'>
                    <InputLabel htmlFor="address">{deliveryAddr}</InputLabel>
                    <Select
                        style={{textAlign: 'left'}}
                        value={this.state.address}
                        onChange={this.handleChangeAddress}
                        inputProps={{
                            name: 'address',
                        }}
                        disabled={this.state.timeList.length <= 0 ? true : false}
                    >
                    {
                        this.addressList.map(
                            item => (
                                <MenuItem style={{textAlign: 'left'}} value={item} key={item}>{item}</MenuItem>
                            )
                        )
                    }
                    </Select>
                </FormControl>
                <div style={{display: 'flex'}}>
                    <Select
                        value={this.state.phonePrefix}
                        onChange={this.handleChangePhonePrefix}
                        inputProps={{
                            name: 'age',
                            id: 'age-simple',
                        }}
                        style={{height: '39px', marginTop: '25px'}}
                        renderValue={v => `+${v}`}
                    >
                            {
                                phonePrefixList.map(
                                    l => (
                                        <MenuItem key={prefix[l].name} value={l}>{`+${l} (${prefix[l].name})`}</MenuItem>
                                    )
                                )
                            }
                    </Select>
                    <TextField
                        id="filled-name"
                        label={contactNo}
                        className={classes.addressInput}
                        value={this.state.phoneNo}
                        onChange={this.handleChangePhoneNo}
                        margin="normal"
                        error={this.state.isError.phoneNo}
                    />
                </div>
                <TextField
                    id="filled-name"
                    label={contactPerson}
                    className={classes.addressInput}
                    value={this.state.contactPerson}
                    onChange={this.handleChangeContactPerson}
                    margin="normal"
                    error={this.state.isError.contactPerson}
                />
                
                <FormControl className={classes.formControl} margin='normal'>
                    <InputLabel htmlFor="time">{this.state.timeList.length <= 0 ? timeReachLabel : deliveryTime}</InputLabel>
                    <Select
                        style={{textAlign: 'left'}}
                        value={this.state.deliveryTime}
                        onChange={this.handleChangeDeliveryTime}
                        inputProps={{
                            name: 'deliveryTime',
                        }}
                        disabled={this.state.timeList.length <= 0 ? true : false}
                    >
                    {
                        this.state.timeList.map(
                            item => (
                                <MenuItem style={{textAlign: 'left'}} value={item} key={item}>{item}</MenuItem>
                            )
                        )
                    }
                    </Select>
                </FormControl>
                
                <div className={classes.promoRoot}>
                    <TextField
                        id="filled-promo"
                        label={promoCodeInput}
                        className={classes.promoCodeInput}
                        value={this.state.promoCodeInput}
                        margin="normal"
                        onChange={this.handleChangePromoInput}
                        disabled={this.state.shouldPromoInputDisabled}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.promoApplyBtn}
                        disabled={this.state.shouldDisable}
                        onClick={this.handlePromoClick}
                    >
                        {promoCodeApply}
                    </Button>
                </div>

                <Typography variant="body1" component="div">
                { /* Render props to provide state for outer components */ }
                    { this.props.render(this.state) }
                </Typography>
                
                <Grid container spacing={5} style={{marginTop: '2rem'}}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color="primary"
                            onClick={this.back}
                            variant="contained"
                            style={{cursor: 'pointer'}}
                        >
                            <Typography variant="h6">{ back }</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color="primary"
                            onClick={this.reset}
                            variant="contained"
                            style={{cursor: 'pointer'}}
                        >
                            <Typography variant="h6">{ resetInput }</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            color="primary"
                            onClick={this.handleOrderProcess}
                            variant="contained"
                            style={{cursor: 'pointer'}}
                            disabled={this.shouldDisable()}
                        >
                            <Typography variant="h6">{ sendOrderOut }</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ConfirmOrderCard.propTypes = {
    classes: PropTypes.object.isRequired,
    changeView: PropTypes.func.isRequired,
    orderData: PropTypes.object.isRequired,
    confirmOrderStatus: PropTypes.func.isRequired,
    shop: PropTypes.string.isRequired,
    removeBeverage: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmOrderCard);