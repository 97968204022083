import React from 'react';
import PropTypes from 'prop-types'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        progress: {
            margin: theme.spacing(2),
            display: 'inline-block'
        },
    }
}

const SimpleMessageDialog = (props) => {
    const {classes} = props;
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.modalOpen}>
                <div className={classes.paper} style={{display: 'flex'}}>
                    <Typography
                        variant="h5"
                        display="inline"
                        style={{
                            display: 'flex',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            justifyContent: 'center',
                            flex: 1
                        }}
                    >
                        {props.lng}
                    </Typography>
                    { 
                        props.shouldLoadingIndicatorShow ? (
                            <CircularProgress
                                size={35}
                                className={classes.progress}
                            />
                        ) : null
                    }
                </div>
            </Fade>
        </Modal>
    );
}

SimpleMessageDialog.propTypes = {
    lng: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Element)
    ]),
    modalOpen: PropTypes.bool.isRequired,
    shouldLoadingIndicatorShow: PropTypes.bool,
}

SimpleMessageDialog.defaultProps = {
    shouldLoadingIndicatorShow: true
}

export default withStyles(styles)(SimpleMessageDialog);