import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import specData from '../misc/simulate/productSpec';
// eslint-disable-next-line no-unused-vars
import lng, { envConst } from '../misc/lng';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const noodleType = specData['sh01']['psp01'].specOptions;
const spiciness = specData['sh01']['psp03'].specOptions;
const styles = () => {
    return {
        wrapper: {
            width: '100%',
            height: '100%',
            color: '#4F4F4F',
        },
        title: {
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '10px',
            color: '#4F4F4F',
        },
        spicinessTitleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 30px',
            color: '#4F4F4F',
        },
        spicinessTitle: {
            textAlign: 'left',
            fontSize: '24px',
            color: '#828282',
        },
        sideDishTitle: {
            textAlign: 'left',
            fontSize: '24px',
            color: '#828282',
            margin: '20px 30px 10px 30px',
            // marginTop: '10px',
        },
        sideDishItemsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            marginBottom: '10px',
            flexWrap: 'wrap',
            width: '90%',
            margin: '0 auto',
        },
        NoodleTypeItemsWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '90%',
            margin: '0 auto',
            flexWrap: 'wrap',
            color: '#4F4F4F',
        },
        noodleTypeItemsOutside: {
            marginTop: '10px',
            marginRight: '10px',
        },
        noodleTypeItems: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: 'auto',
            textAlign: 'center',
        },
        noodleTypeItemsSelected: {
            // display: 'inline-block',
            // fles: 'wrap', 
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: 'auto',
            color: 'white',
            textAlign: 'center',
            backgroundColor: '#e65100',
            // flex: '1 0 13%',
        },
        spicinessItemsOutside: {
            flex: '1 0 30%',
            marginTop: '10px',
        },
        spicinessItems: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 20px',
            width: '50px',
            margin: 'auto',
            textAlign: 'center',
            // flex: '1 0 13%',
        },
        spicinessItemsSelected: {
            // display: 'inline-block',
            // fles: 'wrap',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 20px',
            width: '50px',
            margin: 'auto',
            color: 'white',
            backgroundColor: '#e65100',
            textAlign: 'center',
            // flex: '1 0 13%',
        },
        sideDishItems: {
            display: 'inline-block',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: '5px',
            width: '50px',
            // flex: '1 0 13%',
            textAlign: 'center',
        },
        sideDishItemsSelected: {
            display: 'inline-block',
            border: '2px solid #e65100',
            borderRadius: '25px',
            padding: '5px 10px',
            margin: '5px',
            width: '50px',
            color: 'white',
            backgroundColor: '#e65100',
            textAlign: 'center',
        },
        qtyBtnWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4F4F4F',
        },
        itemQtyBtnWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4F4F4F',
        },
        spicinessItemsWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            margin: '0 auto',
            flexWrap: 'wrap',
            color: '#4F4F4F',
        },
        btnWrapper: {
            padding: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: '0',
            left: 0,
            right: 0,
            backgroundColor: '#e65100',
            
        },
        btn: {
            color: 'white',
            fontSize: '1.5rem',
        },
        removeBtn: {
            color: '#EB5757',
        },
        qtyBtn: {
            color: '#EB5757',
        },
        invisableBtn: {
            color: 'white',
        },
        emptyListWording: {
            marginTop: '40%',
            color: '#4F4F4F',
            fontSize: '1.8rem',
        },
    }
}
class AdminAddNoodlesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.sideDish = [];
        this.sideDish8 = [];
        this.sideDish10 = [];
        this.noodleType = [];
        this.spiciness = [];
        this.state = {
            qty: 1,
            item: {
                id: 0,
                qty: 1,
                sideDish: this.sideDish.map(
                    (dish) => (
                        {
                            ...dish,
                            selected: false,
                        }
                    )
                ),
                sideDish8: this.sideDish8.map(
                    (dish) => (
                        {
                            ...dish,
                            selected: false,
                        }
                    )
                ),
                sideDish10: this.sideDish10.map(
                    (dish) => (
                        {
                            ...dish,
                            selected: false,
                        }
                    )
                ),
                noodleType: {
                    productId: "",
                    productName: "",
                    productImg: "",
                    unitPrice: 0,
                    unitQty: 0,
                    unitStock: 0
                },
                spiciness: '',
            },
        };
    
        this.clickSideDishItem = this.clickSideDishItem.bind(this);
        this.getComfirmList = this.getComfirmList.bind(this);
        this.confirm = this.confirm.bind(this);
        this.isConfirmDisabled = this.isConfirmDisabled.bind(this);
        this.selectNoodleType = this.selectNoodleType.bind(this);
        this.selectSpicyness = this.selectSpicyness.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
    }

    componentDidMount() {
        // load the noodle side dish list (for dynamic menu update)
        const urlProductSpec = `${envConst.GET_MENU_URL}/productSpec.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
        // retrieve noodle side dish data from json file
        axios.get(urlProductSpec).then((res) => {
            const specData = res.data;
            this.noodleType = specData['sh01']['psp01'].specOptions;
            this.spiciness = specData['sh01']['psp03'].specOptions;
            this.sideDish = specData['sh01']['psp02'].specOptions;
            this.sideDish8 = specData['sh01']['psp04'].specOptions;
            this.sideDish10 = specData['sh01']['psp05'].specOptions;
        });
    }

    getTotalPrice(spec) {
        let sideDashSpec = [];
        let spicinessSpec = '';
        let noodleType = '';
        let totalPrice = this.props.initProductData.unitPrice;
        spec.forEach(
            (spec) => {
                if (spec.specName === '辣度') {
                    spicinessSpec = spec.specOptions[0];
                } else if (spec.specName === '配菜') {
                    spec.specOptions.forEach(
                        (i) => {
                          sideDashSpec.push(i);
                          totalPrice += spec.unitPrice;
                        }
                    );
                } else if (spec.specName === '米線系列') {
                  noodleType = spec.specOptions[0].productName;
                  totalPrice += spec.specOptions[0].unitPrice;
                }
            }
        );
        return totalPrice;
    };
    onEnter() {
        this.setState(
            {
                qty: 1,
                item: {
                    id: 0,
                    qty: 1,
                    sideDish: this.sideDish.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                    sideDish8: this.sideDish8.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                    sideDish10: this.sideDish10.map(
                        (dish) => (
                            {
                                ...dish,
                                selected: false,
                            }
                        )
                    ),
                    noodleType: {
                        productId: "",
                        productName: "",
                        productImg: "",
                        unitPrice: 0,
                        unitQty: 0,
                        unitStock: 0
                    },
                    spiciness: '',
                },
            }
        );
    }
    getTotalQty() {
        let totalQty = 0;
        this.state.itemList.forEach(
            (item) => {
                totalQty += item.qty;
            }
        );
        return totalQty;
    }

    clickSideDishItem(dishtype, sideDishId) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    [dishtype]: this.state.item[dishtype].map(
                        (dish) => {
                            if (dish.productId === sideDishId) {
                                return {
                                    ...dish,
                                    selected: !dish.selected,
                                };
                            }
                            return dish;
                        }
                    ),
                }
            }
        );
    }

    selectSpicyness(itemId, spicyName) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    spiciness: spicyName,
                },
            }
        );
    }

    selectNoodleType(type) {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    noodleType: type,
                },
            }
        );
    }

    getComfirmList () {
        const {item} = this.state;
        const spec = [
            {
                specName: '米線系列',
                specOptions: [item.noodleType],
                specQtyLimit: 1,
                unitPrice: 0
            },
            {
                specName: '辣度',
                specOptions: [item.spiciness],
                specQtyLimit: 1,
                unitPrice: 0
            },
            {
                specName: '配菜',
                specOptions: item.sideDish.map(
                    (dish) => {
                        if (dish.selected) {
                            return dish.productName;
                        }
                        return false;
                    }
                ).filter(dish => dish),
                specQtyLimit: -1,
                unitPrice: 5
            },
            {
                specName: '配菜',
                specOptions: item.sideDish8.map(
                    (dish) => {
                        if (dish.selected) {
                            return dish.productName;
                        }
                        return false;
                    }
                ).filter(dish => dish),
                specQtyLimit: -1,
                unitPrice: 8
            },
            {
                specName: '配菜',
                specOptions: item.sideDish10.map(
                    (dish) => {
                        if (dish.selected) {
                            return dish.productName;
                        }
                        return false;
                    }
                ).filter(dish => dish),
                specQtyLimit: -1,
                unitPrice: 10
            }
        ].filter(i => i.specOptions.length > 0);
        return {
            spec,
            price: this.getTotalPrice(spec),
            id: this.props.initProductData.productId,
        };
    }

    confirm() {
        const noodlesData = this.getComfirmList();
        console.log('noodlesData');
        console.log(noodlesData);
        this.props.confirm(noodlesData);
    }
    isConfirmDisabled() {
        let shouldDisable = false;
        const {item} = this.state;
        if (
            item.sideDish.filter(d => d.selected).length < 1 &&
            item.sideDish8.filter(d => d.selected).length < 1 &&
            item.sideDish10.filter(d => d.selected).length < 1
        ) {
            shouldDisable = true;
        } else if (item.spiciness === '') {
            shouldDisable = true
        } else if (item.noodleType.productId === '') {
            shouldDisable = true
        }
        return shouldDisable;
    }
    render() {
        const classes = this.props.classes;
        const {chiLng} = lng;
        const item = this.state.item;
    
        return (
            <Dialog fullScreen open={this.props.open} onEntered={this.onEnter} onClose={() => {}} TransitionComponent={Transition}>
                <div className={classes.wrapper}>
                    <Typography classes={{root: classes.title}}>
                        {chiLng.addNoodlesCardTitle}
                    </Typography>
                    <div className={classes.spicinessTitleWrapper}>
                        <Typography classes={{root: classes.spicinessTitle}}>
                            {chiLng.noodleType}
                        </Typography>
                    </div>
                    <div className={classes.NoodleTypeItemsWrapper}>
                        {
                            this.noodleType.map(
                                (type) => (
                                    <div className={classes.noodleTypeItemsOutside} key={type.productId}>
                                        <Typography
                                            classes={{root: item.noodleType.productName === type.productName ? classes.noodleTypeItemsSelected : classes.noodleTypeItems}}
                                            onClick={() => {this.selectNoodleType(type);}}
                                        >
                                            {`${type.productName} ($${type.unitPrice + this.props.initProductData.unitPrice})`}
                                        </Typography>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className={classes.spicinessTitleWrapper}>
                        <Typography classes={{root: classes.spicinessTitle}}>
                            {chiLng.spiciness}
                        </Typography>
                    </div>
                    <div className={classes.spicinessItemsWrapper}>
                        {
                            this.spiciness.map(
                                (spicyName) => (
                                    <div className={classes.spicinessItemsOutside} key={spicyName}>
                                        <Typography
                                            classes={{root: item.spiciness === spicyName ? classes.spicinessItemsSelected : classes.spicinessItems}}
                                            onClick={() => {this.selectSpicyness(item.id, spicyName);}}
                                        >
                                            {spicyName}
                                        </Typography>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <Typography classes={{root: classes.sideDishTitle}}>
                        {chiLng.sideDish5}
                    </Typography>
                    <div className={classes.sideDishItemsWrapper}>
                        {
                            item.sideDish.map(
                                (dish) => (
                                    <Typography
                                        key={dish.productId}
                                        classes={
                                            {
                                                root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                            }
                                        }
                                        onClick={() => {this.clickSideDishItem('sideDish', dish.productId);}}
                                    >
                                        {dish.productName}
                                    </Typography>
                                )
                            )
                        }
                    </div>
                    <Typography classes={{root: classes.sideDishTitle}}>
                        {chiLng.sideDish8}
                    </Typography>
                    <div className={classes.sideDishItemsWrapper}>
                        {
                            item.sideDish8.map(
                                (dish) => (
                                    <Typography
                                        key={dish.productId}
                                        classes={
                                            {
                                                root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                            }
                                        }
                                        onClick={() => {this.clickSideDishItem('sideDish8', dish.productId);}}
                                    >
                                        {dish.productName}
                                    </Typography>
                                )
                            )
                        }
                    </div>
                    <Typography classes={{root: classes.sideDishTitle}}>
                        {chiLng.sideDish10}
                    </Typography>
                    <div className={classes.sideDishItemsWrapper}>
                        {
                            item.sideDish10.map(
                                (dish) => (
                                    <Typography
                                        key={dish.productId}
                                        classes={
                                            {
                                                root: dish.selected ? classes.sideDishItemsSelected : classes.sideDishItems,
                                            }
                                        }
                                        onClick={() => {this.clickSideDishItem('sideDish10', dish.productId);}}
                                    >
                                        {dish.productName}
                                    </Typography>
                                )
                            )
                        }
                        <Typography
                            classes={{root: classes.sideDishItems}}
                            style={{opacity: 0}}
                        >
                        豬大腸
                        </Typography>
                        <Typography
                            classes={{root: classes.sideDishItems}}
                            style={{opacity: 0}}
                        >
                        豬大腸
                        </Typography>
                    </div>
                    <Divider variant="middle" />
                    <div style={{height: '100px'}} />
                    <div className={classes.btnWrapper}>
                        <Button color="primary" classes={{root: classes.btn}} onClick={this.props.cancel}>
                            {chiLng.cancel}
                        </Button>
                        <Button disabled={this.isConfirmDisabled()} color="primary" classes={{root: classes.btn}} onClick={this.confirm}>
                            {chiLng.comfirm}
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

AdminAddNoodlesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    initProductData: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    // confirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AdminAddNoodlesDialog);