import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import lng from '../misc/lng';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            maxWidth: '600px',
            minWidth: '320px',
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1099,
            paddingBottom: 0
        },
        binAvatar: {
            backgroundColor: theme.palette.secondary.dark,
            cursor: 'pointer',
        },
        orderConfirm: {
            color: theme.palette.background.paper,
            textAlign: 'center',
        },
        orderConfirmContainer: {
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
        },
        orderConfirmContainerDisabled: {
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: theme.palette.grey[400],
            cursor: 'not-allowed',
        },
        addProductsIcon: {
            color: theme.palette.grey[400],
            fontSize: 60,
        },
        listItemStyle: {
            color: theme.palette.text.primary
        },
        qtyBoxTxtStyle: {
            fontWeight: theme.typography.fontWeightRegular,
        },
        noodleItem: {
          height: '100px',
        },
        noodleListItemStyle: {
          paddingRight: '23%',
          paddingLeft: '10px',
        },
        noodleListItemTypoStyle: {
          fontSize: '14px',
        },
    }
});

const MealOrderList = ({
  productListArr,
  updateProductShopList,
  updateProductQtyToZero,
  onClickConfirm,
}) => {
    // Can add props as useStyles parameter
    const classes = useStyles();
    const theme = useTheme();
    // Determine current screen width for better style
    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm')); //media query hook
    const dense = false;
    const {chiLng:{prodConfirmOrderBtnLbl}} = lng;
    // Call upper functions from props (consume parent passed redux function)
    const updateProductShopListDel = (productId) => updateProductShopList(productId, 'DEL');
    const updateProductShopListAdd = (productId, e) => updateProductShopList(productId, 'ADD');
    const removeProductFromOrderList = (productId) => updateProductQtyToZero(productId);
    // set redux transaction state for control action and subsequent transaction handling
    const confirmOrder = () => onClickConfirm();
    // Box to handle view when nothing is added to the list
    let isSomethingAddedToCart = false;
    const noItemsOnListPrinter = () => {
        isSomethingAddedToCart = productListArr.some(productList => productList.unitQty > 0);

        if (!isSomethingAddedToCart) {
          return (
            <Box display="block" component="div" mt="4rem" mb="4rem">
                <AddShoppingCart className={classes.addProductsIcon} />
            </Box>
          )
        }
        return;
    };
    const getNoodleSubTitle = (noodle, unitPrice) => {
      console.log('noodle');
      console.log(noodle);
      console.log(unitPrice);
      let sideDashSpec = [];
      let spicinessSpec = '';
      let noodleType = '';
      let totalPrice = unitPrice;
      noodle.spec.forEach(
          (spec) => {
              if (spec.specName === '辣度') {
                  spicinessSpec = spec.specOptions[0];
              } else if (spec.specName === '配菜') {
                  spec.specOptions.forEach(
                      (i) => {
                        sideDashSpec.push(i);
                        totalPrice += spec.unitPrice;
                      }
                  );
              } else if (spec.specName === '米線系列') {
                noodleType = spec.specOptions[0].productName;
                totalPrice += spec.specOptions[0].unitPrice;
              }
          }
      );
      totalPrice *= noodle.qty;
      return `${noodleType}、${spicinessSpec}、${sideDashSpec.join('、')} ($${totalPrice})`;
    };
    const getSoupRiceSubTitle = (noodle, unitPrice) => {
      console.log('noodle');
      console.log(noodle);
      console.log(unitPrice);
      let sideDashSpec = [];
      let noodleType = '';
      let totalPrice = unitPrice;
      noodle.spec.forEach(
          (spec) => {
              if (spec.specName === '配菜') {
                  spec.specOptions.forEach(
                      (i) => {
                        sideDashSpec.push(i);
                        totalPrice += spec.unitPrice;
                      }
                  );
              }
          }
      );
      totalPrice -= 21;
      totalPrice *= noodle.qty;
      return `${sideDashSpec.join('、')} ($${totalPrice})`;
    };
    
    const getTeaTimeSubTitle = (noodle, unitPrice) => {
      console.log('noodle');
      console.log(noodle);
      console.log(unitPrice);
      let sideDashSpec = [];
      let noodleType = '';
      let totalPrice = unitPrice;
      noodle.spec.forEach(
          (spec) => {
              if (spec.specName === '配菜') {
                  spec.specOptions.forEach(
                      (i) => {
                        sideDashSpec.push(i);
                        totalPrice += spec.unitPrice;
                      }
                  );
              }
          }
      );
      totalPrice *= noodle.qty;
      return `${sideDashSpec.join('、')} ($${totalPrice})`;
    };
    const getNoodlesQty = (product) => {
      let qty = 0;
      product.productSpec.forEach(
        (spec) => {
          qty += spec.qty;
        }
      );
      return qty;
    }
    const updateNoodleSpec = (product, specId, action) => {
      console.log(product);
      if (product.unitStock <= 0 && action === 'ADD') {
          return;
      }
      const data = {
        ...product,
        productSpec: product.productSpec.map(
          (spec) => {
            if (spec.id === specId) {
              if (action === 'ADD') {
                return {
                  ...spec,
                  qty: spec.qty + 1,
                };
              } else {
                if (spec.qty <= 1) {
                  return null;
                }
                return {
                  ...spec,
                  qty: spec.qty - 1,
                };
              }
            }
            return spec;
          }
        ).filter(i => i),
      };
      data.totalPrice = 0;
      data.productSpec.forEach(
        (noodle) => {
          let price = data.unitPrice;
          noodle.spec.forEach(
              (spec) => {
                if (spec.specName === '配菜') {
                  spec.specOptions.forEach(
                    (opt) => {
                      price += spec.unitPrice;
                    }
                  );
                } else if (spec.specName === '米線系列') {
                  spec.specOptions.forEach(
                      (opt) => {
                        price += opt.unitPrice;
                      }
                    );
                }
              }
          );
          data.totalPrice += (price * noodle.qty);
        }
      );
      if (data.productSpec.length <= 0) {
        updateProductQtyToZero(product.productId, 'NOODLES');
        return;
      }
      updateProductShopList(product.productId, 'NOODLES', data);
    };
    const updateSoupRiceSpec = (product, specId, action) => {
      console.log(product);
      if (product.unitStock <= 0 && action === 'ADD') {
          return;
      }
      const data = {
        ...product,
        productSpec: product.productSpec.map(
          (spec) => {
            if (spec.id === specId) {
              if (action === 'ADD') {
                return {
                  ...spec,
                  qty: spec.qty + 1,
                };
              } else {
                if (spec.qty <= 1) {
                  return null;
                }
                return {
                  ...spec,
                  qty: spec.qty - 1,
                };
              }
            }
            return spec;
          }
        ).filter(i => i),
      };
      data.totalPrice = 0;
      data.productSpec.forEach(
        (noodle) => {
          let price = data.unitPrice - 21;
          noodle.spec.forEach(
              (spec) => {
                if (spec.specName === '配菜') {
                  spec.specOptions.forEach(
                    (opt) => {
                      price += spec.unitPrice;
                    }
                  );
                }
              }
          );
          data.totalPrice += (price * noodle.qty);
        }
      );
      if (data.productSpec.length <= 0) {
        updateProductQtyToZero(product.productId, 'NOODLES');
        return;
      }
      updateProductShopList(product.productId, 'NOODLES', data);
    };
    return (
        <Box 
          className={classes.root} boxShadow={3}
          style={{ width: matchesMobile ? '100%' : ''}}
        >
            {noItemsOnListPrinter()}
            <List dense={dense} style={{paddingBottom: 0}}>
              {
                  productListArr.map((productItem, idx) => {
                      const {productName, unitPrice, unitQty, productId} = productItem;
                      if (unitQty === 0) return null;
                      if (productItem.productType === 'noodles') {
                        return [(
                          <ListItem key={`prodListKey${productId}`}>
                            <ListItemAvatar>
                              <Avatar className={classes.binAvatar} onClick={() => {updateProductQtyToZero(productId, 'NOODLES');}}>
                                <DeleteIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={productName}
                              secondary={`$HKD ${productItem.totalPrice}`}
                              className={classes.listItemStyle}
                            />
                            <ListItemSecondaryAction>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{marginRight: '0px', color: 'white'}}
                                color="primary"
                              >
                                <RemoveCircle />
                              </IconButton>
                              <Box
                                textAlign="center"
                                style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                fontWeight={300}
                                className={classes.listItemStyle}
                              >
                                <span className={classes.qtyBoxTxtStyle}>{getNoodlesQty(productItem)}</span>
                              </Box>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="add"
                                color="primary"
                                style={{color: 'white'}}
                              >
                                <AddCircle />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ), productItem.productSpec.map(
                          (noodle, idx) => {
                            return (
                              <ListItem key={`prodListKey${productId}${idx}`}>
                                {/* <ListItemAvatar>
                                  <Avatar className={classes.binAvatar} onClick={removeProductFromOrderList.bind(null, productId)}>
                                    <DeleteIcon />
                                  </Avatar>
                                </ListItemAvatar> */}
                                <ListItemText
                                  primary={
                                    `${getNoodleSubTitle(noodle, productItem.unitPrice)}`
                                  }
                                  // secondary={${getNoodleSubTitle(noodle)}}
                                  className={classes.listItemStyle}
                                  classes={{root: classes.noodleListItemStyle, primary: classes.noodleListItemTypoStyle}}
                                />
                                <ListItemSecondaryAction>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    style={{marginRight: '0px'}}
                                    color="primary"
                                    onClick={() => {updateNoodleSpec(productItem, noodle.id, 'DEL')}}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                  <Box
                                    textAlign="center"
                                    style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                    fontWeight={300}
                                    className={classes.listItemStyle}
                                  >
                                    <span className={classes.qtyBoxTxtStyle}>{noodle.qty}</span>
                                  </Box>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="add"
                                    color="primary"
                                    onClick={() => {updateNoodleSpec(productItem, noodle.id, 'ADD')}}
                                  >
                                    <AddCircle />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );  
                          }
                        )];
                      } else if (productItem.productType === 'soupRices') {
                        return [(
                          <ListItem key={`prodListKey${productId}`}>
                            <ListItemAvatar>
                              <Avatar className={classes.binAvatar} onClick={() => {updateProductQtyToZero(productId, 'NOODLES');}}>
                                <DeleteIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={productName}
                              secondary={`$HKD ${productItem.totalPrice}`}
                              className={classes.listItemStyle}
                            />
                            <ListItemSecondaryAction>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{marginRight: '0px', color: 'white'}}
                                color="primary"
                              >
                                <RemoveCircle />
                              </IconButton>
                              <Box
                                textAlign="center"
                                style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                fontWeight={300}
                                className={classes.listItemStyle}
                              >
                                <span className={classes.qtyBoxTxtStyle}>{getNoodlesQty(productItem)}</span>
                              </Box>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="add"
                                color="primary"
                                style={{color: 'white'}}
                              >
                                <AddCircle />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ), productItem.productSpec.map(
                          (noodle, idx) => {
                            return (
                              <ListItem key={`prodListKey${productId}${idx}`}>
                                {/* <ListItemAvatar>
                                  <Avatar className={classes.binAvatar} onClick={removeProductFromOrderList.bind(null, productId)}>
                                    <DeleteIcon />
                                  </Avatar>
                                </ListItemAvatar> */}
                                <ListItemText
                                  primary={
                                    `${getSoupRiceSubTitle(noodle, productItem.unitPrice)}`
                                  }
                                  // secondary={${getNoodleSubTitle(noodle)}}
                                  className={classes.listItemStyle}
                                  classes={{root: classes.noodleListItemStyle, primary: classes.noodleListItemTypoStyle}}
                                />
                                <ListItemSecondaryAction>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    style={{marginRight: '0px'}}
                                    color="primary"
                                    onClick={() => {updateSoupRiceSpec(productItem, noodle.id, 'DEL')}}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                  <Box
                                    textAlign="center"
                                    style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                    fontWeight={300}
                                    className={classes.listItemStyle}
                                  >
                                    <span className={classes.qtyBoxTxtStyle}>{noodle.qty}</span>
                                  </Box>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="add"
                                    color="primary"
                                    onClick={() => {updateSoupRiceSpec(productItem, noodle.id, 'ADD')}}
                                  >
                                    <AddCircle />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );  
                          }
                        )];
                      } else if (productItem.productType === 'teaTime') {
                        return [(
                          <ListItem key={`prodListKey${productId}`}>
                            <ListItemAvatar>
                              <Avatar className={classes.binAvatar} onClick={() => {updateProductQtyToZero(productId, 'NOODLES');}}>
                                <DeleteIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={productName}
                              secondary={`$HKD ${productItem.totalPrice}`}
                              className={classes.listItemStyle}
                            />
                            <ListItemSecondaryAction>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{marginRight: '0px', color: 'white'}}
                                color="primary"
                              >
                                <RemoveCircle />
                              </IconButton>
                              <Box
                                textAlign="center"
                                style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                fontWeight={300}
                                className={classes.listItemStyle}
                              >
                                <span className={classes.qtyBoxTxtStyle}>{getNoodlesQty(productItem)}</span>
                              </Box>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="add"
                                color="primary"
                                style={{color: 'white'}}
                              >
                                <AddCircle />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ), productItem.productSpec.map(
                          (noodle, idx) => {
                            return (
                              <ListItem key={`prodListKey${productId}${idx}`}>
                                {/* <ListItemAvatar>
                                  <Avatar className={classes.binAvatar} onClick={removeProductFromOrderList.bind(null, productId)}>
                                    <DeleteIcon />
                                  </Avatar>
                                </ListItemAvatar> */}
                                <ListItemText
                                  primary={
                                    `${getTeaTimeSubTitle(noodle, productItem.unitPrice)}`
                                  }
                                  // secondary={${getNoodleSubTitle(noodle)}}
                                  className={classes.listItemStyle}
                                  classes={{root: classes.noodleListItemStyle, primary: classes.noodleListItemTypoStyle}}
                                />
                                <ListItemSecondaryAction>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    style={{marginRight: '0px'}}
                                    color="primary"
                                    onClick={() => {updateNoodleSpec(productItem, noodle.id, 'DEL')}}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                  <Box
                                    textAlign="center"
                                    style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                    fontWeight={300}
                                    className={classes.listItemStyle}
                                  >
                                    <span className={classes.qtyBoxTxtStyle}>{noodle.qty}</span>
                                  </Box>
                                  {/* Bind for passing parameters */}
                                  <IconButton
                                    edge="end"
                                    aria-label="add"
                                    color="primary"
                                    onClick={() => {updateNoodleSpec(productItem, noodle.id, 'ADD')}}
                                  >
                                    <AddCircle />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );  
                          }
                        )];
                      } 
                      return (
                          <ListItem key={`prodListKey${productId}`}>
                            <ListItemAvatar>
                              <Avatar className={classes.binAvatar} onClick={removeProductFromOrderList.bind(null, productId)}>
                                <DeleteIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={productName}
                              secondary={`$HKD ${unitPrice * unitQty}`}
                              className={classes.listItemStyle}
                            />
                            <ListItemSecondaryAction>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{marginRight: '0px'}}
                                color="primary"
                                onClick={updateProductShopListDel.bind(null, productId)}
                              >
                                <RemoveCircle />
                              </IconButton>
                              <Box
                                textAlign="center"
                                style={{display:'inline-block', fontSize: '1.5rem', verticalAlign: 'middle', minWidth: 30}}
                                fontWeight={300}
                                className={classes.listItemStyle}
                              >
                                <span className={classes.qtyBoxTxtStyle}>{unitQty}</span>
                              </Box>
                              {/* Bind for passing parameters */}
                              <IconButton
                                edge="end"
                                aria-label="add"
                                color="primary"
                                onClick={updateProductShopListAdd.bind(null, productId)}
                              >
                                <AddCircle />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                      )
                  }).flat()
              }
              <ListItem 
                  className={isSomethingAddedToCart ? classes.orderConfirmContainer : classes.orderConfirmContainerDisabled}
                  onClick={confirmOrder}
              >
                  <ListItemText className={classes.orderConfirm}>
                      <Typography variant='h4'>
                        {prodConfirmOrderBtnLbl}
                      </Typography>
                  </ListItemText>
              </ListItem>
            </List>
        </Box>
    );
};

MealOrderList.propTypes = {
  productListArr: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateProductShopList: PropTypes.func.isRequired,
  updateProductQtyToZero: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
};

export default MealOrderList;
