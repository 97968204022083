import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider }  from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Fade } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import MealCard from '../components/MealCard';
import NoodlesMealCard from '../components/NoodlesMealCard';
import SoupRicesMealCard from '../components/SoupRicesMealCard';
import MealOrderList from '../components/MealOrderList';
import SuccessOrderCard from '../components/SuccessOrderCard';
import ConfirmOrderCard from '../components/ConfirmOrderCard';
import OrderSummaryCard from '../components/OrderSummaryCard';
import withServerRequest from '../components/withServerRequest';
import ClientOrderPage from '../components/ClientOrderPage';
import ClosePage from '../components/ClosePage';
import PromoNotificationBanner from '../components/PromoNotificationBanner';
import SimpleMessageDialog from '../components/SimpleMessageDialog';
import BeverageDialog from '../components/BeverageDialog';
import AddSoupRiceDialog from '../components/AddSoupRiceDialog';
import AddNoodlesDialog from '../components/AddNoodlesDialog';
import AddTeaTimeDialog from '../components/AddTeaTimeDialog';
import ShopListPage from '../components/ShopListPage';
import Typography from '@material-ui/core/Typography';
import { CartArrowRight } from 'mdi-material-ui';
import commonFn from '../misc/common.js';
import GoodManLogo from '../static/good-man-logo.jpeg';
import lng, { envConst } from '../misc/lng';

// import PropTypes from 'prop-types'
import {
    getProductList,
    updateProductShopList,
    updateProductQtyToZero,
    confirmOrderStatus,
    changeView,
    updateAppbarTitle,
    addBeverage,
    saveWsObj,
    removeBeverage,
} from '../actions';

const styles = () => {
    return {
        root: {
            flexGrow: 1,
        },
        contentRoot: {
            position: 'relative',
        },
        contentRow: {
            marginBottom: '1rem',
            alignItems: 'flex-start',
            marginTop: '1rem',
        },
        titleClass: {
            flexGrow: 1,
            textAlign: 'left',
            paddingLeft: '0.6em',
            cursor: 'pointer',
        },
        totalPayClass: {
            cursor: 'pointer',
        },
        fab: {
            cursor: 'pointer',
            position: 'fixed',
            bottom: '1.5rem',
            left: '1.5rem',
            zIndex: 100
        },
        goodManLogoStyle: {
            maxHeight: 'inherit',
            height: '85%'
        },
        toolBarCss: {
            maxHeight: '70px',
        }
    }
}
// languages...
const {chiLng} = lng;

class ParentOrder extends React.Component {
    constructor(props) {
        super(props);
        this.clickMainTitle = this.clickMainTitle.bind(this);
        this.showMealOrderList = this.showMealOrderList.bind(this);
        this.onClickConfirm = this.onClickConfirm.bind(this);
        this.setClientList = this.setClientList.bind(this);
        this.isSomethingAddedToCart = this.isSomethingAddedToCart.bind(this);
        this.getView = this.getView.bind(this);
        this.calculateTotalPay = this.calculateTotalPay.bind(this);
        this.handleDocumentScroll = this.handleDocumentScroll.bind(this);
        this.updateDiscountFormFactortoParent = this.updateDiscountFormFactortoParent.bind(this);
        this.handleBeverageDialogClose = this.handleBeverageDialogClose.bind(this);
        this.handleBeverageDialogOpen = this.handleBeverageDialogOpen.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.getTotalMeal = this.getTotalMeal.bind(this);
        this.setTotalPay = this.setTotalPay.bind(this);
        this.setCloseStatus = this.setCloseStatus.bind(this);
        this.handleOpenAddNoodlesDialog = this.handleOpenAddNoodlesDialog.bind(this);
        this.handleOpenAddSoupRiceDialog = this.handleOpenAddSoupRiceDialog.bind(this);
        this.handleCloseAddNoodlesDialog = this.handleCloseAddNoodlesDialog.bind(this);
        this.handleOpenAddTeaTimeDialog = this.handleOpenAddTeaTimeDialog.bind(this);
        this.state = {
            shouldShow: false, // control whether or not the order meal list should show
            clientOrderList: [],
            clientTel: '',
            shouldUpdatePrice: false,
            revisedTotalPay: null,
            payAmount: this.calculateTotalPay(), //Specify total pay amount for later processing
            beverageDialogOpen: false,
            isClosed: false, // control whether to open or close the site (should be dn ctrl later)
            addNoodlesDialogOpen: true,
            addSoupRiceDialogOpen: true,
            selectedNoodleData: '',
            wsShouldModalOpen: false, // control modal open from web socket message
            wsShouldOrderOverride: false, // control whether order confirm allow override of ordering (time sel)
            wsShouldAutoCloseSystem: false, // control whether auto holiday close function is active
            shouldDisplayTopBanner: true, // control whether top promotional banner should be shown
            closeBanner: chiLng.siteClosed2, // control  the close banner. Use default banner if empty
            banner: '',
            isHoliday: false
        }
    }

    componentDidMount() {
        const {getProductList, changeView, ws} = this.props;
        if (this.state.isClosed) {
            changeView('close');
        }
        // retrieve system settings para (RESTful)
        const targetUrl = envConst.API_GET_SYS_PARA;
        axios.get(targetUrl).then((sysSettingsArr) => {
            console.log(sysSettingsArr);
            this.setState({
                wsShouldModalOpen: sysSettingsArr.data[0].systemOptionVal,
                wsShouldOrderOverride: sysSettingsArr.data[1].systemOptionVal,
                closeBanner: sysSettingsArr.data[3].systemOptionVal,
                wsShouldAutoCloseSystem: sysSettingsArr.data[2].systemOptionVal,
                isHoliday: sysSettingsArr.data[4].systemOptionVal,
                banner: sysSettingsArr.data[5].systemOptionVal
            })
        });
        getProductList();
        // Add scroll event to whole app, when scroll, hide the order meal list
        document.addEventListener('scroll', this.handleDocumentScroll, false);
        // Put bust cache logic here, prevent browser caching meal photos
        // DO NOT put this in render function as scroll event will update the state
        // which in turn triggers re-render of images every time when scrolling
        this.bustCache = Date.now();
        // ws is retrieved from redux store
        ws.on('sysPara', (wsm) => {
            console.log('wsm');
            console.log(wsm);
            // update state according to ws msg
            const systemOnOffOpt = wsm.msg.find((para) => {
                if (para.systemOptionName === 'shouldCloseResturants') {
                    return true; // find the target option value
                }
            });
            const orderOverrideOpt = wsm.msg.find((para) => {
                if (para.systemOptionName === 'shouldOverrideOrderPlacement') {
                    return true; // find the target option value
                }
            });
            const closeBannerOpt = wsm.msg.find((para) => {
                if (para.systemOptionName === 'autoCloseSysytemText') {
                    return true; // find the target option value
                }
            });
            const systemAutoCloseOpt = wsm.msg.find((para) => {
                if (para.systemOptionName === 'shouldAutoCloseSysytem') {
                    return true;
                }
            });
            const isHoliday = wsm?.isHoliday??false;
            console.log(isHoliday)
            const wsShouldOrderOverride = typeof orderOverrideOpt === 'undefined' ? false : orderOverrideOpt.systemOptionVal;
            const wsShouldModalOpen = typeof systemOnOffOpt === 'undefined' ? false : systemOnOffOpt.systemOptionVal;
            const closeBanner = closeBannerOpt.systemOptionVal === '' ? chiLng.siteClosed2 : closeBannerOpt.systemOptionVal;
            const wsShouldAutoCloseSystem = typeof systemAutoCloseOpt === 'undefined' ? false : systemAutoCloseOpt.systemOptionVal;

            this.setState({wsShouldModalOpen, wsShouldOrderOverride, closeBanner, wsShouldAutoCloseSystem, isHoliday});
        });
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleDocumentScroll, false);
    }

    setClientList(list, tel) {
        this.setState({
            clientOrderList: list,
            clientTel: tel,
        });
    }

    // for future use (close the site, read from DB (TBD))
    setCloseStatus(shouldClose) {
        console.log('in set close status')
        this.setState({isClosed: shouldClose});
    }

    // Detect scroll for whole app, hide the meal order list when scroll
    handleDocumentScroll(e) {
        if (this.payboxDiv.contains(e.target) || this.mealOrderListDiv.contains(e.target)) {
            this.setState({shouldShow: true});
        } else {
            this.setState({shouldShow: false});
        }
    }

    handleOpenAddNoodlesDialog(data) {
        this.setState(
            {
                selectedNoodleData: data, 
            },
            () => {
                this.props.changeView('addNoodles');
            }
        );
    }

    handleOpenAddSoupRiceDialog(data) {
        this.setState(
            {
                selectedSoupRice: data, 
            },
            () => {
                this.props.changeView('addSoupRice');
            }
        );
    }

    handleOpenAddTeaTimeDialog(data) {
        this.setState(
            {
                selectedTeaTime: data, 
            },
            () => {
                this.props.changeView('addTeaTime');
            }
        );
    }

    handleCloseAddNoodlesDialog() {
        this.props.changeView('productList');
    }

    clickMainTitle(evt) {
        evt.preventDefault();
        document.location.href="/";
    }
    
    // Show hide meal order list
    showMealOrderList() {
        if (this.props.view === 'successOrder' || this.props.view === 'clientOrder' || this.props.view === 'confirmOrder') {
            return;
        }
        this.setState({...this.state, shouldShow: !this.state.shouldShow});
    }

    onClickConfirm() {
        // No need to update store here, wait for confirmOrder to complete the task
        if (this.props.view === 'productList') {
            // change page view
            // this.props.changeView('confirmOrder', null);
            this.setState(
                {
                    beverageDialogOpen: true, 
                } 
            );
            // Hide the paybox content anyway
            this.setState({shouldShow: false});
            return;
        }
        this.showMealOrderList();
    }

    handleConfirm(list) {
        if (this.props.view === 'productList') {
            // change page view
            this.handleBeverageDialogClose();
            this.props.changeView('confirmOrder', null);
            this.props.addBeverage(list);
            // Hide the paybox content anyway
            this.setState({shouldShow: false});
            return;
        }
        this.showMealOrderList();
    }

    handleBeverageDialogClose() {
        this.setState(
            {
                beverageDialogOpen: false,
            }
        );
    }
    
    handleBeverageDialogOpen() {
        this.setState(
            {
                beverageDialogOpen: true,
            }
        );
    }

    // Calculate total amount to pay and show in pay menu
    calculateTotalPay() {
        const totalPay = this.props.productData.reduce((tmpCalculateSum, productDatum) => {
            let currentProductEarned = productDatum.unitPrice * productDatum.unitQty;
            if (productDatum.productType === 'noodles' || productDatum.productType === 'soupRices' || productDatum.productType === 'teaTime') {
                currentProductEarned = productDatum.totalPrice * productDatum.unitQty;
            }
            return tmpCalculateSum + currentProductEarned;
        }, 0);
        console.log(totalPay)
        return totalPay;
    }

    // update state of totalPay value
    setTotalPay() {
        this.setState({
            payAmount: this.calculateTotalPay()
        })
    }

    getTotalMeal() {
        const total = this.props.productData.reduce((tmpCalculateSum, productDatum) => {
            if (productDatum.productType === 'noodles' || productDatum.productType === 'soupRices' || productDatum.productType === 'teaTime') {
                return tmpCalculateSum + productDatum.productSpec.reduce(
                    (total, noodle) => {
                        return total + noodle.qty;
                    }, 0
                );
            }
            return tmpCalculateSum + productDatum.unitQty;
        }, 0);
        return total;
    }

    // Update total amount status after discount
    updateDiscountFormFactortoParent(discountFormFactor) {
        const revisedTotalPay = commonFn.payAmountCalulator(this.calculateTotalPay(), discountFormFactor);
        this.setState({
            shouldUpdatePrice: true,
            revisedTotalPay,
            payAmount: revisedTotalPay,
        })
    }

    // Determine which view to show corresponding to different actions.
    getView(createMealMenu) {
        switch(this.props.view) {
            case 'addNoodles':
                return (
                    <AddNoodlesDialog
                        updateProductShopList={this.props.updateProductShopList}
                        initProductData={this.state.selectedNoodleData}
                        cancel={this.handleCloseAddNoodlesDialog}
                        confirm={this.handleCloseAddNoodlesDialog}
                    />
                );
            case 'addSoupRice':
                return (
                    <AddSoupRiceDialog
                        updateProductShopList={this.props.updateProductShopList}
                        initProductData={this.state.selectedSoupRice}
                        cancel={this.handleCloseAddNoodlesDialog}
                        confirm={this.handleCloseAddNoodlesDialog}
                    />
                );
            case 'addTeaTime':
                return (
                    <AddTeaTimeDialog
                        updateProductShopList={this.props.updateProductShopList}
                        initProductData={this.state.selectedTeaTime}
                        cancel={this.handleCloseAddNoodlesDialog}
                        confirm={this.handleCloseAddNoodlesDialog}
                    />
                );
            case 'close': 
                return (
                    <ClosePage shouldCloseDialogOpen={true} />
                );
            case 'clientOrder':
                return (
                    <ClientOrderPage clientTel={this.state.clientTel} list={this.state.clientOrderList} />
                );
            case 'confirmOrder':
                // Pass user's order data to the confirm order card for further processing
                // As <ConfirmOrderCard /> is rendered while <ParentOrder /> is rendered, all state data like payAmount REMAINS at the time when 
                // <ParentOrder /> is rendered
                return (
                    <ConfirmOrderCard
                        changeView={this.props.changeView}
                        orderData={this.props.orderData}
                        payAmount={this.state.payAmount}
                        confirmOrderStatus={this.props.confirmOrderStatus}
                        updateDiscountFormFactortoParent={this.updateDiscountFormFactortoParent}
                        setTotalPay={this.setTotalPay}
                        wsShouldOrderOverride={this.state.wsShouldOrderOverride}
                        shop={this.props.shop}
                        totalPrice={this.calculateTotalPay()}
                        removeBeverage={this.props.removeBeverage}
                        render={(stateObj) => {
                            // DO NOT put setstate here, will cause render loop!!
                            // here wrapper (ConfirmOrderCard) pass its state object to wrapped object in 
                            // render function for plug and play rendering
                            return (
                                // make use of render props for convenient reuse of component
                                <PromoNotificationBanner stateObj={stateObj} />
                            );
                        }}
                    />
                );
            case 'successOrder':
                // Pass order id for order review and timestamp for view
                return (
                    <SuccessOrderCard
                        generatedOrderId={this.props.generatedOrderId}
                        orderTimeStamp={this.props.orderTimeStamp}
                    />
                );
            case 'orderSummary':
                return (
                    <OrderSummaryCard />
                );
            case 'shopList':
                return (
                    <ShopListPage 
                        changeView={this.props.changeView}
                        updateAppbarTitle={this.props.updateAppbarTitle}
                        setClientList={this.setClientList}
                    />
                );
            default:
                return (
                    <div>
                        { /* Show final call wordings */}
                        {
                            (() => {
                                console.log('this.props.shop');
                                console.log(this.props.shop);
                                if (this.props.shop === 'sh01') {
                                    return (
                                        <Typography variant="h5" style={{ marginTop: '1rem' }}>
                                            {chiLng.finalCallBanner}
                                        </Typography>
                                    );
                                }
                                return (
                                    <Typography variant="h5" style={{ marginTop: '1rem' }}>
                                        {chiLng.teaTimeFinalCallBanner}
                                    </Typography>
                                );
                            })()
                        }
                        {this.props.productData && this.props.productData.length > 0 && createMealMenu()}
                    </div>               
                );
        }
    }

    isSomethingAddedToCart() {
        const productListArr = this.props.productData;
        return productListArr.some(productList => productList.unitQty > 0);
    }

    render() {
        const {root, titleClass, totalPayClass, contentRoot, contentRow, fab, goodManLogoStyle, toolBarCss} = this.props.classes;
        const {appBarTitle, productData, updateProductShopList, updateProductQtyToZero} = this.props;
        // Determine desktop width type
        const smMatch = isWidthDown('sm', this.props.width);
        const mdMatch = isWidthUp('sm', this.props.width) && isWidthDown('md', this.props.width);
        const lgMatch = isWidthUp('lg', this.props.width);
        const xlMatch = isWidthUp('xl', this.props.width);
        let itemsPerRow = 3;
        // Control number of items to show per row according to width
        if (smMatch) itemsPerRow = 1;
        if (mdMatch) itemsPerRow = 2;
        if (lgMatch) itemsPerRow = 3;
        if (xlMatch) itemsPerRow = 4;
        // Generate fab button for quick checkout
        const CheckoutFab = ({handleFabOnclick}) => {
            return (
                <Fab color="secondary" aria-label="check-out" className={fab} onClick={handleFabOnclick}>
                    <CartArrowRight />
                </Fab>
            );
        }
        
        // Pay box show at top right corner of the appBar
        const PayBox = ({totalPay, revisedTotalPay, shouldUpdatePrice}) => {
            const generatePriceComponent = (revisedTotalPay, shouldUpdatePrice) => {
                if (shouldUpdatePrice) {
                    // price updated because of promotion discount
                    return (
                        <span>
                            {lng.chiLng.payTotalTxt}: 
                            <b style={{ textDecoration: 'line-through'}}>${totalPay}</b><b>${revisedTotalPay}</b>
                        </span>
                    );
                }
                // nothing promoted, display as usual
                return (
                    <span>
                        {lng.chiLng.payTotalTxt}: 
                        <b>${totalPay}</b>
                    </span>
                );
            };
            return (
                <Box
                    borderRadius={6}
                    bgcolor="background.paper"
                    color="primary.main"
                    pt={0.5}
                    pb={0.5}
                    pl={1.5}
                    pr={1.5}
                    width='auto'
                    boxShadow={2}
                    className={this.props.classes.totalPayClass}
                    onClick={this.showMealOrderList}
                >
                    <Typography variant="body1">
                        { generatePriceComponent(revisedTotalPay, shouldUpdatePrice) }
                    </Typography>
                </Box>
            );
        };

        // 2d map, making grid container wrapping each row (row by row) for easier styling
        const imgUrlPrefix = envConst.GET_MEAL_IMG_URL;
        const createMealMenu = () => {
            const MealCardGridContainer = ({productRowData, idxNumSum}) => {
                const TmpGridContent = productRowData.map((productRowDatum, idx) => {
                    return (
                        <Grid item xs={12 / itemsPerRow} key={`mealGrid${idx}`}>
                            {
                                (() => {
                                    switch(productRowDatum.productType) {
                                        case 'noodles':
                                            return (
                                                <NoodlesMealCard
                                                    shopID='sh01'
                                                    productID={productRowDatum.productId}
                                                    productName={productRowDatum.productName}
                                                    productImg={`${imgUrlPrefix}/${productRowDatum.productImg}?bust=${this.bustCache}` /* bust url suffix for cache prevent */}
                                                    unitPrice={productRowDatum.unitPrice}
                                                    unitQty={productRowDatum.unitQty}
                                                    key={`meal${idxNumSum}${idx}`}
                                                    handleOpenAddNoodlesDialog={this.handleOpenAddNoodlesDialog}
                                                    productData={productRowDatum}
                                                />
                                            );
                                        case 'soupRices':
                                            return (
                                                <SoupRicesMealCard
                                                    shopID='sh01'
                                                    productID={productRowDatum.productId}
                                                    productName={productRowDatum.productName}
                                                    productImg={`${imgUrlPrefix}/${productRowDatum.productImg}?bust=${this.bustCache}` /* bust url suffix for cache prevent */}
                                                    unitPrice={productRowDatum.unitPrice}
                                                    unitQty={productRowDatum.unitQty}
                                                    key={`meal${idxNumSum}${idx}`}
                                                    handleOpenAddNoodlesDialog={this.handleOpenAddSoupRiceDialog}
                                                    productData={productRowDatum}
                                                />
                                            )
                                        case 'teaTime':
                                            return (
                                                <SoupRicesMealCard
                                                    shopID='sh02'
                                                    productID={productRowDatum.productId}
                                                    productName={productRowDatum.productName}
                                                    productImg={`${imgUrlPrefix}/${productRowDatum.productImg}?bust=${this.bustCache}` /* bust url suffix for cache prevent */}
                                                    unitPrice={productRowDatum.unitPrice}
                                                    unitQty={productRowDatum.unitQty}
                                                    key={`meal${idxNumSum}${idx}`}
                                                    handleOpenAddNoodlesDialog={this.handleOpenAddTeaTimeDialog}
                                                    productData={productRowDatum}
                                                />
                                            )
                                        default:
                                            return (
                                                <MealCard
                                                    shopID='sh01'
                                                    productID={productRowDatum.productId}
                                                    productName={productRowDatum.productName}
                                                    productImg={`${imgUrlPrefix}/${productRowDatum.productImg}?bust=${this.bustCache}` /* bust url suffix for cache prevent */}
                                                    unitPrice={productRowDatum.unitPrice}
                                                    unitQty={productRowDatum.unitQty}
                                                    key={`meal${idxNumSum}${idx}`}
                                                    updateProductShopList={updateProductShopList}
                                                />
                                            )
                                    }
                                })()
                                    
                            }
                            {
                                /* 
                                    Pass redux action updateProductShopList down to child component <MealCard /> 
                                    <MealCard /> no need to knowledge redux action for centralize action handling
                                    (by Jacky Lam 23/8/2019)
                                */
                            }
                        </Grid>
                    )
                });
                return (
                    <Grid container className={contentRow} key={`mealRow_${idxNumSum}`}>
                        {TmpGridContent}
                    </Grid>
                )
            };
            
            const productRowData = [];
            // loop full list of product for row display calculation
            const ret = productData.map((productDatum, idx) => {
                productRowData.push(productDatum);
                // Determine whether the data are in the same row
                if((idx + 1) % itemsPerRow === 0 || idx === productData.length - 1) {
                    // tmp storage for row data, re-use in next row
                    const copyOfProductRowData = [...productRowData];
                    const idxKey = `rowNum${idx}`;
                    // reset the menu item array for next row
                    productRowData.length = 0;
                    // 1 row stored, make container, prepare for next row
                    return (
                        <MealCardGridContainer
                            style={{marginTop: '2rem'}}
                            productRowData={copyOfProductRowData}
                            key={idxKey}
                        />
                    )
                }
                return null;
            }).filter((el) => el !== null);
            // Remove unused null in element
            return ret;
        }
        // const createEmptyMealMenu = () => {
        //     // to be implemented, show default text when no product data loaded
        // };
        const shouldCloseRestaurantObj = this.props.serverPara.length > 0 ? (this.props.serverPara.find((para)=>{
            if (para.systemOptionName === 'shouldCloseResturants') {
                return true; // find the target option value
            }
        })) : false;
        // only open the maintenance dialog when we receive system settings from server
        const shouldModalOpen = this.props.serverPara.length > 0 && 
            (shouldCloseRestaurantObj.systemOptionVal || (this.state.wsShouldAutoCloseSystem && this.state.isHoliday));
        console.log(this.state.isHoliday)
        return (
            <div className={root} ref={ rootDiv => this.rootDiv = rootDiv }>
                <SimpleMessageDialog 
                    lng={this.state.closeBanner}
                    modalOpen={shouldModalOpen || this.state.wsShouldModalOpen}
                    shouldLoadingIndicatorShow={false}
                />
                { /* promotion banner */}
                <ThemeProvider theme={this.props.theme}>
                    <div
                        style={{
                            backgroundColor: this.props.theme.palette.primary.custlight,
                            color: this.props.theme.palette.secondary.main,
                            textAlign: 'center',
                            fontSize: '18px',
                            height: 'auto',
                            //height: '25px',
                            overflow: 'hidden',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            fontWeight: 'bold',
                            display: !this.state.shouldDisplayTopBanner ? 'none' : 'block'
                        }}
                    >
                        {this.state.banner}
                    </div>
                </ThemeProvider>
                { /* Floating button for checkout comopnent here... */ }
                <Fade in={this.props.view === 'productList' && this.isSomethingAddedToCart()}>
                    <div>
                        <CheckoutFab handleFabOnclick={this.onClickConfirm} />
                    </div>
                </Fade>
                <BeverageDialog
                    open={this.state.beverageDialogOpen}
                    handleClose={this.handleBeverageDialogClose}
                    total={this.getTotalMeal()}
                    totalPrice={this.calculateTotalPay()}
                    handleConfirm={this.handleConfirm}
                    shop={this.props.shop}
                />
                <AppBar position="sticky">
                    <Toolbar className={toolBarCss}>
                        <img src={GoodManLogo} className={goodManLogoStyle} />
                        <Typography
                            variant="h6"
                            className={titleClass}
                            onClick={this.clickMainTitle}
                        >
                            {appBarTitle /* Show different namings in different pages.. */}
                        </Typography>
                        {
                            this.props.view !== 'orderSummary' ?
                            (
                                <div ref={ payboxDiv => this.payboxDiv = payboxDiv}>
                                    <PayBox
                                        className={totalPayClass}
                                        totalPay={this.calculateTotalPay()}
                                        shouldUpdatePrice={this.state.shouldUpdatePrice}
                                        revisedTotalPay={this.state.revisedTotalPay}
                                        onClick={this.showMealOrderList}
                                    />
                                </div>
                            ) : <span />
                        }
                    </Toolbar>
                    {/* Div for attaching order list absolutely */}
                    <div className={contentRoot}>
                        { /* Order list comopnent here... */ }
                        <Fade in={this.state.shouldShow}>
                            {/* Need native div for fade to work properly, fade is not working on custom element */}
                            <div ref={ mealOrderListDiv => this.mealOrderListDiv = mealOrderListDiv }>
                                <MealOrderList
                                    productListArr={productData}
                                    updateProductShopList={updateProductShopList}
                                    updateProductQtyToZero={updateProductQtyToZero}
                                    onClickConfirm={this.onClickConfirm}
                                />
                            </div>
                        </Fade>
                    </div>
                </AppBar>
                {
                    
                    /* 
                        Listing meal cards...
                        Determine whether or not to show the meal list (determined by internal state ONLY
                            
                        Same as <MealCard /> as same action is offered, pass redux action
                        to child <MealOrderList /> while <MealOrderList /> has no knowledge
                        on redux action
                    */
                    this.state.isClosed ? this.getView('close') : this.getView(createMealMenu)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        productData: state.productsReducer.productData,
        orderData: state.ordersReducer,
        view: state.commonReducer.view,
        generatedOrderId: state.commonReducer.generatedOrderId,
        orderTimeStamp: state.commonReducer.orderTimeStamp,
        appBarTitle: state.commonReducer.appBarTitle,
        ws: state.commonReducer.ws,
        shop: state.commonReducer.shop,
    }
}

export default compose(
    connect(mapStateToProps,
        {
            getProductList,
            updateProductShopList,
            updateProductQtyToZero,
            confirmOrderStatus,
            changeView,
            updateAppbarTitle,
            addBeverage,
            saveWsObj,
            removeBeverage,
        }
    ),
    withStyles(styles, {withTheme: true}),
    withWidth(),
    withServerRequest
)(ParentOrder);
