import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import lng, { envConst } from '../misc/lng';

const useStyles = makeStyles(theme => ({
    textField: {
        width: 'auto',
        paddingLeft: '2rem',
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.light,
    },
    iconButton: {
        color: theme.palette.primary.contrastText,
        paddingRight: '2rem',
    }
}));

const OrderSearcher = ({goSearchByMobile}) => {
    const classes = useStyles();
    const {chiLng} = lng;
    const [ mobileNo, setMobileNo ] = useState('');
    const handleClick = () => {
        goSearchByMobile(mobileNo);
    }
    const handleOnChange = (evt) => {
        const inputMobileNo = evt.target.value;
        setMobileNo(inputMobileNo);
    }
    return (
        <div className={classes.wrapper}>
            <InputBase
                id="find-order-by-mobile"
                className={classes.textField}
                placeholder={chiLng.findByNoPlaceholder}
                inputProps={
                    {
                        onChange: handleOnChange,
                    }
                }
            />
            <IconButton onClick={() => {goSearchByMobile(mobileNo)}} className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
        </div>
    );
}

OrderSearcher.propTypes = {
    goSearchByMobile: PropTypes.func.isRequired,
}

export default OrderSearcher;