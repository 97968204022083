import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {connect} from 'react-redux';
import {compose} from 'redux';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import OrderSearcher from '../components/OrderSearcher';
import shopList from '../misc/simulate/shop';
import WhatsappLogo from '../static/whatsapp-logo.png';
import lng, { envConst } from '../misc/lng';
import {
    changeShop,
    getProductList,
} from '../actions';

const imgRequireFn = require.context(
    '../static/shop/img', //variable substitution is not allowed here!
    false,
    /(jpg|png)$/
);
const styles = () => {
    return {
        root: {
            padding: '20px 35px',
            flexGrow: 1,
        },
        paper: {
            textAlign: 'center',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            justifyContent: 'space-between',
        },
        selectedPaper: {
            textAlign: 'center',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            justifyContent: 'space-between',
        },
        btnWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
        },
        title: {
            textAlign: 'left',
            marginBottom: '10px',
            color: '#333333',
            fontWeight: '300',
        },
        shopIcon: {
            fontSize: '125px',
            color: '#dadada',
        },
        orderSearcher: {
            marginBottom: '1rem',
            textAlign: 'left',
            width: '100%',
            margin: 0
        },
        WhatsappLogoBlk: {
            display: 'inline-flex',
            alignItems: 'center'
        },
        WhatsappLogoStyle: {
            marginRight: '0.2rem'
        }
    }
}
class ShopListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: 'A',
        };
        this.handleClick = this.handleClick.bind(this);
        this.getClassName = this.getClassName.bind(this);
        this.getMinCharge = this.getMinCharge.bind(this);
        this.getShippingFee = this.getShippingFee.bind(this);
        this.goSearchByMobile = this.goSearchByMobile.bind(this);
        this.openWhatsAppUrl = this.openWhatsAppUrl.bind(this);
    }

    componentDidMount() {}

    handleClick(shop, e) {
        console.log(e);
        // Prevent redirect when not opened
        if (shop.status !== 'open') {
            return;
        }
        this.setState({
            shop,
        });
        this.props.changeShop(shop.id);
        this.props.getProductList(shop.id);
        // Change title to shop name according to the chosen restaurant
        this.props.updateAppbarTitle(shop.name);
        // Go ahead for choosing meals
        this.props.changeView('productList');
    }
    getClassName(shop) {
        if (shop === this.state.shop) {
            return this.props.classes.selectedPaper;
        }
        return this.props.classes.paper;
    }
    getMinCharge(minCharge) {
        if (minCharge === -1) {
            return lng.chiLng.hyphen;
        }
        if (minCharge <= 0) {
            return lng.chiLng.noMinCharge;
        }
        return `${lng.chiLng.minCharge} ${minCharge}`;
    }
    getShippingFee(shippingFee) {
        if (shippingFee === -1) {
            return lng.chiLng.hyphen;
        }
        if (shippingFee <= 0) {
            return lng.chiLng.noShippingFee;
        }
        return `${lng.chiLng.shippingFee} ${shippingFee}`;
    }
    goSearchByMobile(mobileNo) {
        // retrieve number from orderSearcher
        const targetUrl = `${envConst.API_REST_URL}`;
        const pastDate = new Date();
        pastDate.setDate(pastDate.getDate() - 7);
        const filter = JSON.stringify(
            {
                where: {
                    orderClientContactNo: `852${mobileNo}`,
                    orderTimeStamp: {
                        gte: pastDate.toISOString(),
                    },
                },
            },
        )
        const body = {
            params: {
                filter,
            }
        };
        axios.get(targetUrl, body).then(
            (res) => {
                console.log(res);
                if (res.status === 200 && res.data) {
                    this.props.setClientList(res.data, mobileNo);
                    this.props.changeView('clientOrder');
                }
            },
            (err) => {
                console.log('err');
                console.log(err);
            }
        );
    }
    // event object at the back
    openWhatsAppUrl(mobile, e) {
        // prevent bubbling effect, which accidentally trigger click event to the outer
        // onClick listener (other parts of shop card)
        e.stopPropagation();
        // open new window and call whatsapp out
        window.open(
            `https://wa.me/852${mobile}`,
            '_blank'
        );
    }
    render() {
        const classes = this.props.classes;
        const {
            chiLng: {
                titleFirstLetter,
                titleRest,
                finalCallBannerShort,
                teaTimeFinalCallBannerShort,
                whatsappCallTxt,
                whatsappCallNo,
                freeDrinks
            }
        } = lng;
        return (
            <React.Fragment>
                <OrderSearcher
                    goSearchByMobile={this.goSearchByMobile}
                    className={classes.orderSearcher}
                />
                <div className={classes.root}>
                    <Typography
                        variant="h6"
                        classes={{root: classes.title}}>
                        <span style={{fontSize: '20px'}}>{titleFirstLetter}</span>
                        <span style={{fontSize: '15px'}}>{titleRest}</span>
                    </Typography>
                    
                    <Grid container spacing={3}>
                        {
                            shopList.shops.map(
                                shop => (
                                    <Grid key={shop.id} item xs={12}>
                                        <Card className={classes.card}>
                                            <CardActionArea onClick={this.handleClick.bind(this, shop)}>
                                                <CardMedia
                                                    component="img"
                                                    alt={shop.name}
                                                    height="140"
                                                    image={imgRequireFn(`./${shop.shopImg}`)}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" component="h2">
                                                        {shop.name}
                                                    </Typography>
                                                    { 
                                                        // only show mobile place order when shop has the no.
                                                        shop.hasMobile ? (<Typography onClick={this.openWhatsAppUrl.bind(this, whatsappCallNo)} gutterBottom variant="body1" className={ classes.WhatsappLogoBlk }>
                                                            <img src={WhatsappLogo} className={ classes.WhatsappLogoStyle } />
                                                            <span>{ whatsappCallTxt } <b> { whatsappCallNo } </b></span>
                                                        </Typography>) : null
                                                    }
                                                    {
                                                        // only show final order info for opened shops
                                                        shop.name !== '即將加盟' ? (<Typography variant="body2" color="textSecondary" component="p">
                                                                { `${this.getShippingFee(shop.shippingFee)} | ${freeDrinks} | ${shop.id === 'sh01' ? finalCallBannerShort : teaTimeFinalCallBannerShort}`}
                                                            </Typography>) : null
                                                    }
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

ShopListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    changeView: PropTypes.func.isRequired,
    setClientList: PropTypes.func.isRequired,
    changeShop: PropTypes.func.isRequired,
    getProductList: PropTypes.func.isRequired,
};

// export default withStyles(styles)(ShopListPage);
const mapStateToProps = (state) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps,
        {
            changeShop,
            getProductList,
        }
    ),withStyles(styles),
)(ShopListPage);