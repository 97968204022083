import { combineReducers } from 'redux';
import productsReducer, * as fromProduct from './product';
import ordersReducer, * as fromOrder from './order';
import commonReducer, * as fromCommon from './common';

// Combine all reducers as one and export
const rootReducer = combineReducers({
    productsReducer,
    ordersReducer,
    commonReducer,
});

export default rootReducer;