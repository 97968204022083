import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from 'axios';
import lng, {envConst} from '../misc/lng';

const { chiLng } = lng;
const styles = () => {
    return {
        root: {
            flexGrow: 1,
            paddingTop: '30px',
            // paddingLeft: '40px',
            // paddingRight: '40px',
        },
        listTitle: {
            fontSize: '12px',
        },
        titleWrapper: {
            display: 'flex',
        },
        titleItem: {
            paddingLeft: '5px',
        },
        icon: {
            padding: 0,
        },
        detailRow: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            marginBottom: '20px',
        },
        detailItem: {
        },
        detailPanel: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: '10px',
        },
        listItemTitle: {
            fontSize: '12px',
            color: '#828282',
        },
        panelRoot: {

        },
        completedStatus: {
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: '22px',
            backgroundColor: '#e65100',
            writingMode: 'vertical-rl',
            color: 'white',
            textAlign: 'center',
            marginLeft: '-3px',
            paddingRight: '3px',
        },
        comfirmedStatus: {
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: '22px',
            backgroundColor: '#e65100',
            writingMode: 'vertical-rl',
            color: 'white',
            textAlign: 'center',
            marginLeft: '-3px',
            paddingRight: '3px',
        },
        summary: {
            height: '80px',
            borderBottom: 'solid 0.6px #828282',
        },
        deleteIcon: {
            position: 'absolute',
            right: '0px',
            top: '85px',
            color: 'red;',
        },
        filterWrapper: {
            display: 'flex',
            flexDirection: 'column-reverse',
            paddingLeft: '100px',
        },
        filterOpt: {
            marginLeft: 'auto',
        },
        cvsButton: {
            position: 'fixed',
            bottom: '0',
            left: '0',
            backgroundColor: '#2F80ED',
        },
        logout: {
            position: 'absolute',
            top: '0px',
            right: '0px',
        },
        completeBtn: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            backgroundColor: '#2F80ED',
        },
    }
}

class ClientListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filter: false,
        }
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.getList = this.getList.bind(this);
        this.getPayment = this.getPayment.bind(this);
        this.getDetailList = this.getDetailList.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.delete = this.delete.bind(this);
        this.getList = this.getList.bind(this);
    }

    componentDidMount() {
        this.getDataList();
    }
    getDataList() {
        const targetUrl = `${envConst.API_REST_URL}`;
        axios.get(targetUrl).then(
            (res) => {
                if (res.status === 200) {
                    console.log(res);
                    this.setState(
                        {
                            list: res.data,
                        }
                    );
                }
            },
            (err) => {
                console.log('err');
                console.log(err);
            }
        );
    }
    getPayment(item) {
        const itemList = item.orderDetails.productData;
        let sum = 0;
        if (itemList) {
            itemList.forEach(
                (i) => {
                    if (i.unitQty > 0) {
                        sum += (i.unitPrice * i.unitQty);
                    }
                }
            );
        }
        if (item.orderPayAmount !== 0) {
            return (
                <span>
                    <span
                        style={{"textDecoration": "line-through"}}
                    >
                        ${sum}
                    </span>
                    ${item.orderPayAmount}
                </span>
            );
        }
        return (<span>${sum}</span>);
    }
    getDetailList(item) {
        console.log(item);
        const itemList = item.orderDetails.productData;
        const returnArr = itemList && itemList.filter(i => i.unitQty > 0).map(
            (i) => {
                if (i.productType === 'noodles') {
                    const noodles = i.productSpec.map(
                        (noodle) => {
                            let sideDashSpec = [];
                            let spicinessSpec = '';
                            let noodleType = '';
                            noodle.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '辣度') {
                                        spicinessSpec = spec.specOptions[0];
                                    } else if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    } else if (spec.specName === '米線系列') {
                                        noodleType = spec.specOptions[0].productName;
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${noodle.id}`}>
                                    {`${noodleType}、${spicinessSpec}、${sideDashSpec.join('、')} x ${noodle.qty}`}
                                </div>
                            )
                        }
                    );
                    console.log(noodles);
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        noodles
                    ];
                } else if (i.productType === 'soupRices') {
                    const soupRices = i.productSpec.map(
                        (soupRice) => {
                            let sideDashSpec = [];
                            soupRice.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${soupRice.id}`}>
                                    {`${sideDashSpec.join('、')} x ${soupRice.qty}`}
                                </div>
                            )
                        }
                    );
                    console.log(soupRices);
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        soupRices
                    ];
                } else if (i.productType === 'teaTime') {
                    const soupRices = i.productSpec.map(
                        (soupRice) => {
                            let sideDashSpec = [];
                            soupRice.spec.forEach(
                                (spec) => {
                                    if (spec.specName === '配菜') {
                                        spec.specOptions.forEach(
                                            (i) => {
                                              sideDashSpec.push(i);
                                            }
                                        );
                                    }
                                }
                            );
                            return (
                                <div key={`${i.productId}${soupRice.id}`}>
                                    {`${sideDashSpec.join('、')} x ${soupRice.qty}`}
                                </div>
                            )
                        }
                    );
                    console.log(soupRices);
                    return [
                        (
                            <div key={i.productId}>
                                {i.productName}
                            </div>
                        ),
                        soupRices
                    ];
                }
                return (
                    <div key={i.productId}>
                        {i.productName} x {i.unitQty}
                    </div>
                )
            }
        ).flat();

        return returnArr;
    }
    handleChangeFilter(event) {
        this.setState(
            {
                filter: event.target.checked,
            }
        );
    }
    getList() {
        if (this.state.filter) {
            return this.props.list.filter(i => i.orderStatus !== 'completed').sort((a, b) => new Date(a.orderTimeStamp) - new Date(b.orderTimeStamp));
        }
        return this.props.list.sort((a, b) => new Date(b.orderTimeStamp) - new Date(a.orderTimeStamp));
    }
    getStatus(status) {
        if (status === 'completed'){
            return '已完成';
        }
        return '準備中';
    }
    delete(item) {
        const id = item.generatedOrderId;
        const targetUrl = `${envConst.API_REST_URL}/${id}`;
        axios.delete(targetUrl)
          .then((response) => {
            console.log(response);
            this.getDataList();
          })
          .catch((error) => {
            console.log(error);
          });
    }
    changeStatus(item) {
        const id = item.generatedOrderId;
        const targetUrl = `${envConst.API_REST_URL}/${id}/replace`;
        axios.post(targetUrl,
            {
                ...item,
                orderStatus: item.orderStatus === 'confirmed' ? 'confirmed' : 'completed',
            }
        )
          .then((response) => {
            console.log(response);
            this.getDataList();
            
          })
          .catch((error) => {
            console.log(error);
          });
    }
    render () {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography
                    variant='subtitle2'
                    style={
                        {
                            color: '#e65100',
                            fontSize: '22px',
                        }
                    }
                >
                    { chiLng.clntTel }: {this.props.clientTel}
                </Typography>
                <div className={classes.filterWrapper}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.filter}
                            value="checked"
                            onChange={this.handleChangeFilter}
                            color="primary"
                        />
                        }
                        classes={
                            {
                                root: classes.filterOpt,
                            }
                        }
                        label={ chiLng.filteredCompletedOrder }
                    />
                </div>
                {
                    this.getList().map(
                        item => (
                            <ExpansionPanel key={item.generatedOrderId}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    classes={
                                        {
                                            expandIcon: classes.icon,
                                            root: classes.summary,
                                        }
                                    }
                                >
                                <div className={item.orderStatus === 'completed' ? classes.completedStatus : classes.comfirmedStatus}>
                                    {this.getStatus(item.orderStatus)}
                                </div>
                                <div className={classes.titleWrapper}>
                                    <div className="" >
                                        <Typography className={classes.listTitle}>
                                            { chiLng.clntOrderNo }
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.generatedOrderId}
                                        </Typography>
                                    </div>
                                    <div className={classes.titleItem}>
                                        <Typography className={classes.listTitle}>
                                            { chiLng.clntPerson }
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.orderClientContactName}
                                        </Typography>
                                    </div>
                                    <div className={classes.titleItem}>
                                        <Typography className={classes.listTitle}>
                                            { chiLng.clntTel }
                                        </Typography>
                                        <Typography className={classes.listItemTitle}>
                                            {item.orderClientContactNo}
                                        </Typography>
                                    </div>
                                </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className={classes.detailPanel}>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntStatus }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {this.getStatus(item.orderStatus)}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntDelivTime }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderDeliveryTime}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className="" >
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntOrderNo }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.generatedOrderId}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntPerson }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderClientContactName}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntTel }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderClientContactNo}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntAddr }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {item.orderAddress}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntPayment }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {this.getPayment(item)}
                                                </Typography>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntOrderTime }
                                                </Typography>
                                                <Typography className={classes.listItemTitle}>
                                                    {new Date(item.orderTimeStamp).toLocaleString('en-GB', { timeZone: 'Asia/Hong_Kong' })}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.detailRow}>
                                            <div className={classes.detailItem}>
                                                <Typography className={classes.listTitle}>
                                                    { chiLng.clntOrderDetails }
                                                </Typography>
                                                <div className={classes.listItemTitle}>
                                                    {/* {item.detail.map(
                                                        detailItem => (
                                                            <div key={detailItem.item}>
                                                                {detailItem.item} x {detailItem.number}
                                                            </div>
                                                        )
                                                    )} */}
                                                    {
                                                        this.getDetailList(item)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.commonReducer.view
    }
}

export default compose(
    connect(mapStateToProps,
        {}
    ),withStyles(styles), withWidth()
)(ClientListPage);
