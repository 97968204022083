import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import SettingsIcon from '@material-ui/icons/Settings';
import grey from '@material-ui/core/colors/grey';
import lng from '../misc/lng';
import withServerRequest from './withServerRequest';
import SimpleMessageDialog from './SimpleMessageDialog';
import envConst from '../misc/envConst';

const { API_UPDATE_SYS_PARA } = envConst;
const { chiLng } = lng;
const normalGreyColor = grey[900];
const lightGreyColor = grey[600];
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    commonFontSize: {
        fontSize: '3rem'
    },
    iconFontSize: {
        fontSize: '2rem'
    },
    systemTitle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        display: 'flex',
        flex: 1,
    },
    internalEl: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '85%',
        marginTop: '1.5rem',
        color: normalGreyColor,
        justifyContent: 'space-between'
    },
    subTitle: {
        paddingBottom: '0px',
        borderBottom: `2px solid ${normalGreyColor}`,
        fontWeight: 500
    },
    titleDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    formDiv: {
        marginLeft: '0rem'
    },
    formFontSize: {
        fontSize: '1.8rem'
    },
    formControlCssLabel: {
        fontSize: '1.5rem',
    },
    formControlCssLabelPlacementStart: {
        marginLeft: '0px',
        marginRight: '0px',
        alignItems: 'flex-start',
        justifyContent: 'flex-end', // align content along with main axis, flex end because of row direction reverse
    },
    formControlCssLabelSwitch: {
        marginLeft: 'auto', // by auto, we ask to fill up all the available left hand side spaces as much as possible so as to stay the switch always right
    },
    saveSysSettingsBtnRoot: {
        padding: "0px"
    },
    formControlSelect: {
        marginLeft: '0px',
        width: '100%'
    }
}));

/**
 * [{"system-id":"sys001","system-option-name":"shouldCloseResturants","system-option-val":true,"is-global-settings":true,"target-restaurant-id":null},{"system-id":"sys002","system-option-name":"shouldOverrideOrderPlacement","system-option-val":false,"is-global-settings":true,"target-restaurant-id":null}]
 */


const AdminSettingsPage = (props) => {
    const classes = useStyles();
    // initial state when server cannot load anything
    const initialSysSettings = {
        shouldCloseResturants: true,
        shouldOverrideOrderPlacement: false,
        shouldAutoCloseSysytem: false,
        autoCloseSysytemText: '',
    }
    // UI state data saved separately
    const [ sysSettingsObj, setSysSettingsObj ] = useState(initialSysSettings);
    // server data retrieved from HOC withServerRequest, note that at this moment, server data is not retrieved and assign yet
    const [ serverPara, setServerPara ] = useState(props.serverPara);
    // state data to store save button state, default not disabled
    const [ shouldSaveBtnDisabled, setShouldSaveBtnDisabled ] = useState(false);
    // state data for loading dialog
    const [ dialogPara, setDialogPara ] = useState({
        lng: chiLng.loadingSysInProgress,
        modalOpen: false,
        shouldLoadingIndicatorShow: true
    });
    const toggleChecked = (evt) => {
        const isChecked = evt.target.checked;
        const val = evt.target.value;
        // update UI state
        setSysSettingsObj(
            {
                ...sysSettingsObj,
                ...{[val]: isChecked}
            }
        );
        // update server state object
        console.log(serverPara)
        const updatedServerPara = serverPara.map((aServerParaObj) => {
            console.log(aServerParaObj);
            if (aServerParaObj['systemOptionName'] === val) {
                let newServerPara = {...aServerParaObj};
                newServerPara['systemOptionVal'] = isChecked;
                return newServerPara;
            } else {
                return aServerParaObj;
            }
        });
        setServerPara(updatedServerPara);
    }
    const toggleCheckedText = (evt) => {
        const val = evt.target.value;
        const optName = evt.target.name;

        setSysSettingsObj(
            {
                ...sysSettingsObj,
                ...{[optName]: val}
            }
        );
        console.log(serverPara)
        const updatedServerPara = serverPara.map((aServerParaObj) => {
            console.log(aServerParaObj);
            if (aServerParaObj['systemOptionName'] === optName) {
                let newServerPara = {...aServerParaObj};
                newServerPara['systemOptionVal'] = val;
                return newServerPara;
            } else {
                return aServerParaObj;
            }
        });
        setServerPara(updatedServerPara);
    }
    const saveSysSettings = () => {
        setDialogPara({ ...dialogPara, ...{  modalOpen: true, lng: chiLng.savingSysInProgress } });
        // save server object to db
        axios.post(
            API_UPDATE_SYS_PARA, {
                updateSysArr: serverPara
            }
        ).then((putRes)=>{
            if (putRes.data.result.success) {
                setDialogPara({ ...dialogPara, ...{  modalOpen: true, lng: chiLng.savedSysParaSuccessRetrieveAgain, shouldLoadingIndicatorShow: true } });
            } else {
                setDialogPara({ ...dialogPara, ...{  modalOpen: true, lng: chiLng.savedSysParaFailed, shouldLoadingIndicatorShow: false } });
            }
        }).catch((err)=>{
            setDialogPara({ ...dialogPara, ...{  modalOpen: true, lng: chiLng.savedSysParaFailed, shouldLoadingIndicatorShow: false } });
        }).then(() => {
            // reload the updated system parameter (calling method provided by HOC)
            props.setShouldReload(true);
        });
    }

    /* alignItems to control alignment perpendicular to main axis */
    const SwitchLabel = ({labelTxtMain, labelTxtSub}) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <div flex="1" color={normalGreyColor}>{labelTxtMain}</div>
                <div flex="1" 
                    style={
                        {
                            fontSize: '0.9rem',
                            color: lightGreyColor,
                        }
                    }
                >
                    {labelTxtSub}
                </div>
            </div>
        );
    };

    useEffect(() => {
        // show loading dialog
        setDialogPara({ ...dialogPara, ...{  modalOpen: true, shouldLoadingIndicatorShow: true } });
        // props.serverPara now contains retrieved server data
        // system settings data retrieved from server, set server parameter state for later use
        setServerPara(props.serverPara);
        // parse server state data and update ui state accordingly
        // temp variable is important here!
        // DO NOT use setstate in for loop, setstate is a batch process!! The state may not be up-to-date for second loop
        let temp = {};
        // error receives, should not allow to save
        if (!Array.isArray(props.serverPara)) {
            setShouldSaveBtnDisabled(true);
        } else {
            setShouldSaveBtnDisabled(false);
            props.serverPara.length > 0 && props.serverPara.map((paraObj) => {
                temp = {...temp, ...{[paraObj['systemOptionName']]: paraObj['systemOptionVal']}}
            });
            // update ui state object
            setSysSettingsObj(
                {
                    ...sysSettingsObj,
                    ...temp
                }
            )
        }
        // hide loading dialog
        setTimeout(() => {
            setDialogPara({ ...dialogPara, ...{  modalOpen: false } });
        }, 2000);
    }, [props.serverPara]); // check change of value before re-rendering

    return (
        <div className = {classes.root}>
            <SimpleMessageDialog
                modalOpen={dialogPara.modalOpen}
                shouldLoadingIndicatorShow={dialogPara.shouldLoadingIndicatorShow}
                lng={dialogPara.lng}
            />
            <div className={classes.titleDiv}>
                <SettingsIcon color="primary" className={classes.commonFontSize}/>
                <Typography
                    color="primary"
                    className={classes.systemTitle}
                >
                    { chiLng.adminSettingsTitle }
                </Typography>
            </div>
            
            <div className={classes.internalEl} style={{justifyContent: 'space-between'}}>
                <Typography className={[classes.subTitle, classes.formFontSize].join(' ')}>
                    { chiLng.globalSetup }
                </Typography>
                <IconButton
                    aria-label="save-sys-settings"
                    className={classes.saveSysSettingsBtn}
                    style={{cursor: shouldSaveBtnDisabled ? 'pointer' : 'not-allowed'}}
                    onClick={saveSysSettings}
                    classes={{
                        root: classes.saveSysSettingsBtnRoot
                    }}
                >
                    <SaveIcon color="primary" className={classes.iconFontSize}/>
                </IconButton>
            </div>

            <div className={classes.internalEl}>
                <FormControl component="fieldset" className={classes.formControlSelect}> {/* 85% for centralize switch component */}
                    <FormControlLabel
                        control={<Switch className={classes.formControlCssLabelSwitch} color="primary"/>}
                        labelPlacement="start"
                        value="shouldCloseResturants"
                        label={<SwitchLabel labelTxtMain={chiLng.openCloseSite} labelTxtSub={chiLng.openCloseSiteSub} />}
                        classes={{
                            label: classes.formControlCssLabel,
                            labelPlacementStart: classes.formControlCssLabelPlacementStart
                        }}
                        onChange={toggleChecked}
                        checked={sysSettingsObj.shouldCloseResturants}
                    />
                </FormControl>
            </div>
            
            <div className={classes.internalEl}>
                <FormControl component="fieldset" className={classes.formControlSelect}> {/* 85% for centralize switch component */}
                    <FormControlLabel
                        control={<Switch className={classes.formControlCssLabelSwitch} color="primary"/>}
                        labelPlacement="start"
                        value="shouldAutoCloseSysytem"
                        label={<SwitchLabel labelTxtMain={chiLng.autoCloseSysytem} labelTxtSub={chiLng.autoCloseSysytemSub} />}
                        classes={{
                            label: classes.formControlCssLabel,
                            labelPlacementStart: classes.formControlCssLabelPlacementStart
                        }}
                        onChange={toggleChecked}
                        checked={sysSettingsObj.shouldAutoCloseSysytem}
                    />
                </FormControl>
            </div>

            <div className={classes.internalEl}>
                <FormControl component="fieldset" className={classes.formControlSelect}> {/* 85% for centralize switch component */}
                    <TextField
                        id="autoCloseSysytemText"
                        name="autoCloseSysytemText"
                        label={chiLng.autoCloseSysytemText}
                        helperText={chiLng.autoCloseSysytemTextSub}
                        InputLabelProps={{
                            classes: {root: classes.formControlCssLabel},
                        }}
                        value={sysSettingsObj.autoCloseSysytemText}
                        onChange={toggleCheckedText}
                        disabled={!sysSettingsObj.shouldAutoCloseSysytem}
                    />
                </FormControl>
            </div>

            <div className={classes.internalEl}>
                <FormControl component="fieldset" className={classes.formControlSelect}> {/* 85% for centralize switch component */}
                    <FormControlLabel
                        control={<Switch className={classes.formControlCssLabelSwitch} color="primary"/>}
                        labelPlacement="start"
                        value="shouldOverrideOrderPlacement"
                        label={<SwitchLabel labelTxtMain={chiLng.allowOrderOverride} labelTxtSub={chiLng.allowOrderOverrideSub} />}
                        classes={{
                            label: classes.formControlCssLabel,
                            labelPlacementStart: classes.formControlCssLabelPlacementStart
                        }}
                        onChange={toggleChecked}
                        checked={sysSettingsObj.shouldOverrideOrderPlacement}
                    />
                </FormControl>
            </div>
        </div>
    );
}

// wrap the component if system setting server data is needed
export default withServerRequest(AdminSettingsPage);