import React from 'react';
import PropTypes from 'prop-types';
import SimpleMessageDialog from './SimpleMessageDialog';
import lng from '../misc/lng';

const { chiLng } = lng;
const ClosePage = (props) => {
    const {closeMsg} = chiLng;
    return (
        <SimpleMessageDialog
            lng={closeMsg}
            shouldLoadingIndicatorShow={false}
            modalOpen={props.shouldCloseDialogOpen}
        />
    );
}

ClosePage.propTypes = {
    shouldCloseDialogOpen: PropTypes.bool.isRequired,
}

export default ClosePage;
