// import axios for http fetching
import axios from 'axios';
// import tz from 'moment-timezone';
import { sha256 } from 'js-sha256';
// import calculated menu data
import productData from '../misc/helperImport';
import teaMenu from '../misc/simulate/teaTime';
import lng from '../misc/lng';
import { envConst } from '../misc/lng';
import { menuNameExtractHelper } from '../misc/envConst';

export const GET_PROD_LIST = 'GET_PROD_LIST';
export const UPDATE_PROD_STOCK_QTY = 'UPDATE_PROD_STOCK_QTY';
export const UPDATE_TOTAL_PAY_AMOUNT = 'UPDATE_TOTAL_PAY_AMOUNT';
export const GET_PROD_BY_ID = 'GET_PROD_BY_ID';
export const UPDATE_PROD_SHOP_LIST = 'UPDATE_PROD_SHOP_LIST';
export const ADD_BEVERAGE = 'ADD_BEVERAGE';
export const UPDATE_PROD_SHOP_QTY = 'UPDATE_PROD_SHOP_QTY';
export const UPDATE_PROD_QTY_TO_ZERO = 'UPDATE_PROD_QTY_TO_ZERO';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const UPDATE_APPBAR_TITLE = 'UPDATE_APPBAR_TITLE';
export const ClEARORDER = 'ClEARORDER';
export const SAVE_WS_OBJ = 'SAVE_WS_OBJ';
export const CHANGE_SHOP = 'CHANGE_SHOP';
export const REMOVE_BEVERAGE = 'REMOVE_BEVERAGE';

export const changeShop = (shop) => {
    return {
        type: CHANGE_SHOP,
        data: shop,
    }
}

export const clearOrder = () => {
    return {
        type: ClEARORDER,
    }
}

export const addBeverage = (beverageList) => {
    return {
        type: ADD_BEVERAGE,
        data: beverageList ,
    }
}

export const removeBeverage = () => {
    return {
        type: REMOVE_BEVERAGE,
    }
}

export const updateAppbarTitle = (title=lng.chiLng.toolbarTitle) => {
    return {
        type: UPDATE_APPBAR_TITLE,
        title,
    }
}

export const changeView = (view, generatedOrderId, orderTimeStamp) => {
    return {
        type: CHANGE_VIEW,
        data: view,
        generatedOrderId,
        orderTimeStamp
    }
}

export const saveWsObj = (wsObj) => {
    return {
        type: SAVE_WS_OBJ,
        ws: wsObj
    }
}

// Retrieve plain menu json menu file data
export const getMenuJson = (shopID) => {
    // get named day for choosing today's menu
    const dayName = menuNameExtractHelper();
    let productGetUrl = `${envConst.GET_MENU_URL}/${dayName}-menu.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
    if (shopID === 'sh02') {
        productGetUrl = `${envConst.GET_MENU_URL}/teaTime.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
    }
    return axios.get(productGetUrl);
};

export const getProductList = (shopID = 'sh01', ) => {
    return (dispatch) => {
        // should replaced with fetch in the future
        // sh01 is hardcoded, need to handle when there are more than 1 resturants
        // const someProductData = productData.sh01;
        getMenuJson(shopID).then((res) => {
            // Update product list loaded from server
            dispatch({
                type: GET_PROD_LIST,
                productData: res.data[shopID]
            });
        });
    }
}

export const updateProductShopList = (productId, userActionType, productData) => {
    return {
        type: UPDATE_PROD_SHOP_LIST,
        productId,
        userActionType,
        productData
    }
}

export const updateProductQtyToZero = (productId, productType) => {
    return {
        type: UPDATE_PROD_QTY_TO_ZERO,
        productId,
        productType,
    }
}

// order url hash suffix is generated by hash of order id concatenate with order id
export const confirmOrderStatus = (
    orderStatus,
    orderAddress = null,
    orderClientContactName = null,
    orderClientContactNo = null,
    orderDeliveryTime = null,
    orderMoment = null,
    payAmount = 0,
) => {
    return async(dispatch, getState) => {
        // order id is designed as A0001
        // get the order count and generate new order ID from db
        // const targetUrl = `${envConst.API_REST_ORDER_COUNT_URL}`;
        return new Promise(
            function (resolve, reject) {
                const {productsReducer} = getState();
                const productReducerObj = productsReducer;
                const orderTimeStamp = typeof orderMoment !== undefined ? orderMoment.format() : null;
                // const hashOfOrderTimeStamp = sha256(orderTimeStamp);
                // const orderUrlHash = sha256(`${generatedOrderId}${hashOfOrderTimeStamp}`);
                const orderUrlHash = '';
            
                dispatch({
                    type: UPDATE_ORDER_STATUS,
                    generatedOrderId: '',
                    orderTimeStamp,
                    orderStatus,
                    productReducerObj,
                    orderUrlHash,
                    orderAddress,
                    orderClientContactNo,
                    orderClientContactName,
                    orderDeliveryTime,
                    payAmount,
                });
                resolve();
            }
        );
        // return axios.get(targetUrl).then((res) => {
        //     console.log(res);
        //     // let orderCnt = res.data.count;
        //     // if (!orderCnt) {
        //     //     orderCnt = 0;
        //     // }
        //     // create generated order ID, use today's day and month (UTC+8)
        //     // const orderDay = new Date().getDate();
        //     // const orderMonth = new Date().getMonth() + 1;
        //     // const formattedOrderDay = orderDay.toString().padStart(2, '0');
        //     // const formattedOrderMonth = orderMonth.toString().padStart(2, '0');
        //     // const generatedOrderId = `${formattedOrderDay}${formattedOrderMonth}-A${(orderCnt + 1) + 150}`
        //     // const generatedOrderId = 'A' + (orderCnt + 1).toString().padStart(4, '0');
        //     const {productsReducer} = getState();
        //     const productReducerObj = productsReducer;
        //     const orderTimeStamp = typeof orderMoment !== undefined ? orderMoment.format() : null;
        //     // const hashOfOrderTimeStamp = sha256(orderTimeStamp);
        //     // const orderUrlHash = sha256(`${generatedOrderId}${hashOfOrderTimeStamp}`);
        //     const orderUrlHash = '';
        
        //     dispatch({
        //         type: UPDATE_ORDER_STATUS,
        //         generatedOrderId: '',
        //         orderTimeStamp,
        //         orderStatus,
        //         productReducerObj,
        //         orderUrlHash,
        //         orderAddress,
        //         orderClientContactNo,
        //         orderClientContactName,
        //         orderDeliveryTime,
        //         payAmount,
        //     });
        // });
    };
}