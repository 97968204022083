import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import lng from '../misc/lng';
import envConst from '../misc/envConst'
import ExportHtmlDialog from './ExportHtmlDialog';

const styles = () => {
    return {
        date: {
            marginTop: '20px',
        },
    };
};
class ExportDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exportOpt: 'cust',
            dateFrom: Moment(new Date()).format('YYYY-MM-DD'),
            dateTo: Moment(new Date()).format('YYYY-MM-DD'),
            helperText: '',
            err: false,
            format: 'html',
            exportHtmlDialog: {
                open: false,
                data: [],
            },
        };
        this.tempList = [];
        this.numberOfTimeLeft = 25;
        this.onEnter = this.onEnter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getAllOrder = this.getAllOrder.bind(this);
        this.getWhereFilter = this.getWhereFilter.bind(this);
        this.shouldDisableConfirm = this.shouldDisableConfirm.bind(this);
        this.updateError = this.updateError.bind(this);
        this.handleChangeFormat = this.handleChangeFormat.bind(this);
        this.openExportHtmlDialog = this.openExportHtmlDialog.bind(this);
        this.closeExportHtmlDialog = this.closeExportHtmlDialog.bind(this);
        
    }

    componentDidMount() {
        
    }
    openExportHtmlDialog(data) {
        this.setState({
            exportHtmlDialog: {
                data,
                open: true,
            },
        });
    }
    closeExportHtmlDialog() {
        this.setState({
            exportHtmlDialog: {
                data: [],
                open: false,
            },
        });
    }
    onEnter() {
        console.log('onEnter');
        this.setState({
            exportOpt: 'cust',
            dateFrom: Moment(new Date()).format('YYYY-MM-DD'),
            dateTo: Moment(new Date()).format('YYYY-MM-DD'),
        });
    }
    handleChange(e) {
        this.setState(
            {
                exportOpt: e.target.value,
            }
        );
    }
    handleChangeFormat(e) {
        this.setState(
            {
                format: e.target.value,
            }
        );
    }
    handleDateFromChange(e) {
        this.setState({
            dateFrom: e.target.value,
        }, () => {this.updateError();});
    }

    handleDateToChange(e) {
        this.setState({
            dateTo: e.target.value,
        }, () => {this.updateError();});
    }
    updateError() {
        const dateFromWithTime = new Date(this.state.dateFrom);
        const dateToWithTime = new Date(this.state.dateTo);
        dateFromWithTime.setHours(0, 0);
        dateToWithTime.setHours(23, 59);
        if (dateFromWithTime >= dateToWithTime) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                error: false
            });
        }
    }
    getWhereFilter() {
        const whereFilter = {};

        if (this.state.exportOpt === 'cust') {
            // console.log('this.state.dateFrom');
            // console.log(this.state.dateFrom);
            // console.log('this.state.dateTo');
            // console.log(this.state.dateTo);
            if (this.state.dateFrom && this.state.dateTo) {
                const dateFromWithTime = new Date(this.state.dateFrom);
                const dateToWithTime = new Date(this.state.dateTo);
                dateFromWithTime.setHours(0, 0);
                dateToWithTime.setHours(23, 59);
                // console.log('dateFromWithTime');
                // console.log(dateFromWithTime);
                // console.log('dateToWithTime');
                // console.log(dateToWithTime);
                if (dateFromWithTime <= dateToWithTime) {
                    whereFilter['orderTimeStamp'] = {
                        between: [
                            dateFromWithTime.toISOString(),
                            dateToWithTime.toISOString(),
                        ],
                    };
                }
            }
        } else if (this.state.exportOpt === 'lastMonth')  {
            const dateFromWithTime = new Date();
            const dateToWithTime = new Date();

            const currentMonth = dateFromWithTime.getMonth();

            dateFromWithTime.setMonth(currentMonth - 1);
            dateFromWithTime.setDate(1);
            dateFromWithTime.setHours(0, 0);

            // previous day of 1st of current month
            dateToWithTime.setDate(0);
            dateToWithTime.setHours(23, 59);
            whereFilter['orderTimeStamp'] = {
                between: [
                    dateFromWithTime.toISOString(),
                    dateToWithTime.toISOString(),
                ],
            };
            // console.log(dateFromWithTime);
            // console.log(dateToWithTime);
            // console.log('whereFilter');
            // console.log(whereFilter);
        }

        return whereFilter;
    }

    // Return Promise for getting all data
    getAllOrder() {
        this.tempList = [];
        const targetUrl = `${envConst.API_REST_URL}`;
        return new Promise(
            (resolve, reject) => {

                // 
                const requestApiCall = () => {
                    const body = {
                        params: {
                            filter: {
                                where: this.getWhereFilter(),
                                limit: 100,
                                skip: this.tempList.length,
                                // order: 'orderTimeStamp ASC',
                            },
                        }
                    };
                    console.log('body');
                    console.log(body);
                    axios.get(targetUrl, body).then(
                        (res) => {
                            if (res.status === 200) {
                                console.log(res.data);
                                this.numberOfTimeLeft -= 1;
                                this.tempList = [...this.tempList, ...res.data];
                                if (res.data.length <= 0) {
                                    resolve(this.tempList);
                                } else {
                                    requestApiCall();
                                }
                            }
                        },
                        (err) => {
                            console.log('cannot get order');
                            console.log(err);
                            reject(err)
                        }
                    );
                };

                // start calling recursive call 
                requestApiCall();
            }
        );
    }
    onSubmit() {
        console.log('onSubmit');
        this.props.setLoadingIndicator(true);
        this.getAllOrder().then(
            (data) => {
                if (this.state.format === 'html') {
                    this.openExportHtmlDialog(data.filter(i=> i.orderStatus !== 'void'));
                    this.props.setLoadingIndicator(false);
                    return;
                }
                this.props.export(data.filter(i=> i.orderStatus !== 'void'));
                this.props.setLoadingIndicator(false);
                this.props.cancel();
            }
        );
        console.log(this.getWhereFilter());
    }
    shouldDisableConfirm() {
        if (this.state.exportOpt === 'cust') {
            const dateFromWithTime = new Date(this.state.dateFrom);
            const dateToWithTime = new Date(this.state.dateTo);
            dateFromWithTime.setHours(0, 0);
            dateToWithTime.setHours(23, 59);
            if (dateFromWithTime >= dateToWithTime) {
                return true;
            }
        }
        return false;
    }
    render() {
        const classes = this.props.classes;
        const {chiLng} = lng;
        const {exportOptList, exportFormatList} = envConst;
        
        return (
            <Dialog open={this.props.open} onClose={this.props.cancel} onEnter={this.onEnter}>
                <DialogTitle className={classes.title}>
                    {chiLng.exportDialogTitle}
                </DialogTitle>
                <DialogContent>
                    <div style={{width: '200px'}}>
                        <FormControl className={classes.formControl} fullWidth>
                            <Select
                                id="export-select"
                                value={this.state.exportOpt}
                                onChange={this.handleChange}
                            >
                                {
                                    exportOptList.map(
                                        (opt) => (
                                            <MenuItem value={opt.value} key={opt.value}>{opt.name}</MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl style={{marginTop: '15px'}} className={classes.formControl} fullWidth>
                            <Select
                                id="export-select"
                                value={this.state.format}
                                onChange={this.handleChangeFormat}
                            >
                                {
                                    exportFormatList.map(
                                        (opt) => (
                                            <MenuItem value={opt.value} key={opt.value}>{opt.name}</MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                        {
                            this.state.exportOpt === 'cust' ? (
                                <FormControl className={classes.formControl} fullWidth>
                                    <TextField
                                        id="dateFrom"
                                        label={chiLng.exportLabelFrom}
                                        type="date"
                                        value={this.state.dateFrom}
                                        className={classes.date}
                                            InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.handleDateFromChange}
                                        error={this.state.error}
                                    />
                                </FormControl>
                            ) : (<div/>)
                        }
                        {
                            this.state.exportOpt === 'cust' ? (
                                <FormControl className={classes.formControl} fullWidth>
                                    <TextField
                                        id="dateTo"
                                        label={chiLng.exportLabelTo}
                                        type="date"
                                        value={this.state.dateTo}
                                        className={classes.date}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.handleDateToChange}
                                        error={this.state.error}
                                    />
                                    <FormHelperText id="date-error">{this.state.helperText}</FormHelperText>
                                </FormControl>
                            ) : (<div/>)
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onSubmit} color="primary" disabled={this.shouldDisableConfirm()} >
                        {chiLng.okAlert}
                    </Button>
                    <Button onClick={this.props.cancel} color="primary">
                        {chiLng.okAlertCancel}
                    </Button>
                </DialogActions>
                <ExportHtmlDialog open={this.state.exportHtmlDialog.open} data={this.state.exportHtmlDialog.data} cancel={this.closeExportHtmlDialog} />
            </Dialog>
        );
    }
}

ExportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExportDialog);