import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Moment from 'moment';
import lng from '../misc/lng';
import envConst from '../misc/envConst'
import '../export-table.css';

const styles = () => {
    return {
        table: {
            width: '100%',
            minWidth: '800px',
        },
        tableHeader: {
            textAlign: 'left',
        },
    };
};

const getBackgroundColor = function(address) {
    console.log('getBackgroundColor');
    console.log(address);
    if (address === '招商局物流中心') {
        return 'rgba(142, 255, 146, 0.5)';
    } else if (address === '豐樹青衣物流中心') {
        return 'rgba(252, 123, 129, 0.5)';
    }
    return 'inherit';
}
const getOrderClientContactNo = (phone) => {
    if (phone === '') return;

    if (phone.substring(0, 3) === '852') return (phone.substring(3));

    return phone;
};

class ExportHtmlDialog extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.parseArrToHtml = this.parseArrToHtml.bind(this);
        this.getTableRowData = this.getTableRowData.bind(this);
        this.getTableRow = this.getTableRow.bind(this);
    }

    componentDidMount() {
        console.log('ExportHtmlDialog');
    }

    onEnter() {
        console.log('ExportHtmlDialog onEnter');
        console.log(this.props.data);
    }

    getTableRowData(orders) {
        console.log('getTableRowData');
        console.log(orders);
        const productData = orders.orderDetails.productData.filter(o => o.unitQty > 0);
        if (productData.length <= 1) {
            return (
                <tr key={orders.generatedOrderId}>
                    <td>{orders.generatedOrderId}</td>
                    <td>{orders.orderClientContactName}</td>
                    <td>{getOrderClientContactNo(orders.orderClientContactNo)}</td>
                    <td>{orders.orderPayAmount}</td>
                    <td>{orders.orderDeliveryTime}</td>
                    <td></td>
                    <td>{productData[0].unitQty}</td>
                    <td>{productData[0].unitQty}</td>
                </tr>
            );
        }
        
        let resultRows = [];
        
        const infoCell = [
            orders.generatedOrderId, orders.orderClientContactName, getOrderClientContactNo(orders.orderClientContactNo), orders.orderPayAmount, orders.orderDeliveryTime
        ]; 
        const paddingCell = ['', '', '', '', ''];
        
        console.log('orderArr');

        productData.forEach(
            (product) => {
                console.log(product);
                if (product.productType === 'noodles') {

                    const specRowList = [];

                    // loop through each noodle menu and list out
                    product.productSpec.forEach(
                        (spec) => {
                            const specSize = spec.spec.length;
                            const noodleOption = spec.spec[0].specOptions[0].productName;
                            const noodleTaste = spec.spec[1].specOptions[0];
                            const noodleQty = spec.qty;
                            let noodleSubPartsStr = '';
                            for (let i=2; i<specSize; i++) {
                                for(let j=0; j<spec.spec[i].specOptions.length; j++) {
                                    if (i === specSize -1 && j === spec.spec[i].specOptions.length - 1) {
                                        // list the last one
                                        noodleSubPartsStr += spec.spec[i].specOptions[j];
                                    } else {
                                        noodleSubPartsStr += spec.spec[i].specOptions[j] + ' | ';
                                    }
                                }
                            }
                            const specRow = [
                                `${product.productName}(${noodleOption} | ${noodleTaste} | ${noodleSubPartsStr})`, noodleQty, noodleQty
                            ];
                            specRowList.push(specRow);
                        }
                    );

                    console.log('specRowList');
                    console.log(specRowList);
                    resultRows = [...resultRows, ...specRowList];

                } else if (product.productType === 'soupRices' || product.productType === 'teaTime') {
                    let qty = 0;
                    const specRowList = [];
                    product.productSpec.forEach(
                        (spec) => {
                            qty = qty + spec.qty;
                        }
                    );
                    // loop through each noodle menu and list out
                    product.productSpec.forEach(
                        (spec) => {
                            const specSize = spec.spec.length;
                            const noodleQty = spec.qty;
                            let noodleSubPartsStr = '';
                            for (let i=0; i<specSize; i++) {
                                for(let j=0; j<spec.spec[i].specOptions.length; j++) {
                                    if (i === specSize -1 && j === spec.spec[i].specOptions.length - 1) {
                                        // list the last one
                                        noodleSubPartsStr += spec.spec[i].specOptions[j];
                                    } else {
                                        noodleSubPartsStr += spec.spec[i].specOptions[j] + ' | ';
                                    }
                                }
                            }
                            const specRow = [
                                `${product.productName}(${noodleSubPartsStr})`, noodleQty, noodleQty
                            ];
                            specRowList.push(specRow);
                        }
                    );
                    resultRows = [...resultRows, ...specRowList];
                } else {
                    resultRows.push(
                        [product.productName, product.unitQty, product.unitQty]
                    );
                }
            }
        );
        
        console.log('resultRows');
        console.log(resultRows);
        return {
            id: orders.generatedOrderId,
            payment: orders.orderPayAmount,
            set: resultRows.reduce(
                (sum, row) => {
                    return sum + row[2];
                }, 0
            ),
            address: orders.orderAddress,
            resultRows: resultRows.map(
                (row, idx) => {
                    if (idx === 0) {
                        return [
                            ...infoCell,
                            ...row,
                        ];
                    }
                    return [
                        ...paddingCell,
                        ...row,
                    ];
                }
            ),
        };
    }
    parseArrToHtml (data, id) {
        const {classes} = this.props;
        console.log('parseArrToHtml');
        console.log(data);

        return data.resultRows.map(
            (item, idx) => {
                if (idx === 0) {
                    return (
                        <tr key={`${id}-${idx}`} style={{backgroundColor: getBackgroundColor(data.address)}}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                            <td>{item[2]}</td>
                            <td className="center">{item[3]}</td>
                            <td className="center">{item[4]}</td>
                            <td>{item[5]}</td>
                            <td className="center">{item[6]}</td>
                            <td className="center" rowSpan={data.resultRows.length}>{data.set / 2}</td>
                        </tr>
                    );
                }
                return (
                    <tr key={`${id}-${idx}`} style={{backgroundColor: getBackgroundColor(data.address)}}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{item[2]}</td>
                        <td className="center">{item[3]}</td>
                        <td className="center">{item[4]}</td>
                        <td>{item[5]}</td>
                        <td className="center">{item[6]}</td>
                    </tr>
                );
            }
        );
    }
    getTableRow() {
        const data = this.props.data.map(
            (order) => {
                return this.getTableRowData(order);
            }
        );

        console.log('getTableRow kenny');
        console.log(data);

        const result = data.map(
            (order) => {
                return this.parseArrToHtml(order,  order.generatedOrderId);
            }
        );
        
        const totalPayment = data.reduce(
            (count, order) => {
                return order.payment + count;
            }, 0
        );
        
        const totalSet = data.reduce(
            (count, order) => {
                return order.set + count;
            }, 0
        );

        result.push(
            (
                <tr key="sum">
                    <td colSpan="3">Total Payment</td>
                    <td className="center">{totalPayment}</td>
                    <td colSpan="2">Total Qty / Set</td>
                    <td className="center">{totalSet}</td>
                    <td className="center">{totalSet / 2}</td>
                </tr>
            )
        );

        return result;
    }
    render() {
        const {classes} = this.props;
        const {chiLng} = lng;
        
        return (
            <Dialog open={this.props.open} onClose={this.props.cancel} onEnter={this.onEnter} fullScreen>
                <DialogTitle className={classes.title}>
                    {chiLng.exportHtmlDialogTitle}
                </DialogTitle>
                <DialogContent>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.tableHeader}>Order No</th>
                            <th className={classes.tableHeader}>Contact Person</th>
                            <th className={classes.tableHeader}>Contact no</th>
                            <th className={classes.tableHeader}>Paymant</th>
                            <th className={classes.tableHeader}>Delivery Time</th>
                            <th className={classes.tableHeader}>Details</th>
                            <th className={classes.tableHeader}>Qty</th>
                            <th className={classes.tableHeader}>Set</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.getTableRow()
                        }
                    </tbody>
                </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.cancel} color="primary">
                        {chiLng.okAlertCancel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ExportHtmlDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(ExportHtmlDialog);