import moment from 'moment';
import tz from 'moment-timezone';
// import all necessary data
import mondayMenu from './simulate/monday-menu';
import tuesdayMenu from './simulate/tuesday-menu';
import wednesdayMenu from './simulate/wednesday-menu';
import thursdayMenu from './simulate/thursday-menu';
import fridayMenu from './simulate/friday-menu';
import saturdayMenu from './simulate/saturday-menu';
import sundayMenu from './simulate/noodle-menu-data-struct-sample'; // sat equals sun for easy development



// convert imported menu data to object for convenient export
const menuObj = {mondayMenu, tuesdayMenu, wednesdayMenu, thursdayMenu, fridayMenu, saturdayMenu, sundayMenu}
// Find today's weekday for name mapping
const today = moment.tz('Asia/Hong_Kong');
let todayWeekday = today.format('dddd').toLowerCase();
// As we do not have sunday menu, we map saturday menu to sunday menu
if (todayWeekday === 'saturday') {todayWeekday = 'sunday';} // sat equals sun for easy development
// Map the file name
const todayMenu = menuObj[`${todayWeekday}Menu`];
export default todayMenu;