import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import SimpleMessageDialog from '../components/SimpleMessageDialog';
// eslint-disable-next-line no-unused-vars
import lng from '../misc/lng';
import envConst from '../misc/envConst';

function range(size, startAt = 0) {
    console.log(size);
    return [...Array(size).keys()].map(i => i + startAt);
}

const styles = () => {
    return {
        root: {
            width: '80%',
            height: '80%'
        },
        wrapper: {
            width: '100%',
            height: '90%',
            overflowY: 'scroll'
        },
        header: {
            padding: '20px',
            fontSize: '2rem',
            textAlign: 'center',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
            marginLeft: '30px',
            marginRight: '30px'
        },
        label: {
            textAlign: 'right',
            paddingRight: '10px',
            fontSize: '1.3rem'
        },
        select: {
            paddingLeft: '10px',
        },
        selectItem: {
            minHeight: '35px',
        },
        sizeLarge: {
            fontSize: '1.4rem',
            fontWeight: '500'
        },
        actionDiv: {
            width: 'auto',
            height: '10%'
        }
    }
}
class BeverageDialog extends React.Component {
    constructor(props) {
        super(props);
        this.timeCheckIvl = [];
        this.state = {
            beverageList: [],
            shouldModalOpen: false,
            shouldLoadingIndicatorShow: false,
            msg: lng.chiLng.notEnoughPriceForTeaTime,
        };
        this.handleChangeNumberOfBeverage = this.handleChangeNumberOfBeverage.bind(this);
        this.getTotalLeft = this.getTotalLeft.bind(this);
        this.getSelectList = this.getSelectList.bind(this);
        this.isDisableConfirm = this.isDisableConfirm.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    componentWillUnmount() {
        this.mount = false;
        for(let i=0; i<this.timeCheckIvl; i++) {
            clearTimeout(this.timeCheckIvl[i]);
        }
    }

    componentDidMount() {
        this.mount = true;
        // load the beverage list (for dynamic menu update)
        console.log('kenny');
        const url = `${envConst.GET_MENU_URL}/beverage.json?bust=${Date.now()  /* bust url suffix for cache prevent */}`;
        const emptyBeverage = [];
        
        axios.get(url).then((res) => {
            const beverageData = res.data;
            this.setState(
                {
                    beverageList: beverageData.sh01.map(item => (
                            {
                                ...item
                            }
                        )
                    )
                }
            );
        }).catch(() => {
            this.setState({beverageList: emptyBeverage});
        });
    }
    onEnter() {
        console.log('onEnter');
        console.log(this.props.shop);
        let beverageName = 'beverage';
        if (this.props.shop === 'sh02') {
            beverageName = 'ttbeverage';
        }
        const url = `${envConst.GET_MENU_URL}/${beverageName}.json?bust=${Date.now()  /* bust url suffix for cache prevent */}`;
        const emptyBeverage = [];
        
        axios.get(url).then((res) => {
            const beverageData = res.data;
            this.setState(
                {
                    beverageList: beverageData.sh01.map(item => (
                            {
                                ...item
                            }
                        )
                    )
                }
            );
        }).catch(() => {
            this.setState({beverageList: emptyBeverage});
        });
    }
    handleChangeNumberOfBeverage(name, event) {
        this.setState(
            {
                beverageList: this.state.beverageList.map(
                    (item) => {
                        if (item.productName !== name) {
                            return item;
                        }
                        return {
                            ...item,
                            unitQty: event.target.value,
                        };
                    }
                ),
            }
        );
    }
    
    getTotalLeft() {
        let val = this.props.total;
        this.state.beverageList.forEach(
            (item) => {
                val = val - item.unitQty;
            }
        )
        return val;
    }

    getSelectList(item) {
        if (item.unitQty <= 0) {
            return range(this.getTotalLeft() + 1).map(
                i => <MenuItem classes={{root: this.props.classes.selectItem}} key={i} value={i}>{i}</MenuItem>
            );
        }
        return range(item.unitQty + 1).map(
            i => <MenuItem classes={{root: this.props.classes.selectItem}} key={i} value={i}>{i}</MenuItem>
        );
    }
    isDisableConfirm() {
        let total = 0;
        this.state.beverageList.forEach(
            (item) => {
                total += item.unitQty;
            }
        );
        return total < this.props.total;
    }
    render() {
        const classes = this.props.classes;
        const {chiLng} = lng;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                classes={{paper: classes.root}}
                onEntered={this.onEnter}
            >

                <Typography className={classes.header}>
                    {`${chiLng.beverageDialogHeader}`}{`(${this.props.total}杯)`}
                </Typography>
                <SimpleMessageDialog 
                    lng={this.state.msg}
                    modalOpen={this.state.shouldModalOpen}
                    shouldLoadingIndicatorShow={this.state.shouldLoadingIndicatorShow}
                />
                <div className={classes.wrapper}>
                    {
                        this.state.beverageList.map(
                            item => (
                                <div className={classes.row} key={item.productId}>
                                    <Typography classes={{root: classes.label}}>
                                        {item.productName} {item.unitPrice !== 0 ? `(+$${item.unitPrice})`: ''}
                                    </Typography>
                                    <Select
                                        value={item.unitQty}
                                        onChange={(e) => {this.handleChangeNumberOfBeverage(item.productName, e)}}
                                    >
                                        {
                                            this.getSelectList(item)
                                        }
                                    </Select>                    
                                </div>
                            )
                        )
                    }
                </div>
                <DialogActions className={classes.actionDiv}>
                    <Button 
                        color="primary"
                        onClick={this.props.handleClose}
                        size="large"
                        classes={{
                            sizeLarge: classes.sizeLarge
                        }}
                    >
                        {chiLng.cancel}
                    </Button>
                    <Button 
                        color="primary"
                        disabled={this.isDisableConfirm()}
                        onClick={() => {
                            if (this.props.shop !== 'sh02') {
                                this.props.handleConfirm(this.state.beverageList);
                                return;
                            }
                                let totalDrinkPrice = 0;
                                this.state.beverageList.forEach(
                                    (beverage) => {
                                        totalDrinkPrice += beverage.unitPrice;
                                    }
                                );
                                totalDrinkPrice += this.props.totalPrice;
                                console.log(totalDrinkPrice);
                            if (totalDrinkPrice < envConst.MIN_TEATIME_PAY) {
                                this.setState(
                                    {
                                        shouldModalOpen: true,
                                    },
                                    () => {
                                        this.timeCheckIvl.push(setTimeout(
                                            () => {
                                                this.setState({
                                                    shouldModalOpen: false,
                                                });
                                            },
                                            2000
                                        ));
                                    }
                                );
                            } else {
                                this.props.handleConfirm(this.state.beverageList);
                            }
                        }}
                        size="large"
                        classes={{
                            sizeLarge: classes.sizeLarge
                        }}
                    >
                        {chiLng.comfirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

BeverageDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    shop: PropTypes.string.isRequired,
};

export default withStyles(styles)(BeverageDialog);