import envConst from './envConst';
const lng = {
    chiLng: {
        toolbarTitle: 'GOOD MAN EAT',
        okAlert: '確定',
        okAlertCancel: '取消',
        okAlertRemoveOrderPrefix: '確定刪除訂單',
        okAlertRemoveOrderSuffix: '此動作無法還原',
        okAlertTitle: '刪除訂單',
        payTotalTxt: '應繳費用',
        prodNameDef: '輸入貨品名稱',
        prodDescDef: '輸入貨品描述',
        freeDrinks: '送飲品',
        prodQty: '數量',
        prodPriceUnit: 'HKD',
        prodConfirmOrder: '確認訂單',
        prodConfirmOrderBtnLbl: '確認訂單並選擇飲品',
        deliveryAddr: '外送地址',
        contactNo: '聯絡電話',
        orderClientContactNo: '聯絡電話',
        contactPerson: '聯絡人',
        orderClientContactName: '聯絡人',
        deliveryTime: '送遞時間',
        orderDeliveryTime: '送遞時間',
        generatedOrderId: '訂單編號',
        orderTimeStamp: '下單時間',
        orderStatus: '訂單狀況',
        resetInput: '重設',
        sendOrderOut: '送出訂單',
        orderProcessing: '正在處理訂單，根據網絡速度，落單需時可能長達1-2分鐘',
        successOrderCreate: '成功創建訂單',
        failedOrderCreate: '創建訂單失敗!',
        successOrderOut: '成功送出訂單',
        orderOutTime: '訂單送出時間',
        viewOrderStatus: '檢視訂單狀況',
        somethingWentWrong: '系統錯誤或訂單不存在!',
        pending: '準備中',
        confirmed: '訂單已確認',
        next: '下一步',
        orderWentWrong: '創建訂單失敗',
        orderWentWrong2: '請Whatsapp聯繫我們落單',
        smsWentWrong: '訂單已確認，但未能送出短訊，請確定聯絡電話可以收取短訊或嘗試其他號碼',
        titleFirstLetter: '搜',
        titleRest: '尋精選餐廳',
        noShippingFee: '免運費',
        shippingFee: '運費: ',
        noMinCharge: '不設最低消費',
        minCharge: '最低消費: $',
        joiningSoon: '即將加盟',
        hyphen: '-',
        findByNoPlaceholder: '輸入電話號碼搜尋訂單',
        searchByMobileBtnTxt: '搜索訂單',
        promoCodeInput: '優惠碼（如適用)',
        promoCodeApply: '使用',
        applyPromoOk: '成功使用優惠碼 訂單總額已更新！',
        applyPromoFailed: '優惠碼無效！',
        timeReachLabel: '已截單',
        beverageDialogHeader: '選擇飲品',
        noBeverageItem: '不用加配',
        comfirm: '確定',
        cancel: '取消',
        returnBtnCtx: '完成',
        filteredCompletedOrder: '只顯示未完成訂單',
        clntOrderNo: '訂單編號',
        clntPerson: '聯絡人',
        clntTel: '聯絡電話',
        clntStatus: '訂單狀況',
        clntDelivTime: '送遞時間',
        clntAddr: '派送地址',
        clntPayment: '總額',
        clntOrderTime: '送餐時間',
        clntOrderDetails: '訂單詳情',
        finalCallBanner: '截單時間為每日上午11時正',
        teaTimeFinalCallBanner: '截單時間為每日下banner午1時20分',
        finalCallBannerShort: '上午11時正截單',
        teaTimeFinalCallBannerShort: '下午1時20分截單',
        closeMsg: '為提升服務質素,我們正在進行系統升級,並於3月9日重新投入服務',
        bringYourChanges: '注:為使交收時更有效率,付款時請盡量自備零錢',
        addNoodlesCardTitle: '酸辣米線 (辣度/配菜)',
        noodleType: '米線款式',
        spiciness: '辣度',
        notSpicy: '走辣',
        littleSpicy: '微辣',
        lessSpicy: '小辣',
        mediumSpicy: '中辣',
        mostSpicy: '大辣',
        sideDish5: '配菜 (每款 $5)',
        sideDish8: '配菜 (每款 $8)',
        sideDish10: '配菜 (每款 $10)',
        buy: '選購',
        prodTotalQty: '總數量',
        emptyListWording: '請選擇數量',
        whatsappCallTxt: 'WHATSAPP 落單',
        whatsappCallNo: '67196297',
        wrongCode: '錯誤代碼',
        wrongCode2: '請截圖錯誤代碼，以便改善系統',
        whatsappOrder: '落單',
        totalPrice: '應繳款項',
        calculate: '計算',
        adminSettingsTitle: '系統設定',
        adminOrderTitle: '管理訂單',
        adminMainTitle: 'GOOD MAN EAT',
        adminSubTitle: '管理系統',
        adminUserName: '帳戶名',
        adminPwd: '帳戶密碼',
        adminLogin: '登入',
        globalSetup: '全域設定',
        openCloseSite: '關閉整個系統',
        openCloseSiteSub: '首頁將顯示「系統維護中」',
        allowOrderOverride: '解除截單時間限制',
        autoCloseSysytem: '自動關閉系統',
        autoCloseSysytemSub: '星期六 、日及公眾假期自動顯示休息通知',
        autoCloseSysytemText: '輸入自定義休息通知',
        autoCloseSysytemTextSub: '設定後會覆蓋預設休息通知',
        allowOrderOverrideSub: '忽略所有餐廳的截單時間',
        loadingSysInProgress: '正在讀取系統設定',
        savingSysInProgress: '正在儲存',
        savedSysParaSuccess: '儲存成功',
        savedSysParaSuccessRetrieveAgain: '儲存成功, 重新載入設定',
        savedSysParaFailed: '儲存失敗',
        banner: '感謝閣下使用GoodManEat外賣平台',
        loadJsonDataFailed: '加載菜單資料失敗',
        addSoupRiceCardTitle: '2餸1菜湯飯系列',
        addTeaTimeCardTitle: '下午茶系列',
        teaTimeDish: '任選一款',
        soupRiceSideDish8: '選兩餸 (另加每款$8)',
        soupRiceSideDish5: '選一菜 (另加每款$5)',
        siteClosed: '系統維護中，請稍候再試！',
        siteClosed2: '星期六,日及公眾假期休息',
        mealCntTitle: '訂餐數目',
        notEnoughPriceForTeaTime: '下banner午茶時段試業，最低消費$300',
        selFilter: '請選擇篩選條件',
        filCompleted: '只顯示已完成',
        filToday: '只顯示今天',
        filTeaTime: '只顯示下午茶',
        filLunch: '只顯示午餐',
        exportCsv: '匯出訂單 (.csv)',
        orderTitle: '訂單列表',
        orderNoLbl: '訂單編號',
        contactPLbl: '聯絡人',
        telLbl: '聯絡電話',
        statusLbl: '訂單狀況',
        deliveryTimeLbl: '送貨時間',
        addressLbl: '送貨地址',
        paymentLbl: '訂單總額',
        orderTimeLbl: '落單時間',
        detailsLbl: '訂單詳細',
        markAsCompleted: '設為已完成訂單',
        markedAsConfirmed: '設為已確認訂單',
        exportDialogTitle: '匯出選項',
        exportHtmlDialogTitle: '匯出',
        exportLabelFrom: '由',
        exportLabelTo: '至',
        loadMoreLabel: '按下載入更多',
        manualOrderTitle: '手動下單',
        manualLunchTitle: '午餐',
        manualTeatimeTitle: '下午茶',
        manualDrinksTitle: '飲品',
        manualTeatimeDrinksTitle: '下午茶飲品',
        dollarSign: '$',
        perMeal: '每份',
        manualPrint: '印單',
        back: '返回'
    },
    sysLng: {
        appName: 'Maxwell Quick Order System', //temp, not used yet
        prodImgDef: '/static/prod/img/no-image.jpg',
        BList: [] // hard code first, should load from db
    }
}

export {envConst};
export default lng;
